import { Fab, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import ROLES from '../../constants/roles'
import { recoverAccountList, toggleAccountState } from '../../containers/DataContainer'
import {
	useIEEInfoForm,
	useOtherUserInfoForm,
	useSelectUserProfileForm,
	useToggleDOForm,
} from '../../containers/FormContainer'
import Access from '../../containers/RoleContainer'
import { PLATFORM_ID_COOKIE, ACCOUNT_TYPES_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { COLUMNS_USERS, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
	dataTable: {
		width: '95%',
		marginLeft: '2.5%',
		marginBottom: 60,
	},
	link: {
		color: theme.palette.secondary.main,
	},
	icon: {
		color: theme.palette.secondary.main,
	},
	tooltip: {
		display: 'block',
	},
}))

const Users = () => {
	const classes = useStyles()
	const [redirect] = useState()
	const [cookies] = useCookies()
	const otherUserInfoForm = useOtherUserInfoForm()
	const [result, setResult] = useState({ columns: [], data: [], options: {} })
	const ieeInfoForm = useIEEInfoForm()
	const { t } = useTranslation('user')
	const { enqueueSnackbar } = useSnackbar()
	const [listDO, setListDo] = useState({})
	const toggleDOForm = useToggleDOForm(listDO)
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const platformName = useSelector((state) => {
		const find = state.platforms.platforms.find(
			(p) => p.platformId === +cookies[PLATFORM_ID_COOKIE],
		)
		return find ? find.name : ''
	})
	const isAdminOrManager = cookies[ACCOUNT_TYPES_COOKIE].some(
		(ur) => ur.code === 'ADMIN' || ur.code === 'PLATFORMMANAGER',
	)

	useEffect(() => {
		setIsLoading(true)
		recoverAccountList(
			{ platformId: cookies[PLATFORM_ID_COOKIE], nbItemsAsked: 10 },
			function (res, err) {
				if (err) return console.error(err)
				if (!res) return console.error('No response !')
				setResult(res.data)
				setIsLoading(false)
			},
		)
	}, [refresh])

	const OpenInfoForm = () => {
		ieeInfoForm.open()
	}

	const OpenOtherUserForm = () => {
		otherUserInfoForm.open()
	}

	const MatchGuidRegex = (strIn) => {
		const regexGuid =
			/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
		return regexGuid.test(strIn)
	}

	const { formular, open } = useSelectUserProfileForm({ OpenInfoForm, OpenOtherUserForm })

	const accountInfo = (companyId) => {
		window.open('/op-infos/' + companyId)
	}

	const toggleAccount = (accountId) => {
		toggleAccountState(
			{
				platformId: cookies[PLATFORM_ID_COOKIE],
				accountId: accountId,
			},
			(res, error) => {
				if (error) {
					console.error(error)
					if (error.data) {
						setRefresh(!refresh)
						return enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
					}
					setRefresh(!refresh)
					return enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				}
				if (res) {
					if (res.data && res.data.message === 'Succeeded') {
						if (res.data.statusCode && res.data.statusCode === 'DOSubstitate') {
							setListDo({ listDO: res.data.doDict, accountId: accountId })
							toggleDOForm.open()
							return
						}
						enqueueSnackbar(t('AccountStatusHasBeenChanged'), { variant: 'info' })
						setRefresh(!refresh)
						return
					}
					setRefresh(!refresh)
					enqueueSnackbar(t('AccountStatusHasBeenChanged'), { variant: 'info' })
				}
			},
		)
	}

	const ToggleAccountButton = ({ accountId, mode }) => {
		const [initialMode, setInitialMode] = useState()

		useEffect(() => {
			setInitialMode(mode)
		}, [mode])

		return (
			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
				<Tooltip title={initialMode ? t('DisableAccount') : t('ActivateAccount')}>
					<IconButton
						size="small"
						className={classes.icon}
						onClick={() => {
							toggleAccount(accountId)
							setInitialMode(!initialMode)
						}}
					>
						{initialMode && <CloseIcon />}
						{!initialMode && <CheckIcon />}
					</IconButton>
				</Tooltip>
			</Access>
		)
	}
	ToggleAccountButton.propTypes = {
		accountId: PropTypes.number,
		mode: PropTypes.bool,
	}

	const AddUser = () => (
		<Fab className={classes.fab} color="secondary" onClick={open}>
			<AddIcon />
		</Fab>
	)

	return (
		<>
			{formular}
			{ieeInfoForm.formular}
			{otherUserInfoForm.formular}
			{toggleDOForm.formular}
			{redirect && <Redirect to={redirect} />}
			<HeadTitle title={t('UsersList')} link="/dashboard" />
			<DataTable
				title={t('UsersList')}
				columns={result.columns}
				data={result.data}
				options={result.options}
				className={classes.dataTable}
				isLoading={isLoading}
				defaultFilters={[
					{
						name: 'platforms',
						value: [platformName],
					},
					{
						name: 'isActive',
						value: [t('users_isActive')],
					},
				]}
				customColumns={[
					{
						name: 'owner',
						component: function componentAccess(value, tableMeta) {
							return (
								<>
									<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL]}>
										{value}
									</Access>
									<Access accept={[ROLES.PRINCIPAL]}>{value}</Access>
								</>
							)
						},
					},
					{
						name: 'info',
						component: (value) => {
							if (value)
								return (
									<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
										<Tooltip title={t('AccessAccountInformation')}>
											<IconButton size="small" onClick={() => accountInfo(value)}>
												<InfoIcon className={classes.icon} />
											</IconButton>
										</Tooltip>
									</Access>
								)
						},
					},
					{
						name: 'state',
						component: function componentToggleAccountButton(value, tableMeta) {
							if (!tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_USERS.PLATFORMS)])
								return
							return (
								<>
									<ToggleAccountButton
										accountId={
											tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_USERS.ID)]
										}
										mode={value}
									/>
								</>
							)
						},
					},
					{
						name: 'isActive',
						component: (value) => {
							if (!value) return
							return t('users_isActive')
						},
					},
					{
						name: 'login',
						component: function componentAccess(value, tableMeta) {
							// it it's match a guid regex => it means that it's a pending account, we add tooltip to explain the users
							const userId = tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_USERS.ID)]
							if (MatchGuidRegex(value)) {
								return (
									<>
										{isAdminOrManager && (
											<Tooltip title={t('AccountCreationPending')}>
												<a
													href={'myAccount/' + userId}
													rel="noopener noreferrer"
													className={classes.link}
												>
													{value}
												</a>
											</Tooltip>
										)}
										{!isAdminOrManager && ROLES.PRINCIPAL && <> {value} </>}
									</>
								)
							}
							if (value != null)
								return (
									<>
										{isAdminOrManager && (
											<a
												href={'myAccount/' + userId}
												rel="noopener noreferrer"
												className={classes.link}
											>
												{value}
											</a>
										)}
										{!isAdminOrManager && ROLES.PRINCIPAL && <> {value} </>}
									</>
								)
							else {
								return (
									<>
										{isAdminOrManager && (
											<a
												href={'myAccount/' + userId}
												rel="noopener noreferrer"
												className={classes.link}
											>
												{t('interne')}
											</a>
										)}
										{!isAdminOrManager && ROLES.PRINCIPAL && <> {value} </>}
									</>
								)
							}
						},
					},
				]}
				customLabels={[
					{
						name: 'owner',
						label: t('owner'),
					},
					{
						name: 'phone-number',
						label: t('phone-number'),
					},
					{
						name: 'login',
						label: t('login'),
					},
					{
						name: 'email',
						label: t('email'),
					},
					{
						name: 'function',
						label: t('function'),
					},
					{
						name: 'state',
						label: t('state'),
					},
					{
						name: 'info',
						label: t('info'),
					},
					{
						name: 'platforms',
						label: t('platforms'),
					},
					{
						name: 'siret',
						label: t('siretNumber'),
					},
				]}
			/>

			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
				<AddUser className={classes.fab} />
			</Access>
		</>
	)
}
export default Users
