import React, { useState } from 'react'
import {
	ACCOUNT_ID_COOKIE,
	ACCOUNT_TYPES_COOKIE,
	FIRST_NAME_COOKIE,
	LAST_NAME_COOKIE,
	COMPANY_ID_COOKIE,
	PLATFORM_ID_COOKIE,
	DEFAULT_COOKIE_OPTIONS,
} from '../../constants/cookies'
import { Redirect } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { sendAuth, internalUserAuthentication, disconnect } from '../../containers/AuthContainer'
import { useCookies } from 'react-cookie'
import { usePlatformChoiceForm, useAttachmentExpiredForm } from '../../containers/FormContainer'
import { useSnackbar } from 'notistack'
import {
	getPlatformListByAccountId,
	updateUserLastConnectionDate,
} from '../../containers/DataContainer'
import { SearchQueryStrings } from '../../utils/Url'
import ROLES from '../../constants/roles.js'
import { FetchToken } from '../../authConfig'
import { addHourToDate } from '../../utils/Date'
import { useDispatch } from 'react-redux'
import { setToken } from '../../store/token/tokenSlice'

const useStyles = makeStyles((theme) => ({
	main: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	formControl: {
		marginTop: '40px',
		marginBottom: '40px',
	},
	form: {
		margin: 'auto',
		width: '70%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
	leftPanel: {
		backgroundImage: 'url(/images/leftLoginBg.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	logo: {
		width: '100%',
		maxWidth: 400,
		height: 'auto',
		padding: '0 50px',
	},
	logoBtn: {
		width: '20%',
		marginRight: '20px',
	},
	title: {
		marginBottom: theme.spacing.unit * 1.5,
		color: theme.palette.secondary.main,
	},
	underlineTitle: {
		marginBottom: theme.spacing.unit * 5,
		backgroundColor: theme.palette.secondary.main,
		width: '50%',
		height: 1,
		border: 0,
	},
	icon: {
		fill: '#C8C8C8',
	},
	usernameField: {
		width: 400,
	},
	forgotLink: {
		color: theme.palette.secondary.main,
		display: 'inline',
		cursor: 'pointer',
	},
	connectBtn: {
		backgroundColor: theme.palette.primary.main,
		border: 'solid 2px #6D6D6D',
		color: '#6D6D6D',
	},
	signupBtn: {
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
	},
	buttons: {
		boxShadow: 'none',
		width: '228px',
		marginBottom: theme.spacing.unit * 4,
	},
	redCross: {
		color: 'red',
	},
	langage: {
		display: 'block',
		marginLeft: 14,
		marginRight: 16,
	},
	flag: {
		width: 80,
		height: 'auto',
	},
	flagSelected: {
		boxShadow:
			'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
	},
}))

export const TYPERETURN = {
	CompanyPlatformThreeMonthsRemains: 'CompanyPlatformThreeMonthsRemains',
	CompanyPlatformTwoMonthsRemains: 'CompanyPlatformTwoMonthsRemains',
	CompanyPlatformOneMonthsRemains: 'CompanyPlatformOneMonthsRemains',
	CompanyPlatformOneMonthsPassed: 'CompanyPlatformOneMonthsPassed',
	CompanyPlatformTwoMonthsPassed: 'CompanyPlatformTwoMonthsPassed',
	CompanyPlatformThreeMonthsPassed: 'CompanyPlatformThreeMonthsPassed',
	CompanyPlatformExpired: 'CompanyPlatformExpired',
}

const SignIn = () => {
	const classes = useStyles()

	const [redirect, setRedirect] = useState(false)

	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies()

	const { enqueueSnackbar } = useSnackbar()
	const [sendDataToForm, setSendDataToForm] = useState()
	const [sendDataToAttachementForm, setSendDataToAttachementForm] = useState()
	const { t, i18n } = useTranslation(['user', 'translation'])
	const PlatformChoiceForm = usePlatformChoiceForm(sendDataToForm)
	const AttachmentExpiredForm = useAttachmentExpiredForm(sendDataToAttachementForm)
	const [selectedLang, setSelectedLang] = useState(i18n.language || 'fr') // current language or default french
	const dispatch = useDispatch()

	const handleChange = (value) => {
		setSelectedLang(value)
		i18n.changeLanguage(value)
	}

	const selectPlatform = (value, authRes) => {
		load(authRes, value)
	}

	const connect = () => {
		enqueueSnackbar(t('YouAreAuthenticated'), { variant: 'success' })
		setRedirect(true)
	}

	const recoverPlatforms = () => {
		getPlatformListByAccountId(null, (res, err) => {
			let isCorrect = !err && res && res.data && res.data.length
			if (!isCorrect) return
			connect()
		})
	}

	const load = async (connectRes, selectedPlatform) => {
		const expireToken = addHourToDate(3)
		updateUserLastConnectionDate(selectedPlatform, (_res, err) => {
			if (err) return console.error(err)
		})
		const options = {
			expires: expireToken,
			...DEFAULT_COOKIE_OPTIONS,
		}
		const token = await FetchToken()
		dispatch(setToken(token))
		setCookie(ACCOUNT_ID_COOKIE, connectRes.account.id, options)

		if (connectRes.account.firstName)
			setCookie(FIRST_NAME_COOKIE, connectRes.account.firstName, options)
		else setCookie(FIRST_NAME_COOKIE, 'NoFirstName', options)

		if (connectRes.account.lastName)
			setCookie(LAST_NAME_COOKIE, connectRes.account.lastName, options)
		else setCookie(LAST_NAME_COOKIE, 'NoLastName', options)

		if (connectRes.account.company) {
			setCookie(COMPANY_ID_COOKIE, connectRes.account.company.id, options)
		} else {
			setCookie(COMPANY_ID_COOKIE, -1, options)
		}

		if (connectRes.account.userPlatforms) {
			if (selectedPlatform) {
				setCookie(PLATFORM_ID_COOKIE, selectedPlatform, options)
				let platformAccountType = connectRes.account.userPlatforms.filter(function (platform) {
					return platform.platformId === selectedPlatform
				})
				setCookie(
					ACCOUNT_TYPES_COOKIE,
					JSON.stringify(platformAccountType[0].accountTypes),
					options,
				)
			} else {
				let userPlatforms = connectRes.account.userPlatforms
				let firstPlatform = userPlatforms[0]
				setCookie(PLATFORM_ID_COOKIE, firstPlatform.platformId, options)
				setCookie(ACCOUNT_TYPES_COOKIE, JSON.stringify(firstPlatform.accountTypes), options)
			}
		}

		recoverPlatforms()
	}

	const onSubmit = (isInternal) => {
		sendAuth(isInternal, async (_res, err) => {
			if (err) {
				console.warn(err)
				enqueueSnackbar(`${t('serverError')}`, { variant: 'error' })
				return
			}
			internalUserAuthentication(function (response, error) {
				if (error) {
					if (error.data && error.data.errors) {
						let errors = error.data.errors
						if (errors.Login) {
							enqueueSnackbar(`${t('error')} : ${errors.Login[0]} ! `, { variant: 'error' })
							return
						}
					}
					if (error.data && error.data[0]) {
						enqueueSnackbar(t(error.data[0].message), { variant: 'error' })
					} else {
						enqueueSnackbar(`${t('error')} : ${error.status} ${error.statusText}`, {
							variant: 'error',
						})
					}
					disconnect(false)
					return
				}

				if (response && response.data && response.data.account) {
					let result = response.data
					switch (result.account.langId) {
						case 1:
							i18n.changeLanguage('fr')
							break
						case 2:
							i18n.changeLanguage('en')
							break
						default:
							break
					}

					if (result.account.statusCode === undefined || result.account.statusCode === null) {
						load(result)
					}
					if (result.account && Array.isArray(result.account?.statusAdditionnalData)) {
						result.account.statusAdditionnalData.sort((a, b) => {
							if (a.ptfNom === b.ptfNom) return 0
							else if (a.ptfNom > b.ptfNom) return 1

							return -1
						})
					}
					switch (result.account.statusCode) {
						case 'PlatformValid':
							load(result, result.account.statusAdditionnalData.ptfId)
							return
						case 'PlatformChoice':
							setSendDataToForm({
								listPlatforms: result.account.statusAdditionnalData,
								selectPlatform: selectPlatform,
								authRes: result,
							})
							PlatformChoiceForm.open()
							return
						case 'CompanyNoPlatformsValid':
							setSendDataToAttachementForm({
								listPlatforms: result.account.statusAdditionnalData,
								authRes: result,
							})
							AttachmentExpiredForm.open()
							return
						default:
							break
					}
				}
			})
		})
	}

	const getTargetUrl = () => {
		const queries = SearchQueryStrings(window.location.href)
		for (let query of queries) {
			if (query.key === 'targetUrl') return query.value
		}

		const DASHBOARD_REDIRECT = [
			ROLES.ADMIN,
			ROLES.PLATFORMMANAGER,
			ROLES.PRINCIPAL,
			ROLES.DOCUMENTATION,
		]
		const FOLLOWPDP_REDIRECT = [ROLES.EXTERNALOBS]

		let redirectToDashboard = false
		let redirectToFollowPdp = false

		cookies[ACCOUNT_TYPES_COOKIE] &&
			cookies[ACCOUNT_TYPES_COOKIE].forEach((c) => {
				if (DASHBOARD_REDIRECT.find((d) => d.code === c.code)) redirectToDashboard = true
				if (FOLLOWPDP_REDIRECT.find((d) => d.code === c.code)) redirectToFollowPdp = true
			})

		if (redirectToDashboard) return '/dashboard'
		else if (redirectToFollowPdp) return '/followPdp'
		else {
			// Default case
			return '/followCR'
		}
	}

	return (
		<>
			{PlatformChoiceForm.formular}
			{AttachmentExpiredForm.formular}
			{redirect && <Redirect to={getTargetUrl()} />}
			<Grid container spacing={0}>
				<Grid item md={6} className={classes.leftPanel} />
				<Grid item md={6} sm={12}>
					<main className={classes.main}>
						<CssBaseline />
						<div className={classes.paper}>
							<div>
								<FormControl component="fieldset" className={classes.formControl}>
									<Grid container spacing={40}>
										<Grid item>
											<FormControlLabel
												value="fr"
												className={classes.langage}
												control={
													<>
														<img
															src="/images/fr.png"
															alt={t('fr')}
															className={`${classes.flag} ${
																selectedLang === 'fr' ? classes.flagSelected : ''
															}`}
															onClick={() => handleChange('fr')}
														/>
													</>
												}
											/>
										</Grid>
										<Grid item>
											<FormControlLabel
												value="en"
												className={classes.langage}
												control={
													<>
														<img
															src="/images/en.png"
															alt={t('en')}
															className={`${classes.flag} ${
																selectedLang === 'en' ? classes.flagSelected : ''
															}`}
															onClick={() => handleChange('en')}
														/>
													</>
												}
											/>
										</Grid>
									</Grid>
								</FormControl>
							</div>

							<img className={classes.logo} src="/images/total.png" alt={t('translation:brand')} />
							<Typography className={classes.title} component="h1" variant="h4">
								{t('signin')}
							</Typography>
							<hr className={classes.underlineTitle} />
							<Fab
								type="submit"
								variant="extended"
								onClick={() => onSubmit(false)}
								className={classNames([classes.connectBtn, classes.buttons])}
							>
								{t('connect')}
							</Fab>
							<br />
							<Fab
								type="submit"
								variant="extended"
								onClick={() => onSubmit(true)}
								className={classNames([classes.connectBtn, classes.buttons])}
							>
								<img
									className={classes.logoBtn}
									src="/images/totalLogo.png"
									alt={t('translation:brand')}
								/>
								{t('connect')}
							</Fab>

							<br />
							<Fab
								href="/sign-up"
								variant="extended"
								color="secondary"
								className={classNames([classes.signupBtn, classes.buttons])}
							>
								{t('signup_btn')}
							</Fab>
						</div>
					</main>
				</Grid>
			</Grid>
		</>
	)
}

export default SignIn
