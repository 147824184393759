import React, { useEffect } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import MyAccountForm from '../../components/forms/MyAccountForm'
import { useTranslation } from 'react-i18next'

/**
 * Shows the MyAccount page
 * @returns {JSX.Element} React stack to render
 */
// eslint-disable-next-line no-empty-pattern
function MyAccount({ match }) {
	const { setTitleBar } = useTotalTitleBarContext()
	const { t } = useTranslation('user')

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'remove_red_eye',
				title: t('oversight'),
				subtitle: t('WelcomeToYourPdP'),
			},
		})
	}, [])

	return (
		<>
			<HeadTitle title={t('GeneralInformations')} />
			<MyAccountForm match={match} />
		</>
	)
}
export default MyAccount
