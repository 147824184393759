import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/MailOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkEraseOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core'
import { useAddContactForm } from '../../containers/FormContainer'
import { useTranslation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	card: {
		minHeight: 180,
		position: 'relative',
		margin: '10px',
	},
	cardIcon: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'mouse',
	},
	addCardIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70%',
	},
	addIcon: {
		height: '100%',
		width: '100%',
	},
	title: {
		fontSize: 16,
		color: theme.palette.secondary.main,
		paddingRight : '10%',
		display:  'block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},

	separator: {
		width: '90%',
		height: 1,
		border: 'none',
		backgroundColor: '#d6d6d6',
	},
	icons: {
		color: 'black',
	},
	link: {
		color: 'black !important',
		textDecoration: 'none',
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 11,
	},
	cardHeader: {
		display: 'list-item',
		paddingBottom: 5,
	},
	iconList: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	icon: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		height: '27px'
	},
	roleIcon: {
		margin: 5,
	},
	cardContent: {
		'&:last-child': {
			padding: 1,
		},
	},
	listContent: {
		padding: 1,
		margin: 1
	},
	listItem: {
		paddingTop:5,
		paddingBottom:5
	},
	item: {
		padding: 0
	}
}))
/**
 * Shows a Contact card
 * @param {int} size : Size of the card
 * @param {any} data : Object with the data
 * @returns {JSX.Element} React stack to render
 */
const ContactCard = ({ size, data, typeIcons, changeState }) => {
	const classes = useStyles()
	const AddContactForm = useAddContactForm(data, changeState)
	const { t } = useTranslation('user')

	return (
		<Grid item xs={12} sm={6} md={size}>
			{AddContactForm.formular}
			<Card className={classes.card}>
				<MuiCardHeader
					title={
						<Typography className={classes.title} variant="h6">
							{data.firstName} {data.name}
						</Typography>
					}
					titleTypographyProps={{ color: 'inherit' }}
					className={classes.cardHeader}
				/>
				<hr className={classes.separator} />
				<CardContent className={classes.cardContent}>
					<List className={classes.listContent}>
						<ListItem className={classes.listItem}>
							<ListItemIcon>
								<MailIcon className={classes.icons} />
							</ListItemIcon>
							<ListItemText className={classes.item}>
								<a
									className={classes.link}
									href={`mailto:${data.email}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.email}
								</a>
							</ListItemText>
						</ListItem>
						<ListItem className={classes.listItem}>
							<ListItemIcon>
								{data.phone ? (
									<PhoneIcon className={classes.icons} />
								) : (
									<PhonelinkEraseIcon className={classes.icons} />
								)}
							</ListItemIcon>
							<ListItemText className={classes.item}>
								{data.phone ? (
									<a
										className={classes.link}
										href={`tel:${data.phone}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data.phone}
									</a>
								) : (
									<span className={classes.link}>{t('NoPhoneNumber')}</span>
                  
								)}
							</ListItemText>
						</ListItem>
					</List>
					<IconButton
						className={classes.cardIcon}
						aria-label="Edit"
						onClick={() => {
							AddContactForm.open()
						}}
					>
						<EditIcon className={classes.icons} />
					</IconButton>
					<div className={classes.iconList}>
						{typeIcons &&
              typeIcons.map((icon, i) => (
              	<img key={i} src={icon} alt="icon" className={classes.icon} />
              ))}
					</div>
				</CardContent>
			</Card>
		</Grid>
	)
}

export default ContactCard
