import {
	AppBar,
	Button,
	Card,
	CardContent,
	Grid,
	Icon,
	IconButton,
	MobileStepper,
	Paper,
	Toolbar,
	Typography,
	MenuItem,
	Tooltip,
	CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneOutline from '@material-ui/icons/DoneOutline'
import HighlightOff from '@material-ui/icons/HighlightOff'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import ContentSlider from '../../components/ContentSlider/ContentSlider'
import InputTextArea from '../../components/Form/inputFullPage/InputTextArea'
import classNames from 'classnames'
import DescriptionOfOperationsForm from '../../components/forms/CompanyFiles/DescriptionOfOperationsForm/DescriptionOfOperationsForm'
import {
	confirmCompanyRecordById,
	getCompanyRecordById,
	getPdPInformations,
	getWorkflowValidationById,
	recoverOperationalInfos,
	sendACPValidation,
	sendCHPValidation,
	sendEVAValidation,
	sendHAHValidation,
	sendOPDValidation,
	sendOPMValidation,
	sendSBCValidation,
	getCompanyRecordRefusalMessage,
} from '../../containers/DataContainer'
import {
	useChemicalProductsStorage,
	usePhasesUnsafeOperations,
	usePhaseUnsafeObservationForm,
} from '../../containers/FormContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import ChemicalsProducts from './ChemicalsProducts'
import EnvironmentalAspects from './EnvironmentalAspects/EnvironmentalAspects'
import HygieneAndHealth from './HygieneAndHealth'
import OperationalProcedures from './OperationalProcedures'
import PhasesUnsafeOperations from './PhasesUnsafeOperations'
import SubcontractingCompagnies from './SubcontractingCompagnies'
import { ACCOUNT_TYPES_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'
import { InputSelectOnChange } from '../../components/Form/inputFullPage/InputSelect'
import InfoIcon from '@material-ui/icons/Info'
import ROLES, { VALIDATORS } from '../../constants/roles'
import Access from '../../containers/RoleContainer'
const CSS_VALUES = {
	IMPORTANT: ' !important',
}

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		margin: 0,
		width: '100%',
		paddingBottom: 66,
		textAlign: 'center',
	},
	header: {
		margin: theme.spacing.unit * 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	textField: {
		width: '100%',
		marginBottom: theme.spacing.unit,
	},
	test: {
		borderColor: theme.palette.secondary.main + CSS_VALUES.IMPORTANT,
	},
	mobileStepper: {
		position: 'absolute',
		bottom: 0,
		left: '50%',
		width: '100%',
		transform: 'translateX(-50%)',
	},
	page: {
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 10,
		paddingRight: theme.spacing.unit * 10,
		paddingBottom: theme.spacing.unit * 4,
	},
	card: {
		position: 'relative',
		height: 175,
		border: 'none',
		transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		color: 'black !important',
		'&:hover': {
			color: theme.palette.secondary.main + CSS_VALUES.IMPORTANT,
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		},
		'&:hover $title': {
			color: theme.palette.secondary.main + CSS_VALUES.IMPORTANT,
			transition: 'color 0.3s',
		},
	},
	required: {
		border: 'solid 1px red',
	},
	title: {
		margin: 'auto',
		fontSize: '1.2em',
	},
	link: {
		display: 'contents',
		textDecoration: 'none',
		'&:visited': {
			color: 'black',
		},
	},
	contentCard: {
		display: 'flex',
		height: '100%',
		position: 'relative',
	},
	icon: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		fontSize: 50,
	},
	appBar: {
		position: 'relative',
	},
	statusButton: {
		marginLeft: theme.spacing.unit,
	},
	acceptButton: {
		width: '100%',
		height: 53,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.yes.main,
		borderColor: theme.palette.yes.main,
	},
	refuseButton: {
		width: '100%',
		height: 53,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.no.main,
		borderColor: theme.palette.no.main,
	},
	content: {
		margin: 'auto',
		marginTop: 50,
		width: '90%',
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	containerActions: {
		padding: '15px',
	},
	space: {
		marginBottom: 10,
	},
	select: {
		textAlign: 'left',
	},
}))

const CARDS = {
	OperationalInfos: {
		key: 0,
		chevronCode: 'OperationsDetails',
		title: 'OperationDescription',
		icon: HighlightOff,
		page: DescriptionOfOperationsForm,
	},
	SubContractingCompanies: {
		key: 1,
		chevronCode: 'SubContractingCompanies',
		title: 'SubContractingCompanies',
		icon: HighlightOff,
		page: SubcontractingCompagnies,
	},
	HygieneAndHealth: {
		key: 2,
		chevronCode: 'HygieneAndHealth',
		title: 'HygieneAndHealth',
		icon: HourglassEmpty,
		page: HygieneAndHealth,
	},
	DangerActivityPhases: {
		key: 3,
		chevronCode: 'DangerActivityPhases',
		title: 'DangerActivityPhases',
		icon: HourglassEmpty,
		page: PhasesUnsafeOperations,
	},
	ChemicalProducts: {
		key: 4,
		chevronCode: 'ChemicalProducts',
		title: 'ChemicalProducts',
		icon: HourglassEmpty,
		page: ChemicalsProducts,
	},
	EnvironmentalAspects: {
		key: 5,
		chevronCode: 'EnvironmentalAspects',
		title: 'EnvironmentalAspects',
		icon: DoneOutline,
		page: EnvironmentalAspects,
	},
	OperatingModes: {
		key: 6,
		chevronCode: 'OperatingModes',
		title: 'OperatingModes',
		icon: DoneOutline,
		page: OperationalProcedures,
	},
}

const CHEVRONS_STATES = {
	VALID: { code: 'Approved', icon: 'check' },
	PENDING: { code: 'Pending', icon: 'timer' },
	REFUSE: { code: 'Refused', icon: 'clear' },
	READABLE: { code: 'Readable', icon: 'search' },
}

CompanyRecordValidate.propTypes = {
	match: PropTypes.object,
}

function CompanyRecordValidate({ match }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation(['user', 'companyRecord'])
	const { setTitleBar } = useTotalTitleBarContext()
	const [steps, setSteps] = useState([])
	const [currentStep, setCurrentStep] = useState({})
	const [chevronsValidity, setChevronsValidity] = useState([])
	const [canValid, setValid] = useState(false)
	const [canRefused, setRefused] = useState(false)
	const [pdp, setPdp] = useState({})
	const [opInfos, setOpInfos] = useState({})
	const { platformId, pdpId, revisionNumber, companyId, companyRecordId } = match.params
	const { enqueueSnackbar } = useSnackbar()
	const [update, setUpdate] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [loading, setLoading] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [refusalMessages, setRefusalMessages] = useState([])
	const [refusalMessageSelected, setRefusalMessageSelected] = useState(null)

	const sendRequest = (data) => {
		if (!data.isValidated && (!data.reason || data.reason.trim() === '')) {
			enqueueSnackbar(t('CommentMusntBeEmpty'), { variant: 'warning' })
			return
		}

		confirmCompanyRecordById(platformId, companyRecordId, data, (res, err) => {
			if (err) {
				if (err.data && err.data[0] && err.data[0].code) {
					enqueueSnackbar(t(`companyRecord:${err.data[0].code}`), { variant: 'error' })
				} else {
					enqueueSnackbar(t('ErrorValidateStep') + `"${currentStep.name}" !`, {
						variant: 'error',
					})
				}
			} else if (res && res.status === 200) {
				if (data.isValidated)
					enqueueSnackbar(t('StepOfWorkflow') + ` "${currentStep.name}" ` + t('validated'), {
						variant: 'success',
					})
				else
					enqueueSnackbar(t('StepOfWorkflow') + ` "${currentStep.name}" ` + t('refused'), {
						variant: 'success',
					})
				setRedirect(true)
			}
		})
		setUpdate(!update)
	}

	useEffect(() => {
		if (!companyRecordId) return console.log('PAS DE PARAMS !')

		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'person',
				title:
					t('FollowOfCompanyRecordOf') +
					` ${opInfos.entRaisonSociale} ( ${opInfos.entNomAgence} ) ${t('forThePdP')} ${
						pdp.pdpNumero
					}`,
			},
		})
		return () => {
			setTitleBar({})
		}
	}, [pdp, opInfos])

	useEffect(() => {
		setUpdate(!update)
		let Filters = 'None'
		recoverOperationalInfos(Filters, companyId, (res, err) => {
			if (err) return console.error(err)
			if (res) {
				setOpInfos(res.data)
			}
		})

		getPdPInformations(platformId, pdpId, revisionNumber, (res, err) => {
			if (err) return console.error(err)
			if (res) {
				setPdp(res.data)
			}
		})
	}, [])

	useEffect(() => {
		getCompanyRecordById(platformId, companyRecordId, (res, err) => {
			if (err) return
			if (res && res.status === 200) {
				if (!res.data.filter((dt) => dt.isCurrent)[0]) return
				setCurrentStep(res.data.filter((dt) => dt.isCurrent)[0])
				setSteps(res.data)
			}
		})
		recoverRefusalMessage()
	}, [update])

	useEffect(() => {
		autorize()
	}, [currentStep, chevronsValidity])

	useEffect(() => {
		getWorkflowValidationById(platformId, companyRecordId, (res, err) => {
			if (err) return
			if (res && res.status === 200) {
				setChevronsValidity(
					res.data.chevronsState.map((dt) => ({
						code: dt.companyRecordsChevronCodeLabel,
						state: dt.companyRecordsChevronStateLabel,
						isEditedFromPrevious: dt.isEditedFromPrevious,
					})),
				)
			}
		})
	}, [update])

	const isRefused = (requiredChevrons, chevronsState) => {
		if (requiredChevrons.length > 0) {
			requiredChevrons.some((rc) => {
				for (let cs of chevronsState) {
					if (cs.id === rc && cs.state === CHEVRONS_STATES.REFUSE.code) return true
				}
				return false
			})
		}
		return true
	}

	const autorize = () => {
		if (currentStep && !currentStep.companyRecordChevrons) {
			setValid(true)
			setRefused(true)
			return
		}

		const requiredChevrons = currentStep.companyRecordChevrons
		const chevronsState = Object.values(CARDS).map((card) => {
			for (let validity of chevronsValidity) {
				if (validity.code === card.chevronCode) return { id: card.key + 1, state: validity.state }
			}
			return card
		})
		const isValid = requiredChevrons.every((rc) => {
				for (let cs of chevronsState) {
					if (cs.id === rc && cs.state === CHEVRONS_STATES.VALID.code) return true
				}
				return false
			}),
			resultIsRefused = isRefused(requiredChevrons, chevronsState)

		setValid(isValid)
		setRefused(resultIsRefused)
	}

	const onSubmit = (values) => {
		if (values.isValid) {
			validCurrentStep(values.comment)
			return
		}
		refuseCurrentStep(values.comment)
	}

	const validCurrentStep = (comment) => {
		setCurrentStep({ ...currentStep, isValid: true })
		sendRequest({ reason: comment, isValidated: true })
	}

	const refuseCurrentStep = (comment) => {
		setCurrentStep({ ...currentStep, isValid: false })
		sendRequest({ reason: comment, isValidated: false })
	}

	const recoverRefusalMessage = () => {
		getCompanyRecordRefusalMessage(platformId, (res, err) => {
			if (err) console.error(err)

			if (res) {
				setRefusalMessages(res.data.map((x) => x.message))
			}
		})
	}

	const AccreditationProcess = () => {
		if (!currentStep.accountType) return null

		if (redirect) return <Redirect to="/followCR" />

		return (
			<Access accept={VALIDATORS}>
				<Grid item xs={12}>
					<Paper className={classes.container}>
						<div className={classes.header}>
							<Typography variant="h6" color="secondary" inline={true}>
								{currentStep && currentStep.name}
							</Typography>
							{currentStep && currentStep.comment && (
								<Tooltip title={`${t('initialRefusalMessage')} : ${currentStep.comment ?? ''}`}>
									<InfoIcon />
								</Tooltip>
							)}
						</div>

						<Form
							initialValues={{ comment: refusalMessageSelected }}
							onSubmit={onSubmit}
							render={({ handleSubmit, form }) => (
								<form onSubmit={handleSubmit}>
									<div className={classes.containerActions}>
										<Grid container spacing={24}>
											<Grid item sm={12} md={6}>
												{refusalMessages && (
													<InputSelectOnChange
														onChange={(e) => {
															setRefusalMessageSelected(e.target.value)
														}}
														value={refusalMessageSelected}
														name="refusal_messages"
														label={t('companyRecord:refusal_message')}
														className={classNames(classes.space, classes.select)}
														disabled={
															!cookies[ACCOUNT_TYPES_COOKIE].some(
																(role) => role.id === currentStep.accountType,
															)
														}
													>
														{refusalMessages.map((msg) => {
															return (
																<MenuItem key={msg} value={msg}>
																	{msg}
																</MenuItem>
															)
														})}
													</InputSelectOnChange>
												)}
												<InputTextArea
													name="comment"
													label={t('comment')}
													className={classes.textField}
													disabled={
														!cookies[ACCOUNT_TYPES_COOKIE].some(
															(role) => role.id === currentStep.accountType,
														)
													}
												></InputTextArea>
											</Grid>
											<Grid item sm={12} md={6}>
												<Button
													onClick={() => {
														form.change('isValid', true)
													}}
													variant="contained"
													color="inherit"
													className={classNames(classes.acceptButton, classes.space)}
													style={{ marginRight: 5 }}
													disabled={
														!canValid ||
														!cookies[ACCOUNT_TYPES_COOKIE].some(
															(role) => role.id === currentStep.accountType,
														) ||
														loading
													}
													type="submit"
												>
													<Icon>check</Icon>
													<span>{t('validate')}</span>
												</Button>
												<Button
													onClick={() => {
														form.change('isValid', false)
													}}
													variant="contained"
													color="inherit"
													className={classNames(classes.refuseButton, classes.space)}
													disabled={
														!canRefused ||
														!cookies[ACCOUNT_TYPES_COOKIE].some(
															(role) => role.id === currentStep.accountType,
														) ||
														loading
													}
													type="submit"
												>
													<Icon>remove_circle_outline</Icon>
													<span>{t('refuse')}</span>
												</Button>
											</Grid>
										</Grid>
									</div>
								</form>
							)}
						/>
						<MobileStepper
							variant="dots"
							steps={steps.length}
							position="static"
							activeStep={currentStep.stepNumber}
							className={classes.mobileStepper}
							nextButton={<></>}
							backButton={<></>}
						/>
					</Paper>
				</Grid>
			</Access>
		)
	}

	function OICard({ title, state, page: Page, code, required, isEdited }) {
		const [open, setOpen] = useState(false)
		const [icon, setIcon] = useState('wait')
		const currStepData = null
		const PhasesUnsafeOperationsForm = usePhasesUnsafeOperations()
		const PhaseUnsafeObservationForm = usePhaseUnsafeObservationForm()
		const ChemicalProductsStorage = useChemicalProductsStorage()
		const queries = {
			companyRecordId: companyRecordId,
			companyId: companyId,
			platformId: platformId,
		}

		const handleOpen = () => {
			setOpen(true)
		}
		const handleClose = (justClose = false) => {
			if (!justClose) setUpdate(!update)
			setOpen(false)
		}

		const sendChevronValidation = (bool) => {
			setLoading(true)
			setDisabled(true)

			let formData = new FormData()
			formData.append('p_IsValidated', bool)

			switch (code) {
				case CARDS.OperationalInfos.chevronCode:
					sendOPDValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.SubContractingCompanies.chevronCode:
					sendSBCValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.HygieneAndHealth.chevronCode:
					sendHAHValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.DangerActivityPhases.chevronCode:
					sendACPValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.ChemicalProducts.chevronCode:
					sendCHPValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.EnvironmentalAspects.chevronCode:
					sendEVAValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				case CARDS.OperatingModes.chevronCode:
					sendOPMValidation(platformId, companyRecordId, formData, (res, err) => {
						handleResponseValidation(res, err)
					})
					break
				default:
					break
			}
		}

		const handleResponseValidation = (res, err) => {
			setLoading(false)
			setDisabled(false)
			if (err) return console.error(err)
			if (res) {
				handleClose()
			}
		}

		useEffect(() => {
			Object.values(CHEVRONS_STATES).map((cs) => {
				if (cs.code !== state) return
				setIcon(cs.icon)
			})
		}, [state])

		return (
			<Grid md={4} xs={12} item>
				<div className={classes.link} onClick={() => handleOpen()}>
					<Card
						className={classNames({
							[classes.required]: isEdited,
							[classes.card]: true,
						})}
					>
						<CardContent className={classes.contentCard}>
							<Typography className={classes.title} variant="h6">
								{t(title)}
							</Typography>
							{required && <Icon className={classes.icon}>{icon}</Icon>}
						</CardContent>
					</Card>
				</div>
				<ContentSlider open={open} handleClose={() => handleClose(true)} data={{}}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" className={classes.title}>
								{opInfos.entRaisonSociale} {opInfos.entNomAgence} {t('forThePdP')} {pdp.pdpNumero}
							</Typography>
							{required && (
								<>
									<Button
										className={classNames(classes.statusButton, classes.acceptButton)}
										color="inherit"
										variant="contained"
										onClick={() => sendChevronValidation(true)}
										disabled={disabled}
									>
										{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
										{t('validate')}
									</Button>
									<Button
										className={classNames(classes.statusButton, classes.refuseButton)}
										color="inherit"
										variant="contained"
										onClick={() => sendChevronValidation(false)}
										disabled={disabled}
									>
										{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
										{t('refuse')}
									</Button>
								</>
							)}
						</Toolbar>
					</AppBar>
					<div className={classes.content}>
						<Page
							currStep={[currStepData]}
							forms={[
								PhasesUnsafeOperationsForm,
								PhaseUnsafeObservationForm,
								ChemicalProductsStorage,
							]}
							btnsStyle={{}}
							queries={queries}
							isReadOnly
							isReviewMode
						/>
					</div>
					{PhasesUnsafeOperationsForm.formular}
					{PhaseUnsafeObservationForm.formular}
					{ChemicalProductsStorage.formular}
				</ContentSlider>
			</Grid>
		)
	}

	CompanyRecordValidate.propTypes = {
		title: PropTypes.string,
		state: PropTypes.string,
		page: PropTypes.func,
		code: PropTypes.string,
		required: PropTypes.bool,
		isEdited: PropTypes.bool,
	}
	return (
		<>
			<Grid container className={classes.page} spacing={16}>
				<AccreditationProcess />
				{Object.values(CARDS).map((card) => {
					const validity = chevronsValidity.filter((cv) => cv.code === card.chevronCode)[0]
					if (!validity) return
					let required = Object.keys(currentStep).length
						? currentStep.companyRecordChevrons.includes(card.key + 1)
						: false
					let isEdited = validity.isEditedFromPrevious
					return (
						<OICard
							key={card.key}
							title={card.title}
							state={validity.state}
							page={card.page}
							code={card.chevronCode}
							required={required}
							isEdited={isEdited}
						/>
					)
				})}
			</Grid>
		</>
	)
}

export default CompanyRecordValidate
