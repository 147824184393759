import { Fab, Icon, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GetApp from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/styles'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../components/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import { PLATFORM_ID_COOKIE, COMPANY_ID_COOKIE } from '../../constants/cookies'
import { useMatch } from '../../containers/AuthContainer'
import {
	deleteChemicalProduct,
	downloadFile,
	getChemicalProduct,
	getChemicalProductsList,
} from '../../containers/DataContainer'
import { useChemicalProductsForm } from '../../containers/FormContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	alignRight: {
		textAlign: 'right',
	},
	table: {
		height: '100%',
		width: '90%',
		marginLeft: '5%',
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
	dataTable: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		marginBottom: '10px',
	},
	icon: {
		color: theme.palette.secondary.main,
	},
	fdsValidity: {
		display: 'flex',
		alignItems: 'center',
	},
	iconValidity: {
		marginLeft: 50,
	},
	green: {
		color: 'limegreen',
	},
}))

const ChemicalProducts = ({ match }) => {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'companyRecord'])
	const { setTitleBar } = useTotalTitleBarContext()
	const [cookies] = useCookies()
	const { companyId, formatUrl } = useMatch(match, '/op-infos')
	const targetCompanyID = companyId != null ? companyId : cookies[COMPANY_ID_COOKIE]
	const [cpData, setCpData] = useState()
	const ChemicalProductsForm = useChemicalProductsForm(cpData)
	const [result, setResult] = useState({})
	const [refresh, setRefresh] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const [isLoading, setIsLoading] = useState(true)
	const [indexValidityEndDate, setIndexValidityEndDate] = useState()

	const AddChemical = () => {
		const newProduct = () => {
			setCpData({ id: null, companyId: targetCompanyID, setRefresh: setRefresh, refresh: refresh })
			ChemicalProductsForm.open()
		}

		return (
			<>
				<Fab className={classes.fab} color="secondary" onClick={newProduct}>
					<AddIcon />
				</Fab>
			</>
		)
	}

	const downloadCP = (productId) => {
		getChemicalProduct({ productId: productId, companyId: targetCompanyID }, (response, error) => {
			if (error) console.error(error)
			if (response && response.data && response.data.prdFDSCheminComplet) {
				downloadFile(response.data.prdFDSCheminComplet, (res, err) => {
					if (err) {
						managedFileNotFoundError(err, t, enqueueSnackbar)
						console.error(err)
					}
					if (res) fileDownload(res.data, getFileNameToHeaders(res.headers) /* TEMPORARY */)
				})
			}
		})
	}

	const editCP = (productId) => {
		setCpData({
			id: productId,
			companyId: targetCompanyID,
			setRefresh: setRefresh,
			refresh: refresh,
		})
		ChemicalProductsForm.open()
	}

	const deleteCP = (productId) => {
		deleteChemicalProduct({ productId: productId, companyId: targetCompanyID }, (res, err) => {
			if (err) console.error(err)
			if (res) {
				setRefresh(!refresh)
				enqueueSnackbar('Le produit chimique a été supprimé', { variant: 'info' })
			}
		})
	}

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: t('user:OperationnalData') },
		})
	}, [])

	useEffect(() => {
		let data = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			nbItemsAsked: 10,
			pageNumber: 1,
		}
		getChemicalProductsList(targetCompanyID, data, function (res, error) {
			if (res) {
				let tempRes = res.data
				let newColumns = {
					name: 'id',
					label: 'action',
					options: {
						display: 'true',
						filter: false,
						filterOptions: null,
						searchable: false,
						sort: false,
					},
				}
				tempRes.columns = [...tempRes.columns, newColumns]
				setIndexValidityEndDate(tempRes.columns.findIndex((x) => x.name === 'fdsvalidityenddate'))
				setResult(tempRes)
				setIsLoading(false)
			}

			if (error) {
				console.log(error)
			}
		})
	}, [refresh])

	return (
		<>
			{ChemicalProductsForm.formular}
			<HeadTitle title={t('user:chemicalsProducts')} link={formatUrl} />
			<DataTable
				title={t('user:chemicalsProducts')}
				data={result.data}
				columns={result.columns}
				options={result.options}
				isLoading={isLoading}
				customColumns={[
					{
						name: 'id',
						component: function componentId(value) {
							return (
								<>
									<Tooltip title={t('download')}>
										<IconButton size="small" onClick={() => downloadCP(value)}>
											<GetApp className={classes.icon} />
										</IconButton>
									</Tooltip>
									<Tooltip title={t('edit')}>
										<IconButton size="small" onClick={() => editCP(value)}>
											<EditIcon className={classes.icon} />
										</IconButton>
									</Tooltip>
									<Tooltip title={t('delete')}>
										<IconButton size="small" onClick={() => deleteCP(value)}>
											<DeleteIcon className={classes.icon} />
										</IconButton>
									</Tooltip>
								</>
							)
						},
					},
					{
						name: 'dangerphrase',
						component: (value) => {
							return value.join(', ')
						},
					},
					{
						name: 'fdsvalidityduration',
						component: function componentValidityDuration(value, tableMeta) {
							const endDate = new Date(tableMeta.rowData[indexValidityEndDate])
							return (
								<>
									<div className={classes.fdsValidity}>
										<span>
											{value} {value > 1 ? 'ans' : 'an'}
										</span>
										<Tooltip placement="top" title={endDate.toLocaleString().split(' ')[0]}>
											{new Date() > endDate ? (
												<Icon className={classes.iconValidity} color="error">
													cancel
												</Icon>
											) : (
												<Icon className={classNames(classes.iconValidity, classes.green)}>
													check_circle
												</Icon>
											)}
										</Tooltip>
									</div>
								</>
							)
						},
					},
				]}
				customLabels={[
					{
						name: 'name',
						label: t('name'),
					},
					{
						name: 'dangerphrase',
						label: t('danger_phrase'),
					},
					{
						name: 'suppliername',
						label: t('supplier_name'),
					},
					{
						name: 'action',
						label: t('Action'),
					},
					{
						name: 'fdsvalidityduration',
						label: t('chemical:fdsvalidityduration'),
					},
				]}
			/>
			<AddChemical />
		</>
	)
}
ChemicalProducts.propTypes = {
	match: PropTypes.object,
}
export default ChemicalProducts
