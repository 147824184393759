import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Fab,
	Typography,
	Icon,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import DeleteForever from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import ROLES from '../../constants/roles'
import { copyPdP, deletePdP, pdpList } from '../../containers/DataContainer'
import Access from '../../containers/RoleContainer'
import { SITE_USING_RESTRICTED_PDP } from '../../constants/restrictedPdPSites.js'
import { COLUMNS_FOLLOW_PDP, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	content: {
		margin: theme.spacing.unit * 3,
	},
	addPdp: {
		position: 'fixed',
		right: theme.spacing.unit,
		bottom: theme.spacing.unit,
		zIndex: 1,
	},
	icon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	dataTableLink: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: 14,
		right: 300,
	},
	IconAndSubText: {},
	ISIcon: {
		display: 'block',
		margin: 'auto',
	},
	ISSubText: {
		textAlign: 'center',
		fontSize: 10,
	},
}))

export default function FollowPreventionPlan() {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [redirect, setRedirect] = useState(false)
	const [cookies] = useCookies()
	const [result, setResult] = useState({ columns: [], data: [], options: {} })
	const [redirectToPdP, setRedirectToPdP] = useState({})
	const [openCopy, setOpenCopy] = useState(false)
	const [openDeletePdP, setOpenDeletePdP] = useState(false)
	const [pdPData, setPdPData] = useState({})
	const { enqueueSnackbar } = useSnackbar()
	const [refresh, setRefresh] = useState(false)
	const [isInExtendedView, setInExtendedView] = useState(false)
	const [canCreateRestrictedPdP, setCanCreateRestrictedPdP] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const switchView = () => {
		setInExtendedView(!isInExtendedView)
	}

	useEffect(() => {
		// Check if current site can create a restricted pdp
		if (SITE_USING_RESTRICTED_PDP.find((c) => c === cookies[PLATFORM_ID_COOKIE])) {
			setCanCreateRestrictedPdP(true)
		}

		let body = {
			nbItemsAsked: 10,
			pageNumber: 1,
			includeAll: isInExtendedView,
		}
		setIsLoading(true)
		pdpList(cookies[PLATFORM_ID_COOKIE], body, function (res, error) {
			if (res) {
				let tempRes = res.data
				let newColumns = {
					name: 'id',
					label: 'action',
					options: {
						display: 'true',
						filter: false,
						filterOptions: null,
						searchable: false,
						sort: false,
					},
				}
				tempRes.columns = [...tempRes.columns, newColumns]
				setResult(tempRes)
			}
			setIsLoading(false)
		})
	}, [refresh, isInExtendedView])

	const CopyPdP = () => {
		copyPdP(
			cookies[PLATFORM_ID_COOKIE],
			pdPData.pdpId,
			pdPData.revisionNumber,
			function (res, error) {
				if (res) {
					setRedirectToPdP({
						isTrue: true,
						pdpId: res.data.pdPId,
						revisionNumber: res.data.revisionNumber,
					})
				}
				if (error) {
					enqueueSnackbar(t('error') + ':' + error.status, { variant: 'error' })
				}
			},
		)
	}

	const DeletePdP = () => {
		deletePdP(cookies[PLATFORM_ID_COOKIE], pdPData.pdpId, function (res, error) {
			if (res) {
				enqueueSnackbar(t('ThePdPHasBeenDeleted'), { variant: 'info' })
				setRefresh(!refresh)
				setOpenDeletePdP(false)
			}
			if (error) {
				enqueueSnackbar(t('error') + ':' + error.status, { variant: 'error' })
			}
		})
	}

	const IDWP = {
		AUTO: {
			id: 'Received',
			label: t('user:Receive'),
			icon: 'checked_circle',
			color: '#006946',
		},
		ERROR: {
			id: 'Failed',
			label: t('user:ErrorSend'),
			icon: 'error',
			color: '#F44436',
		},
	}

	const IconWithSubtext = ({ icon, label, color }) => {
		return (
			<div className={classes.IconAndSubText}>
				<Icon className={classes.ISIcon} style={{ color: color }}>
					{icon}
				</Icon>
				<Typography className={classes.ISSubText} style={{ color: color }}>
					{label}
				</Typography>
			</div>
		)
	}

	const findIDWPById = (id) =>
		Object.values(IDWP).filter((s) => {
			if (s.id === id) return s
		})[0]

	const CopyPdpPopup = () => (
		<Dialog open={openCopy} onClose={() => setOpenCopy(false)}>
			<DialogContent>
				<Typography variant="subtitle1">{t('SureToCreatePdP')}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpenCopy(false)} color="secondary">
					{t('cancel')}
				</Button>
				<Button
					onClick={() => {
						CopyPdP()
					}}
					color="secondary"
				>
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)

	const DeletePdpPopup = () => (
		<Dialog open={openDeletePdP} onClose={() => setOpenDeletePdP(false)}>
			<DialogContent>
				<Typography variant="subtitle1">{t('SureToDeletePdP')}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpenDeletePdP(false)} color="secondary">
					{t('cancel')}
				</Button>
				<Button
					onClick={() => {
						DeletePdP()
					}}
					color="secondary"
				>
					{t('confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)

	return (
		<>
			{redirect && <Redirect to={'/preventionPlan'} />}
			{redirectToPdP.isTrue && (
				<Redirect
					to={'/preventionPlan/' + redirectToPdP.pdpId + '/' + redirectToPdP.revisionNumber}
				/>
			)}
			<CopyPdpPopup />
			<DeletePdpPopup />
			<div className={classes.content}>
				<HeadTitle title={t('FollowPdP')} link="/dashboard" />
				<br />
				<div className={classes.dataTableContainer}>
					<Access
						accept={[
							ROLES.PLATFORMMANAGER,
							ROLES.ADMIN,
							ROLES.EXTCOMP,
							ROLES.EXTCOMPADMIN,
							ROLES.PRINCIPAL,
						]}
					>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={() => switchView()}
						>
							{isInExtendedView ? t('completeView') : t('limitedView')}
						</Button>
					</Access>
					<DataTable
						title={t('FollowPdP')}
						data={result.data}
						columns={result.columns}
						options={result.options}
						isLoading={isLoading}
						customColumns={[
							{
								name: 'date_debut',
								component: (value, tableMeta, updateValue) => moment(value).format('DD/MM/YYYY'),
							},
							{
								name: 'date_fin',
								component: (value, tableMeta, updateValue) => moment(value).format('DD/MM/YYYY'),
							},
							{
								name: 'date_diffusion',
								component: (value, tableMeta, updateValue) => {
									if (value) return moment(value).format('DD/MM/YYYY')
								},
							},
							{
								name: 'dwpStatusCode',
								component: (value, tableMeta, updateValue) => {
									if (!value) return
									let currentState = findIDWPById(value)
									if (!currentState) return
									return (
										<IconWithSubtext
											icon={currentState.icon}
											label={currentState.label}
											color={currentState.color}
										/>
									)
								},
							},
							{
								name: 'id',
								component: (_value, tableMeta) => {
									const numeroFormat =
										tableMeta.rowData[
											getIndexOfColumn(result.columns, COLUMNS_FOLLOW_PDP.NUMERO_FORMAT)
										]
									const pdpId =
										tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_FOLLOW_PDP.ID)]
									const pdpStatus =
										tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_FOLLOW_PDP.STATUS)]

									let pdpInfos = {
										pdpId: pdpId,
										revisionNumber: numeroFormat ?? 0,
									}
									return (
										<>
											<a
												href={'/preventionPlan/' + pdpInfos.pdpId + '/' + pdpInfos.revisionNumber}
												alt="pdp"
												className={classes.dataTableLink}
											>
												<EditIcon className={classes.icon} />
											</a>
											{pdpStatus === 'Brouillon' && (
												<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
													<DeleteForever
														color="secondary"
														className={classes.icon}
														onClick={() => {
															setOpenDeletePdP(true)
															setPdPData({
																pdpId: pdpId,
																revisionNumber: numeroFormat ?? 0,
															})
														}}
													></DeleteForever>
												</Access>
											)}
											{pdpStatus === 'Archivé' && (
												<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
													<AddCircleOutline
														color="secondary"
														className={classes.icon}
														onClick={() => {
															setOpenCopy(true)
															setPdPData({
																pdpId: pdpId,
																revisionNumber: numeroFormat ?? 0,
															})
														}}
													></AddCircleOutline>
												</Access>
											)}
										</>
									)
								},
							},
						]}
						customLabels={[
							{
								name: 'numero_pdp',
								label: t('numero_pdp'),
							},
							{
								name: 'status',
								label: t('status'),
							},
							{
								name: 'numero_format',
								label: t('numero_format'),
							},
							{
								name: 'label',
								label: t('label'),
							},
							{
								name: 'works_locations',
								label: t('works_locations'),
							},
							{
								name: 'date_debut',
								label: t('date_debut'),
							},
							{
								name: 'date_fin',
								label: t('date_fin'),
							},
							{
								name: 'date_diffusion',
								label: t('date_diffusion'),
							},
							{
								name: 'dwpStatusCode',
								label: t('dwpstatuscode'),
							},
							{
								name: 'dwpStatusLabel',
								label: t('dwpstatuscode'),
							},
							{
								name: 'action',
								label: t('Action'),
							},
						]}
					/>
				</div>

				{canCreateRestrictedPdP && (
					<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL]}>
						<Fab
							color="secondary"
							aria-label="add"
							className={classes.addPdp}
							onClick={() => {
								setRedirect(true)
							}}
						>
							<AddIcon />
						</Fab>
					</Access>
				)}

				{!canCreateRestrictedPdP && (
					<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
						<Fab
							color="secondary"
							aria-label="add"
							className={classes.addPdp}
							onClick={() => {
								setRedirect(true)
							}}
						>
							<AddIcon />
						</Fab>
					</Access>
				)}
			</div>
		</>
	)
}
