import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import api from '../../utils/Requester'
import { MAIL_ARCHIVE, RESEND_MAIL } from '../../constants/url'
import { useCookies } from 'react-cookie'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import i18next from 'i18next'
import { bindParams } from '../../utils/Link'
import GetApp from '@material-ui/icons/GetApp'
import AutoRenew from '@material-ui/icons/Autorenew'
import { makeStyles } from '@material-ui/styles'
import { downloadFile } from '../../containers/DataContainer'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'
import fileDownload from 'js-file-download'
import { Tooltip, Modal, Typography, Button } from '@material-ui/core'
import classNames from 'classnames'
import { COLUMNS_MAILS_COMMUNICATION, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '10%',
		maxWidth: '60%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	title: {
		color: theme.palette.secondary.main,
		marginBottom: 20,
	},
	icon: {
		color: theme.palette.secondary.main,
		display: 'block',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	iconInvalid: {
		opacity: 0.5,
		'&:hover': {
			cursor: 'not-allowed',
		},
	},
	buttonGroup: {
		display: 'flex',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		padding: 7,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
}))

function MailCommunication() {
	const { t } = useTranslation(['user', 'communication'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const classes = useStyles()

	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const [isLoading, setIsLoading] = useState(true)
	const [open, setOpen] = useState(false)
	const [mailId, setMailId] = useState(null)

	useEffect(() => {
		const getMailArchive = () => {
			setIsLoading(true)
			api.get(bindParams(MAIL_ARCHIVE, cookies[PLATFORM_ID_COOKIE])).then((res) => {
				const { ok, data } = res

				if (ok) {
					if (data) {
						setDatatable(data)
					}
				} else enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })

				setIsLoading(false)
			})
		}
		getMailArchive()
	}, [])

	const customColumns = [
		{
			name: 'date',
			component: (value) => {
				moment.locale(i18next.language)
				return moment(value).format('DD/MM/YYYY HH:mm')
			},
		},
		{
			name: 'attachment',
			component: function componentDocument(value) {
				if (!value) return t('communication:no_document')
				return (
					<Tooltip title={t('download')}>
						<GetApp
							classes={{ root: classes.icon }}
							onClick={() => {
								downloadDocument(value)
							}}
						/>
					</Tooltip>
				)
			},
		},
		{
			name: 'message',
			component: function componentDocument(value, tableMeta) {
				return (
					<Tooltip title={t('resend')}>
						<AutoRenew
							className={classNames(classes.icon)}
							onClick={() => {
								openConfirm(
									tableMeta.rowData[
										getIndexOfColumn(datatable.columns, COLUMNS_MAILS_COMMUNICATION.ID)
									],
								)
							}}
						/>
					</Tooltip>
				)
			},
		},
	]

	const openConfirm = (mailId) => {
		const data = { mailArchiveId: mailId }
		setOpen(true)
		setMailId(data)
	}

	const close = () => {
		setOpen(false)
		setMailId(null)
	}

	const resendMail = () => {
		api.post(RESEND_MAIL, mailId).then((res) => {
			const { ok } = res

			if (ok) {
				enqueueSnackbar(t('communication:mail_resent'), { variant: 'success' })
			} else enqueueSnackbar(t('communication:mail_not_resent'), { variant: 'error' })

			close()
		})
	}

	const downloadDocument = (file) => {
		downloadFile(file, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				if (error.data && error.data.code) {
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
					return
				}
				enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	return (
		<>
			<HeadTitle title={t('user:mail_communication_title')} />
			<DataTable
				title="Suivi"
				columns={[...datatable.columns]}
				data={datatable.data}
				options={datatable.options}
				customColumns={customColumns}
				isLoading={isLoading}
				customLabels={[
					{
						name: 'recipients',
						label: t('user:mail_communication_thead_recipients'),
					},
					{
						name: 'subject',
						label: t('user:mail_communication_thead_subject'),
					},
					{
						name: 'date',
						label: t('user:mail_communication_thead_date'),
					},
					{
						name: 'platformName',
						label: t('user:mail_communication_thead_platform_name'),
					},
					{
						name: 'userFullName',
						label: t('user:mail_communication_thead_user_full_name'),
					},
					{
						name: 'attachment',
						label: t('user:document'),
					},
					{
						name: 'message',
						label: t('user:resend'),
					},
				]}
			/>
			<Modal open={open}>
				<div className={classes.paper}>
					<Typography variant="h6" classes={{ root: classes.title }}>
						{t('user:mailCommunication_title')}
					</Typography>
					<Typography variant="body1" classes={{ root: classes.title }}>
						{t('user:mailCommunication_body')}
					</Typography>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button
							className={classNames(classes.button, classes.validateButton)}
							onClick={resendMail}
						>
							{t('submit')}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default MailCommunication
