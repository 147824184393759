import React, { useState, useEffect, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import HeadTitle from '../../components/HeadTitle'
import { useTranslation } from 'react-i18next'
import BasicCard from '../../components/Cards/BasicCard'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import * as link from '../../constants/url'
import { useSnackbar } from 'notistack'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { getFileNameToHeaders } from '../../utils/File'
import fileDownload from 'js-file-download'
import { getCompanyByPdp } from '../../containers/DataContainer'
import { useSelector } from 'react-redux'
import ModalContacts from './Modals/ModalContacts'
import ModalChemicalProducts from './Modals/ModalChemicalProducts'
import ModalActivityPhase from './Modals/ModalActivityPhase'

const useStyles = makeStyles((theme) => ({
	titlecentered: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
	},
	container: {
		padding: theme.spacing.unit,
		paddingBottom: theme.spacing.unit * 10,
	},
	buttonProgress: {
		color: 'black',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

function Reports() {
	const [cookies] = useCookies(['platformId'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user', 'chemical', 'reports'])
	const [isOpenChemicalProducts, setIsOpenChemicalProducts] = useState(false)
	const [isOpenActivityPhase, setIsOpenActivityPhase] = useState(false)
	const [isOpenContacts, setIsOpenContacts] = useState(false)
	const [loading, isLoading] = useState(false)

	const [companyPdp, setCompanyPdp] = useState([])
	const [currentPdp, setCurrentPdp] = useState()
	const token = useSelector((state) => state.token.token)

	const recoveryLink = (res) => {
		const { data, problem, headers, status } = res
		isLoading(false)
		if (problem)
			return enqueueSnackbar(
				status === 404 ? `${t('reports:noFilePresent')} !` : `${t('reports:somethingWrong')}`,
				{ variant: 'info' },
			)

		if (data) {
			let blob = data
			const _filename = getFileNameToHeaders(headers)
			if (!_filename.includes('.zip'))
				blob = new Blob(['\ufeff', data], {
					type: headers['content-type'],
				})
			fileDownload(blob, _filename)
		}
	}

	const callToDownload = (link, param = {}) => {
		isLoading(true)
		api
			.get(bindParams(link, cookies[PLATFORM_ID_COOKIE]), param, {
				headers: {
					Pragma: 'no-cache',
					Authorization: `Bearer ${token}`,
				},
				responseType: 'arraybuffer',
			})
			.then((res) => recoveryLink(res))
	}

	const stateModalActivityPhase = () => {
		setIsOpenActivityPhase(!isOpenActivityPhase)
	}

	const stateModalChemicalProducts = () => {
		setIsOpenChemicalProducts(!isOpenChemicalProducts)
	}
	const stateModalContacts = () => {
		setIsOpenContacts(!isOpenContacts)
	}

	/*** OPEN MODALS ***/
	const openChemicalProductModal = useCallback(() => {
		if (!loading) {
			stateModalChemicalProducts()
		}
	})

	const openActivityPhase = useCallback(() => {
		if (!loading) {
			stateModalActivityPhase()
		}
	})

	const openContactModal = useCallback(() => {
		if (!loading) {
			stateModalContacts()
		}
	})
	/*********/
	/*** SUBMIT ***/
	const onContactsSubmit = useCallback((activeContacts) => {
		isLoading(true)
		callToDownload(link.REPORTS_COMPANY_CONTACT_LIST, {
			p_ActiveContacts: activeContacts,
		})
	})

	const onChemicalProductSubmit = useCallback((link) => {
		isLoading(true)
		callToDownload(link)
	})

	const onActivityPhaseSubmit = useCallback((link) => {
		const param = currentPdp ? { p_PdpId: currentPdp } : {}
		isLoading(true)
		callToDownload(link, param)
	})

	const onSubmitOnclickCard = useCallback((link) => () => {
		if (!loading) {
			callToDownload(link)
		}
	})
	/*********/

	useEffect(() => {
		getCompanyByPdp(cookies[PLATFORM_ID_COOKIE], function (res) {
			if (res) {
				setCompanyPdp(res.data)
			}
		})
	}, [])

	return (
		<>
			<HeadTitle title={t('user:reports')} />
			<Grid container spacing={16} direction="row" className={classes.container}>
				<BasicCard
					size={4}
					onClick={() => {
						if (!loading) {
							callToDownload(link.REPORTS_SUBCONTRACTORS, t('reports:SubcontractingRelationship'))
						}
					}}
					disableAction={true}
				>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:SubcontractingRelationship')}
					</Typography>
				</BasicCard>
				<BasicCard size={4} onClick={openActivityPhase} disableAction={true}>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:ActivityPhase')}
					</Typography>
				</BasicCard>
				<BasicCard size={4} onClick={onSubmitOnclickCard(link.REPORTS_JOBS)} disableAction={true}>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:CompagnyJob')}
					</Typography>
				</BasicCard>
				<BasicCard
					size={4}
					onClick={onSubmitOnclickCard(link.REPORTS_OBSERVATIONS)}
					disableAction={true}
				>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:Observation')}
					</Typography>
				</BasicCard>
				<BasicCard size={4} onClick={openChemicalProductModal} disableAction={true}>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('user:ChemicalProducts')}
					</Typography>
				</BasicCard>
				<BasicCard
					size={4}
					onClick={onSubmitOnclickCard(link.REPORTS_COMPANY_LIST)}
					disableAction={true}
				>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:ListCompagny')}
					</Typography>
				</BasicCard>
				<BasicCard size={4} onClick={openContactModal} disableAction={true}>
					<Typography className={classes.titlecentered} variant="h6">
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('reports:ListContact')}
					</Typography>
				</BasicCard>
				<ModalChemicalProducts
					isOpen={isOpenChemicalProducts}
					onClose={setIsOpenChemicalProducts}
					onSubmit={onChemicalProductSubmit}
					loading={loading}
				/>
				<ModalActivityPhase
					isOpen={isOpenActivityPhase}
					onClose={setIsOpenActivityPhase}
					onSubmit={onActivityPhaseSubmit}
					loading={loading}
					companyPdp={companyPdp}
					currentPdp={currentPdp}
					setCurrentPdp={setCurrentPdp}
				/>
				<ModalContacts
					isOpen={isOpenContacts}
					onClose={setIsOpenContacts}
					onSubmit={onContactsSubmit}
					loading={loading}
				/>
			</Grid>
		</>
	)
}
export default Reports
