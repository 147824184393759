import React, { useState, useEffect } from 'react'
import { PLATFORM_ID_COOKIE, ACCOUNT_TYPES_COOKIE } from '../../../../constants/cookies'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button, Stepper, Step, StepButton, StepLabel, Paper } from '@material-ui/core'
import Form from '../../../Form/Form'
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepChecklist from './Steps/StepChecklist'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import {
	getOperationDescription,
	upsertCompanyRecord,
	getOperationDescriptionInit,
} from '../../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { Redirect } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { HandlingErrorMessages } from '../../../../utils/Errors'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	content: {
		padding: 15,
	},
	stepIcon: {
		color: theme.palette.secondary.main + '!important',
	},
	stepText: {
		fill: theme.palette.primary.main,
	},
	buttonGroup: {
		display: 'flex',
	},
	button: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	arrows: {
		margin: 'auto',
		fontSize: '4em',
		maxWidth: 50,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	stepper: {
		minWidth: '80%',
	},
	completed: {
		color: theme.palette.secondary.main,
		border: `solid 2px ${theme.palette.secondary.main}`,
		padding: '10px 25px',
		marginTop: 50,
		borderRadius: 30,
		marginBottom: 10,
		backgroundColor: '#ffffff',
	},
}))

DescriptionOfOperationsForm.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	queries: PropTypes.object,
	isReadOnly: PropTypes.bool,
	isReviewMode: PropTypes.bool,
	reviewData: PropTypes.object,
	setRevisionCompanyRecordId: PropTypes.func,
	setCompanyRecordId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
}
function DescriptionOfOperationsForm({
	forms,
	btnsStyle,
	queries,
	isReadOnly,
	isReviewMode = false,
	setRevisionCompanyRecordId,
	setCompanyRecordId,
	nextStep,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['user, companyRecord'])
	const { enqueueSnackbar } = useSnackbar()
	const [steps, setSteps] = useState([])
	const { companyRecordId, companyId, pdpId, pdpRevisionId, sousTraitant, restrictedPdPContext } =
		queries
	const [cookies] = useCookies()
	const [
		AddCompanyFilesContact,
		AddCompanyFilesCertification,
		AddCompanyFilesDocuments,
		ExecutionManagerForm,
		CreateContactForm,
		CertificationDEForm,
		CheckListForm,
	] = forms

	const [activeStep, setActiveStep] = useState(0)
	const [operationInfos, setOperationInfos] = useState({})
	const [initialValues, setInitialValues] = useState({})
	const [operationManager, setOperationManager] = useState(null)
	const [certificationList, setCertificationList] = useState([])
	const [documentsList, setDocumentsList] = useState([])
	const [checkListDocuments, setCheckListDocuments] = useState([])
	const [companyRecordState, setCompanyRecordState] = useState(null)

	const [technicalsManagerList, setTechnicalsManagerList] = useState([])
	const [newCompanyRecordId, setNewCompanyRecordId] = useState(null)
	const [redirect, setRedirect] = useState(false)
	const [cancelDraft] = useState(false)
	const [createContactResult, setCreateContactResult] = useState(null)
	const [typeCreated, setTypeCreated] = useState()
	const [refresh, setRefresh] = useState(true)
	const [principal, setPrincipal] = useState()
	const [freezePrincipal, setFreezePrincipal] = useState()
	const [checkListItemOnFocus, setCheckListItemOnFocus] = useState(null)
	const [onClickOpenCheckListForm, setOnClickOpenCheckListForm] = useState(false)

	const platformId = parseInt(queries.platformId || cookies[PLATFORM_ID_COOKIE])

	const isAdminOrManager = cookies[ACCOUNT_TYPES_COOKIE].some(
		(ur) => ur.code === 'ADMIN' || ur.code === 'PLATFORMMANAGER',
	)

	//#region OperationInfos
	const getOperationInfos = () => {
		const fetchData = async () => {
			if (!companyRecordId)
				await getOperationDescriptionInit(
					platformId,
					companyId,
					pdpId,
					pdpRevisionId,
					(res, err) => {
						if (err) return console.error('Failed to get init data for new company record')
						if (!res || !res.data) return
						setTechnicalsManagerList(res.data.contacts)
						setOperationInfos(res.data)
						setPrincipal(res.data.principalId)
						setFreezePrincipal(isAdminOrManager ? false : res.data.freezePrincipal)
						setCheckListDocuments(res.data.checkListDocuments)
					},
				)
			else
				await getOperationDescription(platformId, companyId, companyRecordId, (res, err) => {
					if (err) return console.error('Error to subcontractor companies datatable !')
					if (!res || !res.data) return
					setCertificationList(res.data.certifications)
					setOperationManager(res.data.operationExecutionManager)
					setTechnicalsManagerList(res.data.technicalManagers)
					setOperationInfos(res.data)
					setDocumentsList(res.data.otherDocuments)
					setCheckListDocuments(res.data.checkListDocuments)
					setPrincipal(res.data.principalId)
					setFreezePrincipal(isAdminOrManager ? false : res.data.freezePrincipal)
					setCompanyRecordState(res.data.companyRecordState)
				})
		}

		fetchData().catch(console.error)
	}

	const getSteps = () => {
		if (
			checkListDocuments &&
			checkListDocuments.checkListItemsCompanyRecord &&
			checkListDocuments.checkListItemsCompanyRecord.length
		)
			return ['Description', 'Contacts', 'Certification', 'CheckList', 'Autre document']
		else return ['Description', 'Contacts', 'Certification', 'Autre document']
	}

	useEffect(() => {
		;(async () => {
			getOperationInfos()
		})()
	}, [])

	const openAddCompanyFilesCertification = () => {
		AddCompanyFilesCertification.addData({
			setCertificationList: setCertificationList,
			certificationList: certificationList,
			companyId: companyId,
			openCertificationDEForm: openCertificationDEForm,
		})
		AddCompanyFilesCertification.open()
	}
	const openAddCompanyFilesContact = () => {
		AddCompanyFilesContact.addData({
			setTechnicalsManagerList: setTechnicalsManagerList,
			technicalsManagerList: technicalsManagerList,
			companyId: companyId,
			openCreateContactForm: openCreateContactForm,
			setTypeCreated: setTypeCreated,
		})
		AddCompanyFilesContact.open()
	}
	const openExecutionManagerForm = () => {
		ExecutionManagerForm.addData({
			operationManager: operationManager,
			setOperationManager: setOperationManager,
			companyId: companyId,
			openCreateContactForm: openCreateContactForm,
			setTypeCreated: setTypeCreated,
		})
		ExecutionManagerForm.open()
	}

	const openCreateContactForm = (contactList = null, checkedDefaultTypes = null) => {
		CreateContactForm.addData({
			companyId: companyId,
			createContactResult: createContactResult,
			setCreateContactResult: setCreateContactResult,
			contactList,
			checkedDefaultTypes,
		})
		CreateContactForm.open()
	}

	const openAddCompanyFilesDocuments = () => {
		AddCompanyFilesDocuments.addData({
			companyId: companyId,
			platformId: platformId,
			createContactResult: createContactResult,
			setCreateContactResult: setCreateContactResult,
			setDocumentsList: setDocumentsList,
			documentsList: documentsList,
		})
		AddCompanyFilesDocuments.open()
	}

	const openCertificationDEForm = () => {
		CertificationDEForm.addData({
			setCertificationList: setCertificationList,
			certificationList: certificationList,
			companyId: companyId,
		})
		CertificationDEForm.open()
	}

	const openCheckListForm = () => {
		CheckListForm.addData({
			platformId: platformId,
			companyId: companyId,
			setChecklistDocuments: setCheckListDocuments,
			checkListDocuments: checkListDocuments,
			checkListItemOnFocus: checkListItemOnFocus,
			setOnClickOpenCheckListForm: setOnClickOpenCheckListForm,
		})
		CheckListForm.open()
	}

	useEffect(() => {
		setSteps(getSteps())
	}, [checkListDocuments])

	useEffect(() => {
		if (onClickOpenCheckListForm) openCheckListForm()
	}, [checkListItemOnFocus, onClickOpenCheckListForm])

	const addCreatedManager = () => {
		if (!createContactResult) {
			setRefresh(!refresh)
		} else {
			if (typeCreated === 'resp') {
				setOperationManager(createContactResult[0])
				setCreateContactResult()
				return
			}
			setTechnicalsManagerList(createContactResult)
			setCreateContactResult()
		}
	}

	useEffect(() => addCreatedManager, [createContactResult, typeCreated, refresh])

	useEffect(() => {
		if (newCompanyRecordId && !companyRecordId) {
			if (restrictedPdPContext) setCompanyRecordId(newCompanyRecordId)
			else setRedirect(true)
			return
		}
		setRedirect(false)
	}, [newCompanyRecordId])
	//#endregion OperationInfos

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<StepOne
						platformId={platformId}
						operationInfos={operationInfos}
						principal={principal}
						setPrincipal={setPrincipal}
						isReadOnly={isReadOnly}
						restrictedPdPContext={restrictedPdPContext}
						freezePrincipal={freezePrincipal}
						companyRecordState={companyRecordState}
						isAdminOrManager={isAdminOrManager}
					/>
				)
			case 1:
				return (
					<StepTwo
						operationInfos={operationInfos}
						openAddCompanyFilesContact={openAddCompanyFilesContact}
						openExecutionManagerForm={openExecutionManagerForm}
						operationManager={operationManager}
						setOperationManager={setOperationManager}
						technicalsManagerList={technicalsManagerList}
						setTechnicalsManagerList={setTechnicalsManagerList}
						isReadOnly={isReadOnly}
					/>
				)
			case 2:
				return (
					<StepThree
						openAddCompanyFilesCertification={openAddCompanyFilesCertification}
						operationInfos={operationInfos}
						setCertificationList={setCertificationList}
						certificationList={certificationList}
						isReadOnly={isReadOnly}
						isReviewMode={isReviewMode}
					/>
				)
			case 3:
				if (
					!(
						checkListDocuments &&
						checkListDocuments.checkListItemsCompanyRecord &&
						checkListDocuments.checkListItemsCompanyRecord.length
					)
				)
					return getStepFourth()
				else
					return (
						<StepChecklist
							setOnClickOpenCheckListForm={setOnClickOpenCheckListForm}
							isReadOnly={isReadOnly}
							checkListDocuments={checkListDocuments}
							setCheckListItemOnFocus={setCheckListItemOnFocus}
						/>
					)
			case 4:
				return getStepFourth()
			default:
				return 'error'
		}
	}

	const getStepFourth = () => {
		return (
			<StepFour
				openAddCompanyFilesDocuments={openAddCompanyFilesDocuments}
				operationInfos={operationInfos}
				isReadOnly={isReadOnly}
				setDocumentsList={setDocumentsList}
				documentsList={documentsList}
				companyId={companyId}
				platformId
			/>
		)
	}

	const handleNext = () => {
		if (activeStep >= steps.length - 1) return
		const newActiveStep = activeStep + 1

		setActiveStep(newActiveStep)
	}

	const handleBack = () => {
		if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleStep = (step) => () => {
		setActiveStep(step)
	}

	useEffect(() => {
		setInitialValues({
			principale: operationInfos.isPrincipalCompany,
			sousTraitante: operationInfos.isSubcontractingCompany,
			intervention: operationInfos.operationDescriptionText,
		})
	}, [operationInfos])

	const onSubmit = (values) => {
		if (!technicalsManagerList.length)
			return enqueueSnackbar(t('companyRecord:company_record_submit_must_declare_technicals'), {
				variant: 'error',
			})
		let technicalManagers = technicalsManagerList.map((contact) => {
			return contact.id
		})

		let formData = {
			operationDescription: values.intervention,
			operationDescriptionPrincipalUser: principal,
			technicalManagers: technicalManagers,
			operationExecutionManager: operationManager ? operationManager.id : {},
			otherDocuments: documentsList.map((doc) => doc.docId),
			certifications: certificationList.map((certif) => certif.cftId),
			checkListCompanyRecord: checkListDocuments,
		}
		if (!companyRecordId) {
			formData = {
				pdPId: pdpId,
				pdPRevisionNumber: pdpRevisionId,
				...formData,
			}
		} else {
			formData = {
				companyRecordId: companyRecordId,
				...formData,
			}
		}
		upsertCompanyRecord(cookies[PLATFORM_ID_COOKIE], companyId, formData, function (res, error) {
			if (res) {
				enqueueSnackbar(t('user:DataHasBeenUpdate'), { variant: 'info' })
				setNewCompanyRecordId(res.data.companyRecordId)

				setRevisionCompanyRecordId(res.data.companyRecordId)

				getOperationInfos()
			}
			if (error) {
				HandlingErrorMessages(error.data, (code) => {
					enqueueSnackbar(t('companyRecord:' + code), { variant: 'error' })
				})
			}
		})
	}

	return (
		<>
			{redirect && !restrictedPdPContext && (
				<Redirect
					from="/"
					to={
						'/companyRecord/' +
						cookies[PLATFORM_ID_COOKIE] +
						'/' +
						companyId +
						'/' +
						newCompanyRecordId
					}
				/>
			)}
			{cancelDraft && !restrictedPdPContext && <Redirect to={'/followPdp'} />}
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				{AddCompanyFilesContact && AddCompanyFilesContact.formular}
				<Typography variant="h3" classes={{ root: classes.title }}>
					{t('user:OperationDescription')}
				</Typography>
				<hr className={classes.hrRoot} />
				<div className={classes.root}>
					<Paper>
						<div className={classes.content}>{getStepContent(activeStep)}</div>
						<div className={classes.buttonGroup}>
							<KeyboardArrowLeft
								disabled={activeStep === 0 || !companyRecordId}
								color="secondary"
								className={classes.arrows}
								onClick={handleBack}
							></KeyboardArrowLeft>
							<div className={classes.stepper}>
								<Stepper alternativeLabel nonLinear activeStep={activeStep}>
									{steps.map((label, index) => {
										const stepProps = {}
										const buttonProps = {}
										return (
											<Step key={label} {...stepProps}>
												<StepButton
													onClick={handleStep(index)}
													classes={{ root: classes.stepButton, horizontal: classes.stepButton }}
													{...buttonProps}
												>
													<StepLabel
														StepIconProps={{
															classes: {
																root: classes.stepIcon,
																text: classes.stepText,
																active: classes.stepIcon,
															},
														}}
													>
														{label}
													</StepLabel>
												</StepButton>
											</Step>
										)
									})}
								</Stepper>
							</div>
							<KeyboardArrowRight
								disabled={activeStep === steps.length || !companyRecordId}
								color="secondary"
								className={classes.arrows}
								onClick={handleNext}
							></KeyboardArrowRight>
						</div>
					</Paper>
				</div>
				<div className={btnsStyle.root}>
					{(!isReadOnly || isAdminOrManager) && (
						<Button type="submit" className={classes.completed}>
							{t('user:completeStep')}
						</Button>
					)}
				</div>
			</Form>
		</>
	)
}

export default DescriptionOfOperationsForm
