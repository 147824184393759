import React from 'react'

function CGCookiesContentEn() {
	return (
		<div>
			<p>
				Welcome to the website{' '}
				<a href="https://rapso-d.totalenergies.com">https://rapso-d.totalenergies.com</a> (the
				“website”). By connecting to or browsing the website, you agree that you have read,
				understood and accepted, without limitation or reservation, the personal data and cookies
				management policy (the “policy”) and our terms and conditions of use. Please note that other
				terms and conditions and personal data protection policies apply to other Total Group
				websites, and we recommend that you read them carefully.
			</p>
			<p>
				The policy aims to inform you of the rights and freedoms that you can exercise in respect of
				our use of your personal data and describes the measures that we take to protect these data.
			</p>
			<p>
				Total is the “data controller” with responsibility for processing the personal data used to
				manage the website. These processing operations are carried out in accordance with the
				applicable law.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>
				1. Purpose of processing, legal basis, the period of storage and types of data collected
			</h2>
			<p>
				When you visit the website, you may need to provide us with a number of items of personal
				data, such as your first name and surname, in order to use the services available.
				<br />
				<br />
				The information is needed to enable us to fulfil our legal obligations in the setting up of
				Prevention Plans for the work that you would be required to perform on our production sites
				(articles L4121-5, L4122-1, R4511-1 to R4511- 12 and R4512-1 to R4512-16 of the French labor
				code). Without it, Total RC cannot process your request and Total RC will be in breach of
				the law.
				<br />
				<br />
				In our online forms, the mandatory fields are marked with an asterisk. If you do not provide
				answers to the mandatory questions, we will not be able to provide you with the service(s)
				requested.
				<br />
				<br />
				Your personal data will not be processed subsequently in a way that is incompatible with the
				purposes described above or below the collection forms. Your data are kept 7 years after the
				end of the work linked to the related Prevention Plans.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>2. Recipients of data</h2>
			<p>
				Your personal data may be communicated to one or more of the data controller's departments
				or to Total Group companies for the purposes of analysis and surveys.
				<br />
				<br />
				If you leave a comment intended to be posted online, we may be required to publish some of
				your personal data on the website. Given the characteristics of the internet, namely the
				unrestricted capture of published data and the fact that it is difficult, or even
				impossible, to control how third parties can use these data, we hereby inform you that you
				can object to this publication by contacting us as explained in article 5 below.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>3. Data Transfers</h2>
			<p>
				Any transfer of data to a country outside the European Economic Area shall be carried out in
				accordance with the applicable regulations and in such a way as to protect your data
				appropriately.
				<br />
				<br />
				For this reason, the Total Group has adopted “Binding Corporate Rules” (BCR) governing
				intra-Group transfers of personal data originating in the European Economic Area. <br />
				<br />
				You can request a copy by writing to this address
			</p>
			<h2 style={{ fontWeight: 'bold' }}>4. Data security and confidentiality </h2>
			<p>
				The data controller takes appropriate steps to preserve the security and confidentiality of
				your personal data, including to prevent them from being distorted, damaged or disclosed to
				unauthorized third parties.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>5. Cookie management </h2>
			<h3 style={{ fontWeight: 'bold' }}>5.1 Principle</h3>
			<p>
				A cookie is a file which enables a website to save information relating to your computer’s
				browsing of the website (e.g. number of visits, number of pages viewed, etc.), to make your
				visits to the website smoother.
				<br />
				<br />
				You can at any time delete the cookies stored on your computer, object to the storage of new
				cookies and receive a notification before new cookies are stored by changing your browser
				settings using the instructions below (“Types of cookies, cookies and statistics, and
				settings”).
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>
					Please note that if you remove a cookie or object to the storage of cookies on your
					device, you may not be able to use some of the website's services.
				</span>
			</p>
			<h3 style={{ fontWeight: 'bold' }}>
				5.2 Types of cookies, cookies and statistics, and settings.
			</h3>
			<p>
				The cookies that may be stored on your server when you browse the website are cookies which
				are intended solely to enable or facilitate electronic communication or which are strictly
				necessary to provide the service you are requesting (language cookies, login cookies, etc.),
				or statistics cookies or other cookies in accordance with the conditions below.
				<br />
				<br />
				When cookies require{' '}
				<span style={{ fontWeight: 'bold' }}>your agreement before they can be saved</span>, we ask
				you for this agreement via the “find out more” link displayed on the first page of the
				website that you land on,{' '}
				<span style={{ fontWeight: 'bold' }}>
					in which it is made clear that by continuing to browse the website you accept these
					cookies.
				</span>
			</p>
			<h4 style={{ fontWeight: 'bold' }}>5.2.1 Quels sont les cookies installés ?</h4>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Cookies issued by the data controller
			</h5>
			<table>
				<tr>
					<th>Cookie</th>
					<th>Description</th>
					<th>How do you object? </th>
					<th>Period</th>
				</tr>
				<tr>
					<td>AccountId</td>
					<td>Identifiant du compte</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>AccountTypes</td>
					<td>Type(s) du compte</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>AuthToken</td>
					<td>Token d’authentification</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>CompanyID</td>
					<td>Identifiant de l’entreprise</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>FirstName / LastName</td>
					<td>Prénom / Nom du compte authentifié</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>PlateformId</td>
					<td>Identifiant de la plateforme</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
				<tr>
					<td>Plateforms</td>
					<td>Liste des plateformes</td>
					<td>Voir l’article 5.2.2</td>
					<td>Expire à la déconnexion ou après 24h.</td>
				</tr>
			</table>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Cookies issued by third parties
			</h5>
			<p style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>Not applicable</p>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Statistics cookies
			</h5>
			<p style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>Not applicable</p>
			<h4 style={{ fontWeight: 'bold' }}>
				5.2.2 How do you delete cookies, receive notification of their storage or change your
				browser settings?
			</h4>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• How do you delete cookies already stored on your computer?
			</h5>
			<ul>
				<li>On your workstation:</li>
				<li>On the C:\ drive select the Windows folder;</li>
				<li>Open the “Temporary Internet Files” folder;</li>
				<li>Select all files (CTRL A);</li>
				<li>Choose the “delete” option.</li>
			</ul>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• How do you change browser settings to reject or be informed of the storage of cookies?
			</h5>
			<ul>
				<li>
					In the Internet Explorer 5 (Microsoft) browser: Select “Tools”, and then “Internet
					Options”. Click on the “Security” tab, then “Custom level” and scroll down to the
					“cookies” section. Next to “Allow cookies that are stored on your computer” select “Ask”
					to be notified or “Disable” to decline all cookies;
				</li>
				<li>
					In the Internet Explorer 6, 7 or 8 (Microsoft) browser: Select “Tools”, “Internet
					Options”, “Privacy”, then the level you wish to apply;
				</li>
				<li>
					In the Firefox browser: Click on “Tools” and select “Options”. In the “Privacy and
					security” tab, untick the box “Accept cookies from websites”;
				</li>
				<li>
					In the Chrome (Google) browser: Click “Customize and control Google Chrome” and select
					“Settings”. Under “Privacy and security”, click on “Content settings” and enable “Block
					third-party cookies”.
				</li>
			</ul>
			<h3 style={{ fontWeight: 'bold' }}>6. Vos droits /Contact</h3>
			<p>
				In accordance with current regulations, you have the right to access, correct, delete and
				object to the use of your personal data. You can ask for your personal data to be sent to
				you and you have the right to give instructions for the use of your personal data after your
				death. You can also ask for restriction of the data, and/or make a claim to the CNIL (the
				French data protection agency). <br />
				<br />
				Please send your request by email to
				<a href="mailto:rc.data-protection@totalenergies.com">
					rc.data-protection@totalenergies.com
				</a>{' '}
				or by post to the following address:
				<br />
				<br />
				Total Raffinage Chimie
				<br />
				Direction Industrielle
				<br />
				Tour Coupole 2, place Jean Millier
				<br />
				92078 Paris La Défense Cedex France
				<br />
			</p>
		</div>
	)
}

export default CGCookiesContentEn
