import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import {
	TextField,
	FormLabel,
	FormGroup,
	Select,
	MenuItem,
	FormControl,
	ListItemText,
	Checkbox,
	InputLabel,
} from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { bindParams } from '../../utils/Link'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TRUEFALSE from '../../constants/TrueFalse'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	defaultStyle: {
		margin: theme.spacing.unit * 2,
	},
	loaderWrapper: {
		marginTop: 50,
		display: 'flex',
		justifyContent: 'center',
	},
	loader: {
		width: 70,
		height: 70,
	},
}))

function getIndexByName(array, arrayKeyName) {
	for (let index = 0; index < array.length; index++) {
		if (array[index]['name'] === arrayKeyName) return index
	}
	return -1
}

const DataTable = ({
	title,
	columns,
	data,
	options,
	isLoading,
	customColumns = null,
	customLabels = null,
	defaultFilters = null,
	className = null,
}) => {
	const mTitle = title || 'No title'
	const [mColumns, setColumns] = useState([])
	const [mData, setData] = useState([])
	const [mOptions, setOptions] = useState({})
	const classes = useStyles()
	const { t } = useTranslation(['datatable'])

	const GENERAL_OPTIONS = {
		textLabels: {
			body: {
				noMatch: t('noMatch'),
			},
		},
		rowsPerPage: 100,
	}

	const FILTER_TYPES = [
		{
			name: 'date',
			logic: (name, filters) => {
				if (!filters[0] || !filters[1]) return false
				if (!name) return true
				let tmpItDate = moment(name, 'DD/MM/YYYY')
				let currentFilterDate = moment(filters[1], 'YYYY-MM-DD')

				switch (filters[0]) {
					case 1:
						return !tmpItDate.isBefore(currentFilterDate)
					case 2:
						return !tmpItDate.isSame(currentFilterDate)
					case 3:
						return !tmpItDate.isAfter(currentFilterDate)
					default:
						break
				}

				return false
			},
			display: function displayDateFilterComponent(filterList, onChange, index, column) {
				return (
					<div>
						<p>{column.label}</p>
						<Select
							value={filterList[index][0] || 1}
							onChange={(e) => {
								filterList[index][0] = e.target.value
								onChange(filterList[index], index, column)
							}}
						>
							<MenuItem value={1}>{'inf'}</MenuItem>
							<MenuItem value={2}>{'eg'}</MenuItem>
							<MenuItem value={3}>{'sup'}</MenuItem>
						</Select>{' '}
						<TextField
							value={filterList[index][1] || moment().format('YYYY-MM-DD')}
							onChange={(e) => {
								filterList[index][1] = e.target.value
								onChange(filterList[index], index, column)
							}}
							type="date"
						/>
					</div>
				)
			},
		},
		{
			name: 'dropdown',
			logic: (value, filters) => {
				if (!filters[0]) return false
				let flag = false
				filters.map((v) => {
					if (value.includes(v)) flag = true
				})
				return !flag
			},
			display: function displayDropdownFilterComponent(filterList, onChange, index, column) {
				return (
					<FormControl>
						<InputLabel htmlFor="select-multiple-chip">{columns[index].label}</InputLabel>
						<Select
							multiple
							value={filterList[index]}
							renderValue={(selected) => selected.join(', ')}
							onChange={(event) => {
								filterList[index] = event.target.value
								onChange(filterList[index], index, column)
							}}
						>
							{columns[index].options.filterOptions.names.map((item) => (
								<MenuItem key={item} value={item}>
									<ListItemText primary={item} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)
			},
		},
		{
			name: 'checkbox',
			logic: (value, filters) => {
				/**
				 * USE IN DTDE.js for preliminaryvisit Field
				 */
				if (!filters[0]) return false

				let flag = false
				filters.map((v) => {
					if (`${value}`.includes(v.const.bool) && v.state) flag = true
				})
				return !flag
			},
			display: function displayCheckboxFilterComponent(filterList, onChange, index, column) {
				return (
					<FormControl component="fieldset">
						<FormLabel component="legend">{columns[index].label}</FormLabel>
						<FormGroup>
							{columns[index].options.filterOptions.names.map((item) => (
								<FormControlLabel
									control={
										<Checkbox
											onChange={(event) => {
												let finditem = filterList[index].find((i) => i.item === item)
												if (!finditem) {
													let booleanfield = TRUEFALSE.find((x) => x.fr === item || x.en === item)
													filterList[index].push({
														item: item,
														state: event.target.checked,
														const: booleanfield,
													})
												} else if (event.target.checked) finditem.state = event.target.checked
												else {
													filterList[index] = filterList[index].filter((x) => x !== finditem)
												}
												onChange(filterList[index], index, column)
											}}
											name={item}
										/>
									}
									label={item}
									key={item}
								/>
							))}
						</FormGroup>
					</FormControl>
				)
			},
		},
	]

	useEffect(() => {
		if (!columns || !data || !options) return console.error('Required property is missing !')
		if (customColumns)
			columns.map((col) => {
				for (let customColumn of customColumns) {
					if (col.name === customColumn.name)
						col.options.customBodyRender =
							customColumn.component /*PROPS : value, tableMeta, updateValue*/
				}
				return col
			})

		if (customLabels) {
			columns.map((col) => {
				for (let cLabel of customLabels) {
					if (col.name !== cLabel.name) continue
					col.label = cLabel.label
				}
				return col
			})
		}

		if (defaultFilters) {
			columns.map((col) => {
				for (let Dfilter of defaultFilters) {
					if (col.name !== Dfilter.name) continue
					col.options = { ...col.options, filterList: Dfilter.value }
				}
			})
		}

		if (options.filter)
			for (const fType of FILTER_TYPES) {
				if (fType.name === options.filterType)
					options = {
						...options,
						filterType: 'custom',
						filterOptions: {
							names: [],
							logic: fType.logic,
							display: fType.display,
						},
					}
			}

		let tmpColumns = columns.map((col) => {
			for (let filterType of FILTER_TYPES) {
				if (col.options && col.options.filterType === filterType.name)
					return {
						...col,
						options: {
							...col.options,
							filterType: 'custom',
							customFilterListOptions: {
								render: (v) => {
									if (filterType.name === 'date') {
										if (!Array.isArray(v) || (v[0] === undefined && v[1] === undefined))
											return false
										if (!v[0] || !v[1]) return t('datatable:dateDefault')
										switch (v[0]) {
											case 1:
												return bindParams(t('datatable:dateInf'), v[1])
											case 2:
												return bindParams(t('datatable:dateEqu'), v[1])
											case 3:
												return bindParams(t('datatable:dateGr'), v[1])
											default:
												return false
										}
									} else if (filterType.name === 'dropdown') {
										return v
									} else if (filterType.name === 'checkbox') {
										if (v.state) return v.item
										else return false
									}
								},
							},
							filterOptions: {
								names: [],
								logic: filterType.logic,
								display: filterType.display,
							},
						},
					}
			}
			return col
		})
		if (!data.length) {
			options = { ...options, selectableRows: 'none' }
			tmpColumns = tmpColumns.filter((col) => col.name !== 'actions')
		}

		options = {
			...options,
			onDownload: (buildHead, buildBody, p_Columns, p_Data) => {
				// Correct encoding MUI : https://github.com/gregnb/mui-datatables/pull/722
				return '\uFEFF' + buildHead(p_Columns) + buildBody(p_Data)
			},
		}

		setColumns(tmpColumns)
		setData(data)

		setOptions(options)
	}, [data])

	return (
		<>
			{isLoading ? (
				<div className={classes.loaderWrapper}>
					<CircularProgress className={classes.loader} color="secondary" />
				</div>
			) : (
				<MUIDataTable
					title={mTitle}
					columns={mColumns}
					data={mData}
					options={{ ...GENERAL_OPTIONS, ...mOptions }}
					className={classNames([className, classes.defaultStyle])}
				/>
			)}
		</>
	)
}
DataTable.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.array,
	data: PropTypes.array,
	options: PropTypes.object,
	isLoading: PropTypes.bool,
	customColumns: PropTypes.array,
	customLabels: PropTypes.array,
	defaultFilters: PropTypes.array,
	className: PropTypes.string,
}

export { getIndexByName }
export default DataTable
