import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { getContactsByAccountId, getDangerPhrases } from '../../../containers/DataContainer'
import { getFileToByteArray } from '../../../utils/File'
import { composeValidators } from '../../../utils/Form'
import {
	CASorCENumber,
	emailFormat,
	emailSize,
	required,
	noSpecialCharURLFriendly,
} from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormIcon from '../../Form/FormIcon'
import FormStep from '../../Form/FormStep'
import { InputSwitch, InputCheckbox } from '../../Form/input/InputCheckbox'
import InputFile from '../../Form/input/InputFile'
import InputText from '../../Form/input/InputText'
import InputTextArea from '../../Form/input/InputTextArea'
import InputPhone from '../../Form/inputFullPage/InputPhone'
import FPInputText from '../../Form/inputFullPage/InputText'
import SlidingForm from '../../Form/SlidingForm'
import LinearVerticalStepper from '../../VerticalStepper/LinearVerticalStepper'
import LANG from '../../../constants/lang'
import { bindParams } from '../../../utils/Link'
import humanFileSize from '../../../constants/coreConstants'
import {
	PictoRightSide,
	ChemicalProductFormStyle,
	formatRequest,
	FormStepRiskIdentification,
	ContactCard,
	createCompanyContact,
} from '../ChemicalProductsFormContainer'
import PropTypes from 'prop-types'
import InputNumber from '../../Form/input/InputNumber'
import InputDate from '../../Form/input/InputDate'
import { GetPlatform } from '../../../containers/RegisterContainer'

const useStyles = ChemicalProductFormStyle

function DEChemicalProductsForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const localLang = localStorage.getItem('i18nextLng')
	const [initialValues, setInitialValues] = useState({})
	const [dangerPhrases, setDangerPhrases] = useState([])
	const [selectedDangerPhrases, setSelectedDangerPhrases] = useState([])
	const [contacts, setContacts] = useState([])
	const [selectedContact, setSelectedContact] = useState({ id: 0 })
	const [openDialog, setOpenDialog] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [nameFile, setNameFile] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const { t } = useTranslation(['user', 'translation', 'chemical'])
	const [isCasNumberIgnored, setIsCasNumberIgnored] = useState(false)
	const translationFormStepRiskIdentification = {
		dangerousSentences: t('chemical:dangerousSentences'),
		NoDangerPhrases: t('NoDangerPhrases'),
	}
	const [platformFDSValidityDuration, setPlatformFDSValidityDuration] = useState()

	const getLangId = () => LANG[localLang].id

	const fillPlatformFDSValidityDuration = (platforms) => {
		const duration = platforms
			.find((platform) => platform.platformId === parseInt(cookies[PLATFORM_ID_COOKIE]))
			?.platformConfigurations.find((conf) => conf.translationCode === 'FdsValidityDuration')?.value
		setPlatformFDSValidityDuration(duration)
	}

	useEffect(() => {
		if (file) {
			setNameFile(file.name)
		}
	}, [file])

	useEffect(() => {
		if (!open) {
			setNameFile('')
			setSelectedDangerPhrases([])
			return
		}

		if (data.initialValues) {
			let initialVals = data.initialValues
			setInitialValues(initialVals)
			setFile(initialVals.file)
			setSelectedDangerPhrases(initialVals.selectedDangerPhrases)
		}
		getDangerPhrases(getLangId(), (res, err) => {
			if (err) return console.error(err)
			if (res) {
				setDangerPhrases(res.data)
			}
		})
		GetPlatform(null, (res, err) => {
			if (err) console.error(err)
			if (res) {
				fillPlatformFDSValidityDuration(res.data)
			}
		})
	}, [open])

	useEffect(() => {
		if (!open) return
		getContactsByAccountId(data.companyId, function (res, error) {
			if (error) return
			if (res) {
				setContacts(res.data)
			}
		})
	}, [open, refresh])

	/**
	 * @name createContact
	 * @description Create a contact
	 * @param {Object} dt
	 */
	const createContact = (dt) => {
		createCompanyContact({ ...dt, platformId: cookies[PLATFORM_ID_COOKIE] }, data, (result) => {
			if (result.isError) {
				enqueueSnackbar(`t(result.message) : ${result.errorStatus} !`, { variant: 'error' })
			} else {
				enqueueSnackbar(t(result.message), { variant: result.variant })
			}
			setRefresh(!refresh)
			setOpenDialog(false)
		})
	}
	const onSubmit = (value) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				let values = { ...value, file, formFile: res, selectedDangerPhrases }
				data.openChemicalProductsStorageForm({
					values: formatRequest(
						values,
						data,
						selectedDangerPhrases,
						selectedContact,
						isCasNumberIgnored,
					),
					refreshStore: data.refreshStore,
					create: true,
					openChemicalProductsForm: data.openChemicalProductsForm,
					initialValues: values,
				})
				close()
			} else {
				enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
			}
		})
	}

	return (
		<>
			<SlidingForm
				direction="fullscreen"
				open={open}
				title={t('chemical:ChemicalProduct')}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={() => {
								close()
								setInitialValues({})
							}}
						/>
					</>
				}
				submitLabel="Valider"
			>
				<LinearVerticalStepper
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classes.previousBtn,
						next: classes.nextAndCompletedBtn,
						completed: classes.nextAndCompletedBtn,
						finished: classes.nextAndCompletedBtn,
					}}
					contentStyle={{}}
					initialValues={initialValues}
					result={onSubmit}
				>
					<FormStep
						validate={() => {
							if (noSpecialCharURLFriendly(nameFile)) {
								enqueueSnackbar(t('validateData:noSpecialChar'), { variant: 'error' })
								return false
							}

							if (file) return true
							enqueueSnackbar(t('fds_file_upload_required'), { variant: 'error' })
						}}
						label={t('chemical:SubstancesIdentification')}
						vertical
					>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="name"
							label={t('chemical:nameFDS')}
							validate={required}
						/>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="synonym"
							label={t('synonym')}
							validate={required}
						/>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="provider_name"
							label={t('chemical:providerName')}
							validate={required}
						/>
						<InputTextArea
							className={classNames([classes.input, classes.fullWidth])}
							name="use"
							label={t('chemical:usage')}
							validate={required}
						/>
						<Typography className={classes.text} variant="subtitlefdswaring">
							{bindParams(t('chemical:FDSWarning'), platformFDSValidityDuration)}
						</Typography>
						<InputNumber
							name="validityDuration"
							label={t('chemical:validity_duration')}
							className={classNames([classes.input, classes.basicWidth])}
							validate={required}
							inputProperties={{ min: 1, max: 100 }}
						/>
						<InputDate
							name="validityEndDate"
							label={t('chemical:validity_end_date')}
							inputStyle={classNames([classes.input, classes.basicWidth])}
							validate={required}
						/>
						<InputFile
							className={classNames([classes.input])}
							name="fds"
							label={t('chemical:joinFDS')}
							nameFileUpdated={nameFile}
							setFile={setFile}
						/>
					</FormStep>
					<FormStep label={t('chemical:composition')} vertical>
						<InputCheckbox
							label={t('chemical:cas_ce_obligation')}
							name="cas_ce_obligation"
							onClick={() => {
								setIsCasNumberIgnored(!isCasNumberIgnored)
							}}
							checked={isCasNumberIgnored}
						/>
						{!isCasNumberIgnored && (
							<InputText
								className={classNames([classes.input])}
								name="cas_ce"
								label={t('chemical:cas_ce')}
								validate={composeValidators(required, CASorCENumber)}
							/>
						)}
						<InputSwitch name="cmr" label={t('chemical:cmr')} />
						<InputSwitch name="se" label={t('chemical:se')} />
					</FormStep>
					<FormStep
						label={t('chemical:RiskIdentification')}
						vertical
						validate={() => {
							if (!selectedDangerPhrases || selectedDangerPhrases.length < 1) {
								setErrorMessage('Au moins une phrase de danger est requise !')
								return false
							}
							setErrorMessage('')
							return true
						}}
					>
						<div className={classes.leftSide}>
							<FormStepRiskIdentification
								translation={translationFormStepRiskIdentification}
								selectedDangerPhrases={selectedDangerPhrases}
								setSelectedDangerPhrases={setSelectedDangerPhrases}
								dangerPhrases={dangerPhrases}
							/>
						</div>
						<PictoRightSide selectedDangerPhrases={selectedDangerPhrases} />
						<Typography variant="caption" style={{ color: 'red' }}>
							{errorMessage}
						</Typography>
					</FormStep>
					<FormStep
						label={t('chemical:contact')}
						validate={() => {
							if (selectedContact.id === 0) {
								enqueueSnackbar(t('chemical:requiredContact'), { variant: 'error' })
								return false
							}
							return true
						}}
						vertical
					>
						<Typography className={classes.text} variant="subtitle1">
							{t('chemical:selectOrCreateContact')}
						</Typography>
						<div className={classes.scroll}>
							{contacts.map((contact) => (
								<ContactCard
									key={contact.id}
									id={contact.id}
									lastName={contact.name ? contact.name : 'XXXX'}
									firstName={contact.firstName ? contact.firstName : 'XXXX'}
									phone={contact.phone ? contact.phone : t('NoNumberAvailable')}
									mail={contact.email ? contact.email : t('NoEmailAddressAvailable')}
									selectedContact={selectedContact}
									setSelectedContact={setSelectedContact}
								/>
							))}
						</div>
						<br />
						<Fab
							size="medium"
							color="primary"
							aria-label="add"
							className={classes.margin}
							onClick={() => setOpenDialog(true)}
						>
							<AddIcon color="secondary" />
						</Fab>
					</FormStep>
				</LinearVerticalStepper>
			</SlidingForm>
			<Dialog
				onClose={() => setOpenDialog(false)}
				aria-labelledby="customized-dialog-title"
				open={openDialog}
			>
				<Form onSubmit={createContact}>
					<DialogTitle id="customized-dialog-title" onClose={() => setOpenDialog(false)}>
						<Typography color="secondary">{t('CreationChemicalProductManager')}</Typography>
					</DialogTitle>
					<DialogContent>
						<FPInputText name="contactName" label={t('lastname')} validate={required} />
						<br />
						<FPInputText name="contactFirstName" label={t('firstname')} validate={required} />
						<br />
						<FPInputText
							name="contactEmail"
							label={t('email')}
							validate={composeValidators(required, emailSize, emailFormat)}
						/>
						<br />
						<InputPhone name="contactPhone" label={t('phone')} validate={required} />
						<br />
						<InputSwitch name="isContactNotifiable" label={t('notifie')} contrast />
						<br />
					</DialogContent>
					<DialogActions>
						<Button type="submit" color="secondary">
							{t('Create')}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</>
	)
}
DEChemicalProductsForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default DEChemicalProductsForm
