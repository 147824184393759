import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		height: 175,
		border: 'none',
		transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		color: 'black !important',
		'&:hover': {
			color: theme.palette.secondary.main + ' !important',
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		},
		'&:hover $title': {
			color: theme.palette.secondary.main + ' !important',
			transition: 'color 0.3s',
		},
	},
	title: {
		margin: theme.spacing.unit,
		fontSize: '1.2em',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			margin: 5,
		},
	},
	icon: {
		position: 'absolute',
		display: 'block',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: 60,
	},
	link: {
		display: 'contents',
		textDecoration: 'none',
		'&:visited': {
			color: 'black',
		},
	},
}))

function OICard({ title, icon: Icon, link }) {
	const classes = useStyles()

	return (
		<Grid md={4} xs={12} item>
			<Link className={classes.link} to={link}>
				<Card className={classes.card}>
					<CardContent>
						<Typography className={classes.title} variant="h6">
							{title}
						</Typography>
						{Icon && <Icon className={classes.icon} />}
					</CardContent>
				</Card>
			</Link>
		</Grid>
	)
}
OICard.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.func,
	link: PropTypes.string,
}

export default OICard
