import React, { useState, useEffect } from 'react'
import FormIcon from '../../components/Form/FormIcon'
import InputText from '../../components/Form/input/InputText'
import InputMail from '../../components/Form/input/InputMail'
import InputCheckbox, { InputSwitch } from '../../components/Form/input/InputCheckbox'
import InputPhone from '../../components/Form/input/InputPhone'
import SlidingForm from '../../components/Form/SlidingForm'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormButtonIconDelete from '../Form/FormButtonIconDelete'
import Form from '../Form/Form'
import { composeValidators } from '../../utils/Form'
import { required, maxLong, loginSize, noSpace, noSpecialChar } from '../../utils/VerifyFields'
import {
	upsertCompanyContacts,
	deleteCompanyContacts,
	getContactTypeList,
} from '../../containers/DataContainer'
import i18next from 'i18next'
import { useSnackbar } from 'notistack'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@material-ui/core'
import { HandlingErrorMessages } from '../../utils/Errors'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function AddContactForm({ open, close, data, changeState }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [listType, setListType] = useState([])
	const [numberTypes, setNumberTypes] = useState(0)
	const defaultValues = { notifie: false, createAccess: false }
	const [contactValues, setContactValues] = useState({})
	const [language, setLanguage] = useState(1)
	const { enqueueSnackbar } = useSnackbar()
	const [openPopUp, setOpenPopUp] = useState(false)
	const [load, setLoad] = useState(false)
	const [access, setAccess] = useState(false)

	const handleClickOpen = () => {
		setOpenPopUp(true)
	}
	const handleClose = () => {
		setOpenPopUp(false)
	}

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	}, [])

	useEffect(() => {
		if (data !== undefined) {
			let typesValues = {}
			if (data.typesIds !== undefined) {
				for (const typeId of data.typesIds) {
					typesValues = { ...typesValues, ['type' + [typeId]]: true }
				}
			}

			setContactValues({
				lastname: data.name,
				firstname: data.firstName,
				mail: data.email,
				phone: data.phone,
				notifie: data.isNotifiable,
				createAccess: data.hasAccess,
				...typesValues,
			})
		}
		changeState()
		setAccess(false)
	}, [open])

	const onSubmit = (values) => {
		let id = {}
		let access = {}

		if (data !== undefined) {
			id = { contactId: data.id }
		}

		access = { createApplicationAccess: values.createAccess }

		let listTypeRoles = []
		for (let i = 1; i <= numberTypes; i++) {
			if (values['type' + i]) listTypeRoles = [...listTypeRoles, i]
		}

		const upsertData = {
			contactUsername: values.login,
			contactName: values.lastname,
			contactFirstName: values.firstname,
			contactEmail: values.mail,
			contactPhone: values.phone,
			roles: listTypeRoles,
			isContactNotifiable: values.notifie,
			...id,
			...access,
		}
		setLoad(true)
		upsertCompanyContacts(upsertData, data.companyID, function (res, error) {
			setLoad(false)
			if (error) {
				let errors = error.data
				errors.forEach((err) => {
					enqueueSnackbar(t(`user:${err.code}`), { variant: 'error' })
				})
				close()
				return
			}

			if (res) {
				if (data.id) {
					enqueueSnackbar(t('TheContactHasBeenUpdated'), { variant: 'info' })
				} else {
					enqueueSnackbar(t('TheContactHasBeenAdded'), { variant: 'info' })
				}
				close()
			}
		})
		changeState()
	}

	const DeleteContact = () => {
		if (data.hasAccess) {
			handleClickOpen()
			return
		}
		Delete()
	}

	const Delete = () => {
		setLoad(true)
		deleteCompanyContacts(data.companyID, data.id, function (res, error) {
			setLoad(false)
			if (res) {
				enqueueSnackbar(t('TheContactHasBeenDeleted'), { variant: 'info' })
			}
			if (error) {
				HandlingErrorMessages(error.data, (code) => {
					if (code === 'delete_contact_no_more_admin') {
						enqueueSnackbar(t('DeleteLastAccount'), { variant: 'error' })
					} else {
						enqueueSnackbar(t('contacts:' + code), { variant: 'error' })
					}
				})
				close()
			}
		})
		changeState()
		close()
	}

	const PopUp = () => {
		return (
			<Dialog
				open={openPopUp}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Êtes-vous sûr de supprimer le contact ?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('user:SureToDeleteContactWithAccount')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Annuler
					</Button>
					<Button
						onClick={() => {
							Delete()
							handleClose()
						}}
						color="secondary"
						autoFocus
					>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	useEffect(() => {
		if (open && !listType.length)
			getContactTypeList(language, function (res, error) {
				if (error) {
					return
				}
				if (res) {
					setListType(res.data)
					// exclude one role, add + 1 to get every index role
					setNumberTypes(res.data.length + 1)
				}
			})
	}, [open])

	const validate = (values) => {
		const errors = {}
		let flag = false
		for (let i = 1; i <= numberTypes; i++) {
			if (values['type' + i]) flag = true
		}
		if (!flag) {
			errors.type1 = t('CheckAtLeastOneType')
		}
		return errors
	}

	return (
		<>
			<PopUp></PopUp>
			<SlidingForm
				direction="vertical"
				open={open}
				title={t('contacts:AddContact')}
				iconAttachement={
					<>
						<FormIcon icon="clear" onClick={close} />
					</>
				}
				submitLabel="Valider"
				onClose={close}
			>
				<Form
					onSubmit={onSubmit}
					initialValues={{ ...defaultValues, ...contactValues }}
					validate={validate}
				>
					<InputText
						classes={{ root: classes.size1 }}
						validate={composeValidators(required, maxLong)}
						name="lastname"
						label={t('lastname')}
					/>
					<InputText
						classes={{ root: classes.size1 }}
						validate={composeValidators(required, maxLong)}
						name="firstname"
						label={t('firstname')}
					/>
					<InputMail
						classes={{ root: classes.size1 }}
						validate={composeValidators(required, maxLong)}
						name="mail"
						label={t('email')}
					/>
					<InputPhone
						classes={{ root: classes.size1 }}
						validate={composeValidators(required, maxLong)}
						name="phone"
						label={t('phone')}
					/>
					<InputSwitch name="notifie" label={t('notifie')} />
					{listType &&
						listType.map((type) => {
							let y = type.id
							return <InputCheckbox name={'type' + y} label={type.name} value={type.id} key={y} />
						})}
					<InputSwitch
						name="createAccess"
						label={t('contacts:createAccess')}
						onClick={(e) => {
							setAccess(!access)
						}}
					/>

					{access && (
						<InputText
							classes={{ root: classes.size1 }}
							name="login"
							label={t('login')}
							validate={composeValidators(required, loginSize, noSpace, noSpecialChar)}
						/>
					)}
					{!load && (
						<div className={classes.validateGroup}>
							{contactValues.lastname && (
								<FormButtonIconDelete
									classes={{ root: classes.deleteButton }}
									onClick={DeleteContact}
								/>
							)}
							<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
						</div>
					)}
				</Form>
			</SlidingForm>
		</>
	)
}
AddContactForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
	changeState: PropTypes.bool,
}

export default AddContactForm
