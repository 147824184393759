import React, { useEffect } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import { useTranslation } from 'react-i18next'
import OperationnalInformationsForm from '../../components/forms/OperationnalInformations'
import { useMatch } from '../../containers/AuthContainer'
import PropTypes from 'prop-types'

function OperationnalInformations({ match = null }) {
	const { t } = useTranslation('user')
	const { setTitleBar } = useTotalTitleBarContext()
	const { companyId, formatUrl } = useMatch(match, '/op-infos')

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: t('OperationnalData') },
		})
	}, [])

	return (
		<>
			<HeadTitle title={t('info')} link={formatUrl} />
			<OperationnalInformationsForm companyID={companyId} />
		</>
	)
}
OperationnalInformations.propTypes = {
	match : PropTypes.object
}

export default OperationnalInformations
