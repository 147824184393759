import { Grid, Paper, Typography, Checkbox, Button, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTotalTitleBarContext } from '../../../contexts/TotalTitleBarContext'
import PropTypes from 'prop-types'
import Form from '../../../components/Form/Form'
import InputText from '../../../components/Form/inputFullPage/InputText'
import FormButtonFullPage from '../../../components/Form/FormButtonFullPage'
import InputCheckboxGroup from '../../../components/Form/input/InputCheckboxGroup'
import { useCookies } from 'react-cookie'
import { siretFormat, siretSize } from '../../../utils/VerifyFields'
import { useSnackbar } from 'notistack'
import { GET_SIRET_CHANGEMENT, POST_SIRET_CHANGEMENT } from '../../../constants/url'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	page: {
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 10,
		paddingRight: theme.spacing.unit * 10,
		paddingBottom: theme.spacing.unit * 4,
	},
	paper: {
		height: '100%',
		width: '100%',
		padding: theme.spacing.unit * 2,
		color: theme.palette.secondary.main,
		borderRadius: 5,
		[theme.breakpoints.down('sm')]: {
			marginTop: 25,
		},
	},
	form: {
		margin: 20,
		padding: 20,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 10,
	},
	panel: {
		padding: 20,
		rowGap: 10,
		display: 'grid',
	},
	submitButton: {
		marginTop: 20,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	listCheckbox: {
		maxWidth: 450,
		margin: 'auto',
		height: '55vh',
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	siretChangementInformations: {
		marginTop: 10,
		marginBottom: 20,
	},
	modalPaper: {
		margin: 'auto',
		marginTop: '20%',
		maxWidth: '35%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	modalValidateButton: {
		marginLeft: 'auto',
		marginRight: '-5%',
	},
	modalButtonGroup: {
		display: 'flex',
	},
	modalButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	modalTitle: {
		color: theme.palette.secondary.main,
		marginBottom: 20,
	},
	modalSecondTitle: {
		display: 'inline-block',
	},
}))

function SiretChangement() {
	const classes = useStyles()
	const { t } = useTranslation(['specialsTreatments', 'user'])
	const { setTitleBar } = useTotalTitleBarContext()
	const [listPdPs, setListPdPs] = useState([])
	const [initialValues, setInitialValues] = useState({})

	const [newCompanyName, setNewCompanyName] = useState('')
	const [newSocialReason, setNewSocialReason] = useState('')
	const [newSiretNumber, setNewSiretNumber] = useState('')
	const [newAgencyName, setNewAgencyName] = useState('')
	const [oldCompanyId, setDldCompanyId] = useState('')

	const [valueCheckbox, setValueCheckbox] = useState([])
	const [isReadOnly, setIsReadOnly] = useState(true)
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const platforms = useSelector((state) => state.platforms.platforms)
	const [modalState, setModalState] = useState(false)
	const [confirmChanges, setConfirmChanges] = useState(false)
	const platformName = (pdp) => {
		if (platforms.find((p) => p.platformId === pdp.ptfId).name)
			return platforms.find((p) => p.platformId === pdp.ptfId).name
		else return t('specialsTreatments:no_platform_found')
	}

	useEffect(() => {
		setInitialValues({
			oldSiret: '',
			oldSocialReason: '',
			oldCompanyName: '',
			oldAgencyName: '',
			newSiret: '',
			newSocialReason: '',
			newCompanyName: '',
			newAgencyName: '',
		})
	}, [])

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'person',
				title: t('specialsTreatments:siret_changement'),
			},
		})
	}, [setTitleBar, t])

	useEffect(() => {
		if (confirmChanges) {
			const data = {
				siretNumber: newSiretNumber,
				socialReason: newSocialReason,
				companyName: newCompanyName,
				agencyName: newAgencyName,
				oldCompanyId: oldCompanyId,
				preventionPlans: valueCheckbox.filter((item) => item.checked).map((item) => item.id),
			}
			api
				.post(bindParams(POST_SIRET_CHANGEMENT, parseInt(cookies[PLATFORM_ID_COOKIE])), data)
				.then((response) => {
					const { data, ok } = response
					if (!ok) {
						if (data[0] && data[0].code)
							enqueueSnackbar(t(`specialsTreatments:${data[0].code}`), { variant: 'error' })
						else
							enqueueSnackbar(t(`specialsTreatments:company_migration_error`), { variant: 'error' })
						return
					}
					enqueueSnackbar(t(`specialsTreatments:company_migration_success`), { variant: 'success' })
				})
				.catch((error) => {
					console.log(error)
				})
			setConfirmChanges(false)
		}
	}, [confirmChanges])

	const isSiretCorrect = (siret) => {
		if (siretSize(siret) == undefined && siretFormat(siret) == undefined) {
			return true
		}
	}

	const findCompanyBySiretNumber = useCallback((event) => {
		const siret = event.target.value.trim()

		if (isSiretCorrect(siret)) {
			api
				.get(bindParams(GET_SIRET_CHANGEMENT, parseInt(cookies[PLATFORM_ID_COOKIE]), siret))
				.then(handleApiResponseCompanyInformation)
				.catch((error) => {
					console.log(error)
				})
		}
	})

	const updateNewSiretNumber = useCallback((event) => {
		setNewSiretNumber(event.target.value)
	})

	const updateNewSocialReason = useCallback((event) => {
		setNewSocialReason(event.target.value)
	})

	const updateNewCompanyName = useCallback((event) => {
		setNewCompanyName(event.target.value)
	})

	const updateNewAgencyName = useCallback((event) => {
		setNewAgencyName(event.target.value)
	})

	const handleApiResponseCompanyInformation = (response) => {
		const { data, ok } = response
		if (!ok) {
			setNewSocialReason('')
			setNewCompanyName('')
			setNewAgencyName('')
			setNewSiretNumber('')
			setValueCheckbox([])
			setListPdPs([])

			setIsReadOnly(true)
			enqueueSnackbar(t(`specialsTreatments:${data[0].code}`), { variant: 'error' })
			return
		}
		setNewSocialReason(data.socialReason)
		setNewCompanyName(data.companyName)
		setNewAgencyName(data.agencyName)
		setNewSiretNumber(data.siretNumber)
		setListPdPs(data.preventionPlans)
		setDldCompanyId(data.companyId)

		setInitialValues({
			...initialValues,
			oldSiret: data.siretNumber,
			oldSocialReason: data.socialReason,
			oldCompanyName: data.companyName,
			oldAgencyName: data.agencyName,
			newSiret: data.siretNumber,
			newSocialReason: data.socialReason,
			newCompanyName: data.companyName,
			newAgencyName: data.agencyName,
		})

		let temp = []
		//Initialize the list of checkboxs
		data.preventionPlans.forEach((item) => {
			temp.push({ id: `${item.pdpId}`, checked: true })
		})
		setValueCheckbox(temp)

		setIsReadOnly(false)
	}

	const ModalSubmit = () => {
		setConfirmChanges(true)
		close()
	}

	const close = () => {
		setModalState(false)
	}

	const onSubmit = useCallback((_values) => {
		setModalState(true)
	})

	return (
		<>
			<Form className={classes.form} onSubmit={onSubmit} initialValues={initialValues} formCallback>
				{() => (
					<>
						<Typography
							variant="body2"
							color="secondary"
							className={classes.siretChangementInformations}
						>
							{t('siret_changement_informations')}
						</Typography>
						<Grid container justify="center" spacing={16} className={classes.parent}>
							<Grid item md={6} sm={12}>
								<Paper className={classes.panel}>
									<Typography variant="h6" color="secondary">
										{t('old_company')}
									</Typography>

									<InputText
										name="oldSiret"
										label={t('user:siretNumber')}
										validate={siretFormat}
										onChange={findCompanyBySiretNumber}
										required
									/>
									<InputText
										disabled={true}
										name="oldSocialReason"
										label={t('user:socialReason')}
									/>
									<InputText disabled={true} name="oldCompanyName" label={t('user:companyName')} />
									<InputText disabled={true} name="oldAgencyName" label={t('user:agencyName')} />
								</Paper>
							</Grid>
							<Grid item md={6} sm={12}>
								<Paper className={classes.panel}>
									<Typography variant="h6" color="secondary">
										{t('new_company')}
									</Typography>
									<InputText
										disabled={isReadOnly}
										name="newSiret"
										label={t('user:siretNumber')}
										onChange={updateNewSiretNumber}
										required
									/>
									<InputText
										disabled={isReadOnly}
										name="newSocialReason"
										label={t('user:socialReason')}
										onChange={updateNewSocialReason}
										required
									/>
									<InputText
										disabled={isReadOnly}
										name="newCompanyName"
										label={t('user:companyName')}
										onChange={updateNewCompanyName}
										required
									/>
									<InputText
										disabled={isReadOnly}
										name="newAgencyName"
										label={t('user:agencyName')}
										onChange={updateNewAgencyName}
										required
									/>
								</Paper>
							</Grid>

							<Grid item sm={12}>
								{listPdPs && listPdPs.length > 0 ? (
									<>
										<Typography variant="h6" color="secondary">
											{t('specialsTreatments:select_pdp')}
										</Typography>
										<InputCheckboxGroup
											key={`listPdPs`}
											name={`listPdPs`}
											label={'PDP'}
											setValueCheckbox={setValueCheckbox}
											valueCheckbox={valueCheckbox}
											fullPage={true}
										>
											{listPdPs &&
												listPdPs.map((pdp) => {
													return (
														<Checkbox
															fullPage={true}
															name={`pdp-${pdp.pdpId}`}
															label={`${pdp.pdpNumero} - ${platformName(pdp)}`}
															key={pdp.pdpId}
														/>
													)
												})}
										</InputCheckboxGroup>
									</>
								) : (
									<Typography variant="h6" color="secondary">
										{t('specialsTreatments:no_pdp_found')}
									</Typography>
								)}
							</Grid>
							<Grid item sm={12} className={classes.buttonWrapper}>
								<FormButtonFullPage
									color="secondary"
									classes={{ root: classes.submitButton }}
									label={t('user:generate')}
									disabled={isReadOnly}
									type="submit"
								/>
							</Grid>
						</Grid>
					</>
				)}
			</Form>
			<Modal open={modalState} onClose={close}>
				<div className={classes.modalPaper}>
					<Form onSubmit={ModalSubmit}>
						<Typography variant="h6" classes={{ root: classes.modalTitle }}>
							{t('user:ConfirmSiretChanges', { CompanyName: newCompanyName })}
						</Typography>
						<Typography variant="body1" classes={{ root: classes.modalSecondTitle }}>
							{t('user:ReasonConfirmSiretChanges')}
						</Typography>
						<div className={classes.modalButtonGroup}>
							<Button classes={{ root: classes.modalButton }} onClick={close}>
								{t('user:cancel')}
							</Button>
							<Button
								className={classNames(classes.modalButton, classes.modalValidateButton)}
								type="submit"
							>
								{t('user:submit')}
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}
SiretChangement.propTypes = {
	match: PropTypes.object,
}

export default SiretChangement
