import React, { useState, useEffect } from 'react'
import SlidingForm from '../../Form/SlidingForm'
import FormIcon from '../../Form/FormIcon'
import FormButton from '../../Form/FormButton'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Form from '../../Form/Form'
import { Grid } from '@material-ui/core'
import InputText from '../../Form/input/InputText'
import InputCheckbox from '../../Form/input/InputCheckbox'
import {
	recoverChemicalProduct,
	upsertChemicalProductById,
	upsertChemicalProduct,
} from '../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { HandlingErrorMessages } from '../../../utils/Errors'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	validateRoot: {
		display: 'block',
		margin: '0 auto',
	},
	inputText: {
		marginBottom: 20,
	},
}))

function ChemicalProductsStorage({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'validate', 'chemical'])
	const [initialValues, setInitialValues] = useState({})
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [noStorage, setNoStorage] = useState(false)
	const [isReadOnly, setIsReadOnly] = useState(false)
	const [formValues, setFormValues] = useState({})

	const formatResponse = (resData) => ({
		localisation: resData.pddLocalisation,
		userService: resData.pddServiceUtilisateurTotalEe,
		minimumStorage: resData.pddStockMin,
		estimation: resData.pddEstimationBesoinAnnuel,
		buildingUnit: resData.pddUnite,
		conditionnement: resData.pddConditionnement,
		maxStorage: resData.pddStockMax,
		frequence: resData.pddFrequenceReapprovisionnement,
		typeRetention: resData.pddTypeRetentionNecessaire,
	})

	const formatRequest = (resData) => ({
		localisation: resData.localisation,
		serviceUtilisateurTotalEE: resData.userService,
		stockMin: resData.minimumStorage,
		estimationBesoinAnnuel: resData.estimation,
		unite: resData.buildingUnit,
		conditionnement: resData.conditionnement,
		stockMax: resData.maxStorage,
		frequenceReapprovisionnement: resData.frequence,
		typeRetentionNecessaire: resData.typeRetention,
		pasDeStockageSurSite: noStorage,
	})

	const noStorageFormat = () => ({
		localisation: '',
		serviceUtilisateurTotalEE: '',
		stockMin: data.minimumStorage,
		estimationBesoinAnnuel: '',
		unite: '',
		conditionnement: '',
		stockMax: '',
		frequenceReapprovisionnement: '',
		typeRetentionNecessaire: '',
		PasDeStockageSurSite: true,
	})

	useEffect(() => {
		const handleRecoverCP = (res, err) => {
			if (err || !res) return
			setFormValues(formatResponse(res.data))
			setNoStorage(res.data.pddPasDeStockageSurSite)
		}
		const sendCPRecovery = () => {
			if (!data || !data.productId || !data.companyRecordId) return
			if (data.productId.create) return
			recoverChemicalProduct(data.platformId, data.companyRecordId, data.productId, handleRecoverCP)
		}
		if (!open) {
			setInitialValues({})
			return
		}
		sendCPRecovery()
	}, [open, data, cookies])

	useEffect(() => {
		setInitialValues(formValues)
		setIsReadOnly(noStorage)
	}, [formValues])

	const handleSendCP = (res, err) => {
		if (err) {
			if (err.data && err.data[0]) {
				if (err.data[0].innerErrors && err.data[0].innerErrors[0]) {
					enqueueSnackbar(t('error') + ` : ${t(err.data[0].innerErrors[0].code)} !`, {
						variant: 'error',
					})
					return
				}
				enqueueSnackbar(t('error') + ` : ${t(err.data[0].code)} !`, { variant: 'error' })
				return
			}
			enqueueSnackbar(t('error') + ` : ${err.status} !`, { variant: 'error' })
			return
		}
		if (!res) return
		enqueueSnackbar(t('chemicalProductHasBeenUpdated'), {
			variant: 'info',
		})

		close()
		if (res.data && res.data.companyRecordId)
			data.setRevisionCompanyRecordId(res.data.companyRecordId)
		data.refresh()
		data.productId.refreshStore()
	}

	const sendCreateAndStorageRequest = (values) => {
		upsertChemicalProduct(
			{
				companyId: data.companyId,
				data: data.productId.values,
			},
			(res, err) => {
				if (err && err.data) {
					HandlingErrorMessages(err.data, (code) => {
						enqueueSnackbar(t('chemical:' + code), { variant: 'error' })
					})
					return
				}
				if (res) {
					upsertChemicalProductById(
						data.platformId,
						data.companyRecordId,
						res.data,
						values,
						handleSendCP,
					)
					close()
					data.productId.refreshStore()
					data.refresh()
				}
			},
		)
	}

	const sendFormRequest = (values) => {
		const form = () =>
			upsertChemicalProductById(
				data.platformId,
				data.companyRecordId,
				data.productId,
				values,
				handleSendCP,
			)
		if (data.creationFunc) data.creationFunc(() => form())
		else form()
	}

	function handleNoStorage() {
		setNoStorage(!noStorage)
	}

	useEffect(() => {
		if (noStorage) {
			setInitialValues(noStorageFormat)
		} else {
			setInitialValues(formValues)
		}
		setIsReadOnly(noStorage)
	}, [noStorage])

	const onSubmit = (values) => {
		if (!values) console.error(new Error('No data !'))

		if (data.productId.create) return sendCreateAndStorageRequest(formatRequest(values))

		sendFormRequest(formatRequest(values))
	}

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			title={t('chemicalTitle')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<Grid container spacing={8} justify="center">
					<Grid item sm={12} md={12}>
						<InputCheckbox
							name="noStorageOnSite"
							label={t('noStorageOnSite')}
							labelClass={classes.inputText}
							checked={noStorage}
							onChange={handleNoStorage}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							label={t('Localisation')}
							name="localisation"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
						/>
						<InputText
							label={t('userService')}
							name="userService"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
						<InputText
							label={t('minimumStock')}
							name="minimumStorage"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
						<InputText
							label={t('yearNeeded')}
							name="estimation"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							name="buildingUnit"
							label={t('UniteBatiment')}
							classes={{
								root: classes.inputText,
							}}
							disabled={isReadOnly}
						/>
						<InputText
							label={t('Conditionnement')}
							name="conditionnement"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
						/>
						<InputText
							label={t('StockMaximum')}
							name="maxStorage"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							label={t('frequence')}
							name="frequence"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
						<InputText
							label={t('retention')}
							name="typeRetention"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
						/>
					</Grid>
				</Grid>

				{!data.isReviewMode && (
					<FormButton classes={{ root: classes.validateRoot }} label="Valider" type="submit" />
				)}
				<br />
				{data && data.productId && data.productId.create && (
					<FormButton
						classes={{ root: classes.validateRoot }}
						label={t('back')}
						onClick={() => {
							close()
							data.productId.openChemicalProductsForm(undefined, {
								openChemicalProductsForm: data.productId.openChemicalProductsForm,
								refreshStore: data.productId.refreshStore,
								initialValues: data.productId.initialValues,
							})
						}}
					/>
				)}
			</Form>
		</SlidingForm>
	)
}
ChemicalProductsStorage.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}

export default ChemicalProductsStorage
