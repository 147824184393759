import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { Field } from 'react-final-form'

const useStyles = makeStyles(theme => ({
	button: {
		display: 'inline-block',
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.secondary.main,
		},
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	divRoot: {
		display: 'inline-block',
		width: '50%',
	},
	typographyRoot: {
		display: 'inline-block',
	},
}))

/**
 * Shows a InputFile with the fullpage style.
 * @param {String} label : label of the field
 * @param {String} name : name of the field
 * @param {any} className : Style of the label around the field
 * @param {any} props : props supplies to the button
 * @returns {JSX.Element} React stack to render
 */
function InputFile({ label, name, nameFileUpdated, className, setFile, hideName, ...props }) {
	const classes = useStyles()
	const [nameFile, setNameFile] = useState(null)

	useEffect(() => {
		if (nameFileUpdated) {
			nameFileUpdated(nameFile)
		}
	}, [nameFile])

	return (
		<Field
			name={name}
			render={({ input, meta }) => {
				return (
					<>
						<input
							accept=".pdf,.doc,.docx"
							className={classes.input}
							style={{ display: 'none' }}
							id={name}
							type="file"
							onInput={e => {
								setNameFile(e.target.files[0].name)
								setFile(e.target.files[0])
							}}
							{...input}
						/>
						<label htmlFor={name} className={className}>
							<div>
								<Button
									variant="outlined"
									component="span"
									color="secondary"
									className={classes.button}
									{...props}
								>
									{label}
								</Button>
								<Typography
									variant="body2"
									color="secondary"
									classes={{ root: classes.typographyRoot }}
								>
									{!hideName && nameFile}
								</Typography>
							</div>
						</label>
					</>
				)
			}}
		/>
	)
}
export default InputFile
