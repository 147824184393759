import { Button, Typography } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { getChemicalProducts, upsertChemicalProductById } from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import { TotalRadio } from '../../Form/input/InputRadio'
import InputRadio from '../../Form/inputFullPage/InputRadio'
import SlidingForm from '../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	radioInput: {
		display: 'flex',
	},
	validateButton: {
		color: theme.palette.primary.main,
		display: 'block',
		marginLeft: 0,
		marginRight: 0,
		width: '2em',
		height: '2em',
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	text: {
		marginTop: 20,
	},
	buttonAdd: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	addIcon: {
		width: '2em',
		height: '2em',
	},
}))

/**
 * Shows a form to add a chemical product to the company file
 * @returns {JSX.Element} React stack to render
 */
function AddChemicalProduct({ open, close, data }) {
	const classes = useStyles()
	const [products, setProducts] = useState([])
	const [cookies] = useCookies()
	const { t } = useTranslation('user')
	const { enqueueSnackbar } = useSnackbar()
	const [update, setUpdate] = useState(false)

	const refresh = () => setUpdate(!update)

	const handleSendCP = (res, err) => {
		if (err || !res) return
		close()
		if (res.data && res.data.companyRecordId) {
			data.setRevisionCompanyRecordId(res.data.companyRecordId)
			data.refresh()
		}
	}

	const sendFormRequest = (values, productId) => {
		if (data.openChemicalProductsStorageForm)
			data.openChemicalProductsStorageForm(productId, (response) =>
				upsertChemicalProductById(
					cookies[PLATFORM_ID_COOKIE],
					data.companyRecordId,
					productId,
					values,
					(res, err) => {
						response()
						handleSendCP(res, err)
					},
				),
			)
	}

	const recoverCPList = () => {
		if (!data.companyId) return
		getChemicalProducts(
			data.companyId,
			{
				platformId: 1,
				pageNumber: 1,
				NbItemsAsked: 10,
			},
			(res, err) => {
				if (err) return console.error(err)
				setProducts(res.data.data)
			},
		)
	}

	const onSubmit = (values) => {
		let reslt = products.filter((product) => {
			return product.id == values.chemicalProduct
		})[0]
		if (!reslt)
			return enqueueSnackbar(t('PleaseSelectAtLeastOneElement'), {
				variant: 'info',
			})

		if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
		sendFormRequest(reslt, reslt.id)
		data.setUpdate((u) => !u)
	}

	useEffect(recoverCPList, [data.companyId, update])

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addFromLibrary')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit}>
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('chemicalLibrary')}
				</Typography>
				<InputRadio name="chemicalProduct">
					{products.map((el, i) => (
						<TotalRadio
							className={classes.radioInput}
							label={el.name}
							value={el.id.toString()}
							key={el.id}
						/>
					))}
				</InputRadio>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<div>
						<Button
							className={classes.buttonAdd}
							variant="outlined"
							onClick={() => {
								data.openChemicalProductsForm(undefined, {
									refreshStore: refresh,
									openChemicalProductsForm: data.openChemicalProductsForm,
								})
								close()
							}}
						>
							<AddCircle className={classes.addIcon} />
						</Button>
					</div>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddChemicalProduct
