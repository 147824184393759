import { Checkbox, Fab, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloudOff from '@material-ui/icons/CloudOff'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import DWPContactCard from '../../components/Cards/DWPContactCard'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { PLATFORM_ID_COOKIE, COMPANY_ID_COOKIE } from '../../constants/cookies'
import ROLES from '../../constants/roles'
import { useMatch } from '../../containers/AuthContainer'
import { getDWDCorrespondentsByAccountId, setPermitFlag } from '../../containers/DataContainer'
import { useDWPForm } from '../../containers/FormContainer'
import Access from '../../containers/RoleContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
	checkboxChecked: {
		color: theme.palette.secondary.main,
		'&$checkboxChecked': {
			color: theme.palette.secondary.main,
		},
	},
	elibility: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
	},
	messageElibility: {
		color: theme.palette.secondary.main,
		margin: 0,
		fontSize: 13,
		transform: 'translateY(-11px)',
	},
	elibilityGroup: {
		textAlign: 'center',
	},
	emptyMessage: {
		display: 'block',
		margin: 'auto',
	},
	icons: {
		verticalAlign: 'middle',
		fontSize: 25,
		margin: 5,
	},
}))

function DWPCorrespondent({ match }) {
	const { t } = useTranslation('user')
	const { setTitleBar } = useTotalTitleBarContext()
	const [correspondentDWD, setCorrespondentDWD] = useState([])
	const [cookies] = useCookies()
	const [formData, setFormData] = useState({})
	const [isSubmit, setIsSubmit] = useState(false)
	const [isUpdate, setisUpdate] = useState(false)
	const [isClose, setIsClose] = useState(false)
	const [data, setData] = useState({})
	const { companyId, formatUrl } = useMatch(match, '/op-infos')
	const targetCompanyID = companyId != null ? companyId : cookies[COMPANY_ID_COOKIE]
	const DWPCorrespondentForm = useDWPForm({ ...data, companyID: targetCompanyID })
	const [checkboxValue, setCheckboxValue] = useState(false)
	const classes = useStyles()

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: t('OperationnalData') },
		})
	}, [])

	useEffect(() => {
		getDWDCorrespondentsByAccountId(
			cookies[PLATFORM_ID_COOKIE],
			targetCompanyID,
			function (res, error) {
				if (error) {
					return
				}
				if (res) {
					setCheckboxValue(res.data.platformIsEligibleToSimplePermit)
					setCorrespondentDWD(res.data.dwpCorrespondents)
					setIsSubmit(false)
				}
			},
		)
	}, [isSubmit, cookies, targetCompanyID])

	const sendDataToForm = (values) => {
		setFormData(values)
	}

	const changeFormState = () => {
		setFormData(null)
		setIsSubmit(true)
	}

	const updateListCards = (values) => {
		setCorrespondentDWD(values)
	}

	const isClicked = () => {
		setisUpdate(true)
	}

	const formClose = () => {
		setIsClose(true)
	}

	useEffect(() => {
		setData({
			data: formData,
			changeState: changeFormState,
			updateList: updateListCards,
			formClose: formClose,
		})
	}, [formData, isClose, isUpdate, isSubmit])

	const AddDWPCorrespondent = () => {
		const classes = useStyles()
		const newDWP = () => {
			DWPCorrespondentForm.open()
		}

		return (
			<ErrorBoundary>
				<Fab className={classes.fab} color="secondary" onClick={newDWP}>
					<AddIcon />
				</Fab>
			</ErrorBoundary>
		)
	}

	useEffect(() => {
		if (isUpdate) {
			DWPCorrespondentForm.open()
			setisUpdate(false)
		}
	}, [isUpdate])

	useEffect(() => {
		if (isClose) {
			setFormData({})
			setIsClose(false)
		}
	}, [isClose])

	const toggleSimplePermit = () => {
		setPermitFlag(
			!checkboxValue,
			cookies[PLATFORM_ID_COOKIE],
			targetCompanyID,
			function (res, error) {
				if (error) {
					return
				}
				if (res) {
					setCheckboxValue(!checkboxValue)
				}
			},
		)
	}

	return (
		<>
			{DWPCorrespondentForm.formular}
			<HeadTitle title={t('DWDCorrespondent')} link={formatUrl} />
			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
				<div className={classes.elibilityGroup}>
					<p className={classes.elibility}>{t('eligible')}</p>
					<Checkbox
						className={classes.checkboxChecked}
						onClick={toggleSimplePermit}
						checked={checkboxValue}
					/>
					{checkboxValue && <p className={classes.messageElibility}>{t('eligibleSimplePermit')}</p>}
					{!checkboxValue && (
						<p className={classes.messageElibility}>{t('notEligibleSimplePermit')}</p>
					)}
				</div>
			</Access>
			<Grid container spacing={0} direction="row">
				{correspondentDWD.length ? (
					correspondentDWD.map((site, i) => {
						return (
							<DWPContactCard
								key={i}
								size={3}
								data={site}
								sendData={sendDataToForm}
								clicked={isClicked}
							>
								{site.name}
							</DWPContactCard>
						)
					})
				) : (
					<Typography variant="h6" color="secondary" className={classes.emptyMessage}>
						<CloudOff className={classes.icons} />
						{t('noDWPContact')}
					</Typography>
				)}
			</Grid>

			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMP, ROLES.EXTCOMPADMIN]}>
				<AddDWPCorrespondent />
			</Access>
		</>
	)
}

export default DWPCorrespondent
