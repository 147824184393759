import React, { useEffect, useState, useContext } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import {
	IconButton,
	Icon,
	Select,
	MenuItem,
	Fab,
	Paper,
	Typography,
	Tooltip,
	Grid,
	CircularProgress,
} from '@material-ui/core'
import { Link, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
	getCompanyRecordList,
	getNewCompanyRecordList,
	downloadFile,
} from '../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { useCreateCRForm } from '../../containers/FormContainer'
import STATUS_DE, { findStatusById } from '../../constants/statusDE'
import ROLES, { MODIFY_COMPANYRECORD_WITHOUT_PRINCIPAL } from '../../constants/roles'
import Access from '../../containers/RoleContainer'
import { useTranslation } from 'react-i18next'
import { dateDisplay } from '../../utils/Date'
import { useSnackbar } from 'notistack'
import fileDownload from 'js-file-download'
import TYPES from '../../constants/pdpType'
import { TotalModalPopUpContext } from '../../contexts/TotalModalPopUpContext'
import TotalPopUpWarning from '../../components/TotalPopUpWarning/TotalPopUpWarning'
import {
	PLATFORM_ID_COOKIE,
	COMPANY_ID_COOKIE,
	ACCOUNT_TYPES_COOKIE,
} from '../../constants/cookies'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'
import ConfirmRevision from '../../components/forms/ConfirmRevision'
import Form from '../../components/Form/Form'
import { InputSelectOnChange } from '../../components/Form/inputFullPage/InputSelect'
import api from '../../utils/Requester'
import { CR_VIEWS_BY_ROLE } from '../../constants/url'
import PropTypes from 'prop-types'
import { COLUMNS_FOLLOW_CR, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	containerPage: {
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
	},
	container: {
		display: 'block',
		position: 'relative',
		width: 600,
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: theme.spacing.unit,
	},
	select: {
		width: '80%',
	},
	fab: {
		position: 'absolute',
		right: theme.spacing.unit,
		bottom: theme.spacing.unit,
	},
	validated: {
		color: '#006946',
	},
	refuse: {
		color: '#F44436',
	},
	div: {
		textAlign: 'center',
	},
	dataTableContainer: {
		position: 'relative',
	},
	widthSelect: {
		width: '20%',
	},
	button: {
		position: 'absolute',
		top: 8,
		right: 300,
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	IconAndSubText: {},
	ISIcon: {
		display: 'block',
		margin: 'auto',
	},
	ISSubText: {
		textAlign: 'center',
		fontSize: 10,
	},
}))

function FollowCompanyRecord() {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation(['user'])
	const { enqueueSnackbar } = useSnackbar()

	const { formular } = useCreateCRForm()

	const [initialValue, setInitialValue] = useState(null)
	const [pdpList2, setPdpList2] = useState([])
	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const [selectedDE, setSelectedDE] = useState(null)
	//Use for redirect if DE revision
	const [redirected, setRedirected] = useState({ State: false, Link: '' })
	const { typeModal, setTypeModal } = useContext(TotalModalPopUpContext)
	const [openModel, setOpenModal] = useState(false)
	const [currentDeId, setCurrentDeId] = useState()
	const [currentEntId, setCurrentEntId] = useState()
	const [modalState, setModalState] = useState(false)
	const [views, setViews] = useState(null)
	const [actualView, setActualView] = useState(null)
	const [isLoading, setIsLoading] = useState(true)

	/* Open a modal if the context contain a message */
	useEffect(() => {
		if (typeModal) setOpenModal(true)
	}, [typeModal])

	const closeModal = () => {
		setOpenModal(false)
		/* Flush the context */
		setTypeModal(null)
	}

	const IDWP = {
		AUTO: {
			id: 'Received',
			label: t('user:Receive'),
			icon: 'checked_circle',
			color: '#006946',
		},
		DEROGATION: { id: 'Sent', label: t('user:DWPsend'), icon: 'send', color: '#F5960A' },
		BANNED: {
			id: 'NotIssued',
			label: t('user:notSend'),
			icon: 'portable_wifi_off',
			color: '#888888',
		},
		ERROR: {
			id: 'Failed',
			label: t('user:ErrorSend'),
			icon: 'error',
			color: '#F44436',
		},
	}

	const IconWithSubtext = ({ icon, label, color }) => {
		return (
			<div className={classes.IconAndSubText}>
				<Icon className={classes.ISIcon} style={{ color: color }}>
					{icon}
				</Icon>
				<Typography className={classes.ISSubText} style={{ color: color }}>
					{label}
				</Typography>
			</div>
		)
	}
	IconWithSubtext.propTypes = {
		icon: PropTypes.string,
		label: PropTypes.string,
		color: PropTypes.string,
	}
	const findIDWPById = (id) =>
		Object.values(IDWP).filter((s) => {
			if (s.id === id) return s
		})[0]

	const handleResponse = (res, err) => {
		if (err) {
			return
		}
		if (!res) return
		let data = res.data

		let processedData = data.map((dt) => ({
			id: dt.pdPId,
			revisionId: dt.revisionPdP,
			sousTraitant: dt.estSousTraitante,
			numero_pdp: dt.pdPNumero,
			entId: dt.entId,
			libelle_pdp: dt.libellePdP,
		}))
		setPdpList2(processedData)
		setInitialValue(processedData[0].id)
	}

	const getCompanyRecordFollowUp = () => {
		setIsLoading(true)
		if (actualView || actualView === 0) {
			let p_Request = {
				viewTypeId: actualView,
				nbItemsAsked: 1,
				pageNumber: 1,
			}

			getCompanyRecordList(cookies[PLATFORM_ID_COOKIE], p_Request, (res, err) => {
				setIsLoading(false)
				if (err) return
				if (res) {
					setDatatable(res.data)
				}
			})
		}
	}

	const actionsColumn = {
		name: 'actions',
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const createDE = () => {
		if (!pdpList2.length) return
		let pdp = pdpList2.find(function (element) {
			return element.id === initialValue
		})

		if (pdp.sousTraitant) {
			//TEMPORARY : Do not show the pop-up for soutraitant
			//addData(pdp);
			//open();
			//return;
		}
		setSelectedDE({ ...pdp, isSoutraitant: !pdp.sousTraitant && 0 })
	}

	const FolderCreator = () => (
		<Paper className={classes.container}>
			<Typography variant="caption" align="center">
				{t('FolderToCreate')}
			</Typography>
			<Select
				value={initialValue}
				onChange={(e) => {
					setInitialValue(e.target.value)
				}}
				className={classes.select}
			>
				{pdpList2.length > 0 &&
					pdpList2.map((pdp, index) => (
						<MenuItem key={index} value={pdp.id}>
							{pdp.numero_pdp} - {pdp.libelle_pdp}
						</MenuItem>
					))}
				{pdpList2.length < 1 && <MenuItem value={0}>{t('noDEToCreate')}</MenuItem>}
			</Select>
			<Fab size="small" color="secondary" className={classes.fab} onClick={createDE}>
				<Icon>add</Icon>
			</Fab>
		</Paper>
	)

	const downloadDocument = (path) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	const FollowTable = () => {
		//#region Custom Columns

		const customPreliminaryVisitColumn = (value) => {
			if (value)
				return (
					<div className={classes.div}>
						<Icon className={classes.validated}>checked_circle</Icon>
					</div>
				)
			else
				return (
					<div className={classes.div}>
						<Icon className={classes.refuse}>remove_circle_outline</Icon>
					</div>
				)
		}

		const customDerniereModification = (value) => {
			if (!value) return
			return dateDisplay(value)
		}

		const customActionsColumn = (_value, tableMeta) => {
			if (!tableMeta.rowData) return
			const entId = tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.ENTID)]
			const depId = tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.DEPID)]
			const pdpId = tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.PDPID)]
			const revId = tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.PDPREV)]
			const deFilePath = datatable.data.find((item) => item.depId === depId).documentCheminComplet

			const currentId =
				tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.STATUTDE)]
			const currentStatusDE = findStatusById(currentId)
			const isValid = currentStatusDE === STATUS_DE.VALIDE
			const isDraft =
				currentStatusDE === STATUS_DE.BROUILLON || currentStatusDE === STATUS_DE.INCOMPLET
			const isProcess = currentStatusDE === STATUS_DE.SOUMIS

			const isLastRevision =
				tableMeta.rowData[getIndexOfColumn(datatable.columns, COLUMNS_FOLLOW_CR.ISLASTREVISION)]

			const LinkIcon = ({ icon, toolTip, to, onClick }) => (
				<Tooltip title={toolTip}>
					<Link onClick={onClick} to={to} target={'_blank'} style={{ textDecoration: 'none' }}>
						<IconButton>
							<Icon>{icon}</Icon>
						</IconButton>
					</Link>
				</Tooltip>
			)
			LinkIcon.propTypes = {
				icon: PropTypes.string,
				toolTip: PropTypes.string,
				to: PropTypes.string,
				onClick: PropTypes.func,
			}

			const ActionIcon = ({ icon, toolTip, onClick }) => (
				<Tooltip title={toolTip}>
					<IconButton onClick={() => onClick()}>
						<Icon>{icon}</Icon>
					</IconButton>
				</Tooltip>
			)
			ActionIcon.propTypes = {
				icon: PropTypes.string,
				toolTip: PropTypes.string,
				onClick: PropTypes.func,
			}

			return (
				<>
					{redirected.State && <Redirect target={'_blank'} to={redirected.Link} />}
					{isValid && isLastRevision && (
						<Access accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}>
							<IconButton
								onClick={() => {
									setCurrentDeId(depId)
									setCurrentEntId(entId)
									setModalState(true)
								}}
							>
								<Tooltip title={t('OpenDE')}>
									<Icon>{'cached'}</Icon>
								</Tooltip>
							</IconButton>
						</Access>
					)}

					{isDraft && (
						<Access accept={MODIFY_COMPANYRECORD_WITHOUT_PRINCIPAL}>
							<LinkIcon
								icon="edit"
								toolTip={t('OpenDraft')}
								to={`/companyRecord/${cookies[PLATFORM_ID_COOKIE]}/${entId}/${depId}`}
							/>
						</Access>
					)}

					{isDraft && (
						<Access accept={[ROLES.PRINCIPAL]}>
							<LinkIcon
								icon="search"
								toolTip={t('ShowDE')}
								to={`/companyRecordValidate/${cookies[PLATFORM_ID_COOKIE]}/${pdpId}/${revId}/${entId}/${depId}`}
							/>
						</Access>
					)}
					{(isProcess || isValid) && (
						<LinkIcon
							icon="search"
							toolTip={t('ShowDE')}
							to={`/companyRecordValidate/${cookies[PLATFORM_ID_COOKIE]}/${pdpId}/${revId}/${entId}/${depId}`}
						/>
					)}
					{(isProcess || isValid) && (
						<ActionIcon
							icon="get_app"
							toolTip={t('DownloadPDF')}
							onClick={() => {
								downloadDocument(deFilePath)
							}}
						/>
					)}
				</>
			)
		}

		const customColumns = [
			{
				name: 'type',
				component: function componentCustomColumnType(value) {
					switch (value) {
						case TYPES.principale.code:
							return <img src={TYPES.principale.icon} alt="" />

						case TYPES.subcontract.code:
							return <img src={TYPES.subcontract.icon} alt="" />

						case TYPES.princsub.code:
							return <img src={TYPES.princsub.icon} alt="" />

						case TYPES.error.code:
							return <img src={TYPES.error.icon} alt="" />

						default:
							return <img src={TYPES.error.icon} alt="" />
					}
				},
			},
			{
				name: 'dwpStatusCode',
				component: function componentCustomColumnType(value) {
					if (!value) return
					let currentState = findIDWPById(value)
					if (!currentState) return
					return (
						<IconWithSubtext
							icon={currentState.icon}
							label={currentState.label}
							color={currentState.color}
						/>
					)
				},
			},
			{
				name: 'visitePrealable',
				component: customPreliminaryVisitColumn,
			},
			{
				name: 'derniereModification',
				component: customDerniereModification,
			},
			{
				name: 'actions',
				component: customActionsColumn,
			},
		]
		//#endregion Custom Columns

		return (
			<>
				{isLoading && (
					<CircularProgress disableShrink color="secondary" className={classes.buttonProgress} />
				)}
				{!isLoading && (
					<div className={classes.dataTableContainer}>
						<Form
							onSubmit={() => null}
							initialValue={{ viewsSelect: actualView ? actualView : 2 }}
							className={classes.button}
						>
							<Grid container justify="flex-end">
								<Grid item xs={2}>
									<InputSelectOnChange
										value={actualView}
										onChange={(e) => {
											setActualView(e.target.value)
										}}
									>
										{views &&
											views.map((view) => (
												<MenuItem key={view.viewId} value={view.viewId}>
													{t(view.viewName)}
												</MenuItem>
											))}
									</InputSelectOnChange>
								</Grid>
							</Grid>
						</Form>
						<DataTable
							title={t('FollowDE')}
							columns={[...datatable.columns, actionsColumn]}
							data={datatable.data}
							options={datatable.options}
							customColumns={customColumns}
							isLoading={isLoading}
							customLabels={[
								{
									name: 'numeroPdp',
									label: t('pdpNumero'),
								},
								{
									name: 'principalName',
									label: t('principal'),
								},
								{
									name: 'dwpStatusCode',
									label: t('dwpstatuscode'),
								},
								{
									name: 'dwpStatusLabel',
									label: t('dwpstatuscode'),
								},
								{
									name: 'natureIntervention',
									label: t('natureIntervention'),
								},
								{
									name: 'nomEntreprise',
									label: t('entRaisonSociale'),
								},
								{
									name: 'type',
									label: t('companycontracttype'),
								},
								{
									name: 'visitePrealable',
									label: t('visitePrealable'),
								},
								{
									name: 'revisionDossier',
									label: t('depNumeroRevision'),
								},
								{
									name: 'localStatut',
									label: t('localStatut'),
								},
								{
									name: 'derniereModification',
									label: t('derniereModification'),
								},
								{
									name: 'createur',
									label: t('creator'),
								},
								{
									name: 'libellePdP',
									label: t('pdp_description'),
								},
								{
									name: 'descriptionLieuxPdP',
									label: t('works_locations'),
								},
							]}
						/>
					</div>
				)}
			</>
		)
	}

	//#region Effects
	useEffect(() => {
		if (actualView || actualView === 0) getCompanyRecordFollowUp()
	}, [actualView])

	useEffect(() => {
		api.get(CR_VIEWS_BY_ROLE).then((res) => {
			const { data, ok } = res

			if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

			if (data) {
				let _views = []
				// Multi compte delete doubles
				cookies[ACCOUNT_TYPES_COOKIE].map((type) => {
					if (!_views.length) {
						_views = data[type.code]
						return _views
					} else {
						//Feed array without doubles
						if (data[type.code]) {
							data[type.code].map((d) => {
								if (_views.filter((x) => x.viewId === d.viewId).length) return null
								return _views.push(d)
							})
						} else {
							return null
						}
					}
				})

				// If no view, just redirect to the dashboard
				// Can happen if you switch role
				if (!_views) window.location.href = '/dashboard'

				setViews(_views)
				setActualView(_views.find((v) => v.isDefault).viewId)
			}
		})
	}, [enqueueSnackbar, t])

	useEffect(() => {
		if (
			cookies[ACCOUNT_TYPES_COOKIE].some(
				(ur) => ur.code === 'EXTCOMPADMIN' || ur.code === 'EXTCOMP',
			)
		)
			getNewCompanyRecordList(
				cookies[PLATFORM_ID_COOKIE],
				cookies[COMPANY_ID_COOKIE],
				handleResponse,
			)
	}, [])
	//#endregion

	return (
		<>
			{typeModal && (
				<TotalPopUpWarning
					open={openModel}
					onClose={closeModal}
					content={typeModal.content}
					title={typeModal.title}
				/>
			)}
			{selectedDE && (
				<Redirect
					to={
						'/createCompanyRecord/' +
						selectedDE.isSoutraitant +
						'/' +
						selectedDE.id +
						'/' +
						selectedDE.revisionId +
						'/' +
						selectedDE.entId
					}
				/>
			)}
			<HeadTitle title={t('FollowDE')} link="/dashboard" />
			<Access accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}>
				{initialValue && <FolderCreator />}
			</Access>
			<ConfirmRevision
				open={modalState}
				closeModal={setModalState}
				deId={currentDeId}
				entId={currentEntId}
				setRedirected={setRedirected}
			/>
			{formular}
			<FollowTable />
		</>
	)
}

export default FollowCompanyRecord
