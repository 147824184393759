import React, { useState, useEffect } from 'react'
import {
	Typography,
	Button,
	Icon,
	IconButton,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import {
	getActivityPhases,
	toggleActivityPhaseState,
	deleteActivityPhases,
	downloadFile,
} from '../../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import fileDownload from 'js-file-download'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../../utils/File'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: -55,
		right: 10,
	},
	disabled: {
		color: '#e0e0e0 !important',
	},
	iconNewFromRevision: {
		color: theme.palette.secondary.main,
		textAlign: 'center',
		width: '100%',
	},
}))

function PhasesUnsafeOperations({
	forms,
	btnsStyle,
	queries,
	isReadOnly,
	isReviewMode = false,
	setRevisionCompanyRecordId,
	nextStep,
	previousStep,
}) {
	const classes = useStyles()
	const [update, setUpdate] = useState(false)

	const [PhasesUnsafeOperationsForm, PhaseUnsafeObservationForm] = forms

	const { t } = useTranslation(['user', 'companyRecord'])
	const { enqueueSnackbar } = useSnackbar()
	const [specificActivity, setSpecificActivity] = useState([])
	const { companyRecordId, platformId } = queries
	const [listActivities, setListActivities] = useState([])

	const updateData = () => {
		setUpdate(!update)
	}

	//#region PhasesUnsafeOperations
	const openPhasesUnsafeOperationsForm = () => {
		PhasesUnsafeOperationsForm.addData({
			updateData: updateData,
			companyRecordId: companyRecordId,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			platformId,
			activity: null,
			isReadOnly: Boolean(isReviewMode || isReadOnly),
		})
		PhasesUnsafeOperationsForm.open()
	}

	const deletePhasesUnsafeOperations = (activityId) => {
		deleteActivityPhases(platformId, companyRecordId, activityId, (res, err) => {
			if (err) {
				enqueueSnackbar('Erreur de suppression', { variant: 'error' })
				return
			}
			updateData()
			if (res.data && res.data.companyRecordId) setRevisionCompanyRecordId(res.data.companyRecordId)
			enqueueSnackbar('La phase particulière a été supprimée', {
				variant: 'info',
			})
		})
	}

	const openPhaseUnsafeObservationForm = (activityId, observation) => {
		PhaseUnsafeObservationForm.addData({
			updateData: updateData,
			companyRecordId: companyRecordId,
			activityId: activityId,
			observation: observation ? observation : undefined,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			specific: false,
			platformId,
			isReadOnly,
			isReviewMode,
		})
		PhaseUnsafeObservationForm.open()
	}

	const openSpecificPUOF = (activity) => {
		PhasesUnsafeOperationsForm.addData({
			activity,
			updateData: updateData,
			companyRecordId: companyRecordId,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			platformId,
			isReadOnly: Boolean(isReviewMode || isReadOnly),
		})
		PhasesUnsafeOperationsForm.open()
	}

	//#endregion PhasesUnsafeOperations

	//#region get data & datatable
	const recoverDatatable = () => {
		getActivityPhases(platformId, companyRecordId, (res, err) => {
			if (err) return console.error(err)
			if (!res || !res.data) return

			setSpecificActivity(
				res.data.specificActivityPhase.map((activity) => ({
					id: activity.id,
					title: activity.title,
					workDescription: activity.workDescription,
					risks: activity.risks,
					precautions: activity.precautions,
					observations: activity.observations,
					measures: activity.measures,
					isNewFromRevision: activity.isNewFromRevision,
				})),
			)

			let specificActivityPhase = res.data.specificActivityPhase.map((activity) => {
				return {
					pacId: activity.id,
					mopId: -1,
					displayName: activity.title,
					isSelected: 'specific',
					observation: activity.observation,
					isNewFromRevision: activity.isNewFromRevision,
				}
			})
			setListActivities([...res.data.activityPhase, ...specificActivityPhase])
		})
	}

	useEffect(recoverDatatable, [update])
	//#endregion Datatable

	const downloadPhase = (path) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	//#region toggle

	const toggleAccount = (activityId) => {
		toggleActivityPhaseState(platformId, companyRecordId, activityId, (res, error) => {
			if (error) {
				updateData()
				if (error.data && error.data[0]) {
					return enqueueSnackbar(`${t('view_render_failed')}`, {
						variant: 'error',
					})
				}
				return enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
			}
			if (res) {
				enqueueSnackbar('Phase mise à jour', { variant: 'info' })
				updateData()
			}
		})
	}

	const ToggleAccountButton = ({ activityId, mode, readOnly = null }) => {
		const [initialMode, setInitialMode] = useState()
		useEffect(() => {
			setInitialMode(mode)
		}, [mode])

		return (
			<IconButton
				disabled={isReadOnly || readOnly}
				size="small"
				className={classNames(classes.icon, { [classes.disabled]: isReadOnly || readOnly })}
				onClick={() => {
					toggleAccount(activityId)
				}}
			>
				{initialMode && <CheckIcon />}
				{!initialMode && <CloseIcon />}
			</IconButton>
		)
	}
	ToggleAccountButton.propTypes = {
		activityId: PropTypes.number,
		mode: PropTypes.bool,
		readOnly: PropTypes.bool,
	}

	//#endregion toggle

	const Observations = ({ activity }) => {
		if (activity.mopId === -1) {
			return (
				<IconButton
					onClick={() => {
						let activityPhase = specificActivity.filter((phase) => phase.id === activity.pacId)[0]
						openSpecificPUOF(activityPhase)
					}}
				>
					<Icon>edit</Icon>
				</IconButton>
			)
		}
		if (activity.observation) {
			return (
				<IconButton
					onClick={() => {
						openPhaseUnsafeObservationForm(activity.pacId, activity.observation)
					}}
				>
					<Icon>remove_red_eye</Icon>
				</IconButton>
			)
		}
		return (
			<IconButton
				disabled={isReadOnly || !activity.isSelected}
				className={classNames({ [classes.disabled]: isReadOnly || !activity.isSelected })}
				onClick={() => {
					openPhaseUnsafeObservationForm(activity.pacId)
				}}
			>
				<Icon>add</Icon>
			</IconButton>
		)
	}
	Observations.propTypes = {
		activity: PropTypes.object,
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('DangerActivityPhases')}
			</Typography>
			<br />
			<div className={classes.dataTableContainer}>
				{!isReadOnly && (
					<Button
						disabled={isReadOnly}
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openPhasesUnsafeOperationsForm}
					>
						{t('add')}
					</Button>
				)}
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.categoryTitle}>{t('companyRecord:phase')}</TableCell>
							<TableCell className={classes.categoryTitle} align="center">
								{t('companyRecord:generatedPhase')}
							</TableCell>
							<TableCell className={classes.categoryTitle} align="center">
								{t('Observation')}
							</TableCell>
							<TableCell className={classes.categoryTitle} align="center">
								{t('isNewFromRevision')}
							</TableCell>
							<TableCell className={classes.categoryTitle} align="center">
								{t('companyRecord:actions')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{listActivities.map((activity, i) => (
							<TableRow key={i}>
								<TableCell component="th" scope="row">
									{activity.displayName}
								</TableCell>
								<TableCell align="center" component="th" scope="row">
									{activity.isSelected !== 'specific' && (
										<ToggleAccountButton
											readOnly={activity.isReadOnly}
											disabled={isReadOnly}
											activityId={activity.pacId}
											mode={activity.isSelected}
										/>
									)}
								</TableCell>
								<TableCell component="th" scope="row">
									<Observations activity={activity}></Observations>
								</TableCell>
								<TableCell component="th" scope="row">
									{activity.isNewFromRevision && (
										<Icon className={classes.iconNewFromRevision}>info_outlines</Icon>
									)}
								</TableCell>
								<TableCell component="th" scope="row">
									{activity.isSelected === 'specific' ? (
										<IconButton
											disabled={isReadOnly}
											className={classNames({ [classes.disabled]: isReadOnly })}
											onClick={() => deletePhasesUnsafeOperations(activity.pacId)}
										>
											<Icon>delete</Icon>
										</IconButton>
									) : (
										<IconButton
											disabled={isReadOnly}
											className={classNames({ [classes.disabled]: isReadOnly })}
											onClick={() => downloadPhase(activity.pacDocumentCheminComplet)}
										>
											<Icon>get_app</Icon>
										</IconButton>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}
PhasesUnsafeOperations.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	queries: PropTypes.object,
	isReadOnly: PropTypes.bool,
	isReviewMode: PropTypes.bool,
	setRevisionCompanyRecordId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	activity: PropTypes.object,
}
export default PhasesUnsafeOperations
