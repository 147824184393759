import React from 'react'

function CGUContentEn(props) {
	return (
		<div>
			<p>
				Welcome to the website{' '}
				<a href="https://rapso-d.totalenergies.com">https://rapso-d.totalenergies.com</a> (the
				“Website"). By connecting to the Website, you acknowledge that you have read, understood and
				accepted without limitation or reservation these General Terms and Conditions of Use and the
				Personal Data Protection Policy. Please note that if you visit other websites of the Total
				group, different general terms and conditions and policies concerning personal data
				protection apply to these websites. It is recommended that you carefully read their legal
				notices.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>1. Identity</h2>
			<p>
				<span style={{ fontWeight: 'bold' }}>
					Company publishing the Website (referred to herein as the “Company”):
				</span>
				<br />
				Total Refining Chemicals
				<br />
				Head office: 2 place Jean Millier – La Défense 6 – 92400 Courbevoie
				<br />
				Company type: Société par Actions Simplifiée
				<br />
				Registered capital: 933 605 625,50 €<br />
				Trade and Companies Register: Nanterre n° 692 004 807
				<br />
				VAT No: FR63692004807
				<br />
				E-mail: rapso-d-admin@totalenergies.com <br />
				Phone number: 33 + 1 47 44 60 00
				<br />
				<br />
				Publication director: Manufacturing & Projects Division
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>
					Technical design and development of the internet site:
				</span>
				<br />
				Business & Decision France, SAS registered capital of 815 200€ whose head office is located
				at <br />
				Coeur Défense A<br />
				110, Esplanade du Général de Gaulle
				<br />
				92931 Paris La Défense Cedex
				<br />
				Listed in the RCS PARIS Trade and Companies Register under No 480893387
				<br />
				Phone number: 0156212121
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>Hosting :</span>
				<br />
				Total Global Information Technology Services, SAS registered capital of 6 577 787 euros,
				whose head office is located at 2 place Jean Millier – La Défense 6 – 92400 Courbevoie
				Listed in the Nanterre Trade and Companies Register under No. 531 680 437 Phone number: 33 +
				1 47 44 60 00
				<br />
				The companies within the Total group have their own legal existence and independent legal
				status. The Company publishing this Website belongs to the Total group. “Total” and “Total
				group” are sometimes used in this Website for convenience where references are generally
				made to TOTAL S.A. (the holding company) and its subsidiaries and affiliates. Likewise, the
				words “we”, “us” and “our” are used to refer to the Company or any company of the Total
				group in general or to those who work for them. These expressions are also used where no
				useful purpose is served by identifying the particular company or companies. It cannot be
				inferred from these expressions that TOTAL S.A. or any of its subsidiaries or affiliates are
				involved in the business and management of any other company of Total group.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>2. Information About Activities</h2>
			<p>
				This Website presents information concerning certain activities of the Company and the Total
				group. The Company may modify the information contained in this Website at any time and
				without prior notice. This information is provided as general information without any
				guarantees that it is fit for any specific purpose.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>3. Intellectual Property</h2>
			<h3 style={{ fontWeight: 'bold' }}>3.1. Company Property</h3>
			<p>
				All information or documents (text, animated or static images, databases, sounds,
				photographs, know-how or cited products) stored in the Website as well as all elements
				created for the Website and its general structure are either the property of the Company or
				the companies within the Total group or are subject to rights to use, duplicate or
				communicate to the public that have been granted to such.
				<br />
				This information, documents and items are subject to laws protecting copyright insofar as
				they have been made available to the public via this Website. No licence or any right other
				than to view the Website has been granted to any party with regard to intellectual property
				rights.
				<br />
				Duplication of Website documents is authorized solely as information for personal and
				private usage. Any duplication or usage of copies made for other purposes is formally
				prohibited and subject to prior and formal authorization by the Company. In all cases,
				authorized duplication of information stored in this Website must cite the source and make
				adequate references as to proprietary.
			</p>
			<h3 style={{ fontWeight: 'bold' }}>3.2. Distinguishing Marks</h3>
			<p>
				Unless otherwise stipulated, company names, logotypes, products and brands quoted on the
				Website are the property of the Company or the companies within the Total group or are
				subject to rights to use, duplicate or represent/communicate to the public that have been
				granted to such. You may not use them without prior written authorization from the Company.
			</p>
			<h3 style={{ fontWeight: 'bold' }}>3.3. Databases</h3>
			<p>
				The Company is the controller of databases on the Website and the Company is the owner of
				any databases made available. You may not extract or reuse a substantial qualitative or
				quantitative portion of the databases, including for private purpose.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>4. Your Undertakings</h2>
			<h3 style={{ fontWeight: 'bold', display: 'inline' }}>4.1. Right to Use Information</h3>
			<p>
				Each visitor of the Website providing information grants the Company all transferable rights
				concerning said information and authorizes the Company to make use of such. Information
				provided by visitors which shall be deemed not confidential. However, the information
				provided is personal data as defined by applicable law, which means we undertake to process
				said information in accordance with{' '}
				<a href="#" onClick={(e) => props.onClick()}>
					our Personal Data Protection Policy
				</a>
				, which applies to this Website.
			</p>
			<h3 style={{ fontWeight: 'bold', display: 'inline' }}>4.2. Compliance with Law</h3>
			<p>
				Each visitor of this Website also certifies that he or she complies with these measures and
				current laws and in particular:
				<br />
				<ul>
					<li>Has the ability and means required to access the Website and use it.</li>
					<li>
						Has verified that the IT configuration used does not contain any virus and that it is in
						full working order.
					</li>
					<li>
						Grants the Company and partners where applicable a right to use the information provided
						(other than data of a personal nature).
					</li>
					<li>
						Must keep confidential and is consequently liable for the use and security of access
						codes and passwords that the Company may send you to access specific sections. The
						Company reserves the right to suspend your access to the Website in the event of
						fraudulent use or attempts at fraudulent use of said access.
					</li>
				</ul>
			</p>
			<h2 style={{ fontWeight: 'bold' }}>5. Hypertext Links</h2>
			<h3 style={{ fontWeight: 'bold' }}>5.1. Activation of links</h3>
			<p>
				The Company formally declines any liability as to the content of websites to which it
				provides links. Said links are offered to users of this Website as a service. Please consult
				the general terms and conditions and the personal data protection policy for the websites in
				order to understand their practice. The decision to use links is made solely by the Website
				users. We may modify or delete a link on this Website at any moment.
			</p>
			<h3 style={{ fontWeight: 'bold' }}>5.2. Link authorization</h3>
			<p>
				If you wish to create a hypertext link to this Website, you must obtain prior written
				authorization from the Company using the contact details provided at the end of this
				document.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>6. Formal Notice</h2>
			<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.1. Information</h3>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.1</span> The information
				(“Information”) available on this www (World Wide Web) server is provided in good faith.
			</p>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.2</span> This Information is
				considered correct when it is published on the Website. However, the Company neither
				represents nor guarantees that the Information is comprehensive or accurate. You bear all
				the risks arising from your reliance on the Information. The Information is provided on the
				condition that you or any other person receiving it can determine its interest for a
				specific purpose before use. Under no circumstances will the Company accept liability for
				injury arising from reliance on the said Information, its use or use of a product to which
				it refers.
			</p>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.3</span> The Information shall
				not be deemed a recommendation to use information, products, procedures, equipment or
				formulae contravening a patent, copyright or registered trade mark. The Company declines any
				explicit or implicit liability in the event that use of the Information contravenes a
				patent, copyright or registered trade mark.
			</p>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.4</span> The Company and all
				directly or indirectly owned subsidiaries or affiliates and any company within the Total
				group categorically rejects any interpretation which may assimilate the content of its
				websites to purchase offers or incitements to acquire shares or other listed or unlisted
				negotiable securities.
			</p>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.5</span> No explicit or implicit
				guarantee is given regarding the commercial nature of the Information provided or its
				suitability for a given purpose as well as the products to which said Information refers to.
			</p>
			<p>
				<span style={{ fontWeight: 'bold', display: 'inline' }}>6.1.6</span> Under no circumstances
				does the Company undertake to update or correct the Information disseminated on the Internet
				or on its web servers. Similarly, the Company reserves the right to amend or correct the
				content of its Website at any time without prior notification.
			</p>
			<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.2. Forward-Looking Statements</h3>
			<p>
				The Information presented in this Website may contain forward-looking statements concerning
				the financial position, operating results, activities and industrial strategy of the
				Company, TOTAL S.A. and/or companies of the Total group. Such statements are subject to
				risks and uncertainties. These statements are based on the beliefs and assumptions of
				management and on the information currently available to management. Forward-looking
				statements include information concerning forecasts, projections, anticipated synergies, and
				other information concerning possible or assumed future results and may be preceded by,
				followed by, or otherwise include the words “believes”, “expects”, “anticipates”, “intends”,
				“plans”, “targets”, “estimates” or similar expressions. Forward-looking statements are not
				assurances of results or values. They involve risks, uncertainties and assumptions. Future
				results may differ materially from those expressed in these forward-looking statements. Many
				of the factors that will determine these results and values are beyond the Company’s ability
				to control or predict.
				<br />
				The documents included in this website may contain forward-looking information on the Group
				(including objectives and trends), as well as forward-looking statements within the meaning
				of the Private Securities Litigation Reform Act of 1995, notably with respect to the
				financial condition, results of operations, business, strategy and plans of TOTAL. These
				data do not represent forecasts within the meaning of European Regulation No. 809/2004. Such
				forward-looking information and statements included in these documents are based on a number
				of economic data and assumptions made in a given economic, competitive and regulatory
				environment. They may prove to be inaccurate in the future, and are subject to a number of
				risk factors that could lead to a significant difference between actual results and those
				anticipated, including currency fluctuations, the price of petroleum products, the ability
				to realize cost reductions and operating efficiencies without unduly disrupting business
				operations, environmental regulatory considerations and general economic and business
				conditions. Certain financial information is based on estimates particularly in the
				assessment of the recoverable value of assets and potential impairments of assets relating
				thereto. Neither TOTAL nor any of its subsidiaries assumes any obligation to update publicly
				any forward-looking information or statement, objectives or trends contained in these
				documents whether as a result of new information, future events or otherwise. Further
				information on factors, risks and uncertainties that could affect the Company’s financial
				results or the Group’s activities is provided in the most recent Registration Document, the
				French language version of which is filed by the Company with the French Autorité des
				Marchés Financiers and annual report on Form 20-F filed with the United States Securities
				and Exchange Commission (“SEC”).
			</p>
			<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.3. Availability</h3>
			<p>
				The Company does not guarantee that the Website will operate without interruption or that
				the servers ensuring access to it operate and/or the third party sites to which hypertext
				links refer do not contain viruses.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>
				7. Updates of terms and conditions of use of the Website and applicable law
			</h2>
			<p>
				The Company may update these Conditions of Use of the Website at any time. Consequently, you
				are invited to regularly refer to the latest Conditions of Use in effect. These general
				conditions of use of the Website are subject to applicable laws.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>8. Contact Details</h2>
			<p>
				You may direct all questions concerning conditions of use of the Website by e-mail to{' '}
				<a href="mailto:rapso-d-admin@totalenergies.com">rapso-d-admin@totalenergies.com</a> or by
				post to the following address:
				<br />
				<br />
				Total Raffinage Chimie
				<br />
				Direction industrielle
				<br />2 place Jean Millier – La Défense 6 – 92400 Courbevoie
			</p>
		</div>
	)
}

export default CGUContentEn
