import React from 'react'
import { MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		backgroundColor: '#3268A7 !important',
		'&:hover': {
			color: '#3268A7',
			backgroundColor: theme.palette.primary.main + '!important',
		},
	},
	selected: {
		color: '#3268A7',
		backgroundColor: theme.palette.primary.main + '!important',
	},
}))

export default function SelectMenuItem({ children, ...props }) {
	const classes = useStyles()
	return (
		<MenuItem {...props} classes={{ root: classes.root, selected: classes.selected }}>
			{children}
		</MenuItem>
	)
}
