import axios from 'axios'
import * as link from '../constants/url'
import { RequestFactory } from './RequestContainer'
import { store } from '../store/store'

const routes = {
	DIFFUSION: '/diffusion/',
	REVISION: '/revision/',
	COMPANIES: '/companies/',
	CONTACTS: '/contacts/',
	COMMUNICATION: '/communication/',
	QUERY_LANG_ID: '?p_LangId=',
	QUERY_PLATFORM_ID: '?p_PlatformId=',
}

const callbackRequest = (reqPromise, callback) => {
	reqPromise
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export const axiosHeaders = (headers = { pragma: false, contentType: '' }) => {
	const token = store.getState().token.token
	const authorization = { Authorization: `Bearer ${token}` }
	const p = headers.pragma ? { Pragma: 'no-cache' } : undefined
	const ct = headers.contentType ? { ContentType: headers.contentType } : undefined
	return {
		...authorization,
		...p,
		...ct,
	}
}

const basicOptions = (typeResponse) => {
	return {
		headers: axiosHeaders({ pragma: true, contentType: 'application/json-patch+json' }),
		responseType: typeResponse ? typeResponse : 'json',
	}
}

const formDataOptions = (typeResponse) => {
	return {
		headers: axiosHeaders({ pragma: true, contentType: 'multipart/form-data' }),
	}
}

export function getPlatformListByAccountId(id, callback) {
	let basicOpts = {
		...basicOptions(),
	}
	let options = !id ? basicOpts : { ...basicOpts, params: { p_AccountId: id } }
	let req = RequestFactory.createAxiosRequest('GET', link.GETPLATFORMS, undefined, options)
	callbackRequest(req.promise, callback)
}

export function getContactsByAccountId(id, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.CONTACTSLIST + id + '/contacts',
		undefined,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function upsertCompanyContacts(contactData, id, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.CONTACTSLIST + id + '/contacts',
		contactData,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function deleteCompanyContacts(id, idContact, callback) {
	let req = RequestFactory.createAxiosRequest(
		'DELETE',
		link.CONTACTSLIST + id + routes.CONTACTS + idContact,
		undefined,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function getDWDCorrespondentsByAccountId(platformId, id, callback) {
	let req = RequestFactory.createAxiosRequest('GET', link.DWPCORRESPONDENTS + id, undefined, {
		...basicOptions(),
		params: { p_PlatformId: platformId },
	})
	callbackRequest(req.promise, callback)
}

export function createDWPCorrespondent(correspondentData, id, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.DWPCORRESPONDENTS + id + '/add-correspondent',
		correspondentData,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function isNotifiablePdP(platformId, pdpId, pdpRevision, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS +
			platformId +
			routes.DIFFUSION +
			pdpId +
			routes.REVISION +
			pdpRevision +
			'/is-diffuseable',
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getPDPDocuments(platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + '/documents/' + pdpId + routes.REVISION + revisionNumber,
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getDiffusionStats(platformId, pdpId, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + routes.DIFFUSION + pdpId + '/stats',
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getPdPWorkflows(platformId, langId, pdpType, isSpecific, callback) {
	let req = RequestFactory.createAxiosRequest('GET', link.PDPWORKFLOW, undefined, {
		...basicOptions(),
		params: {
			p_PlatformId: platformId,
			p_LangId: langId,
			p_PdPType: pdpType,
			p_DedicatedUser: isSpecific,
		},
	})
	callbackRequest(req.promise, callback)
}

export function getEligibleCompanies(platformId, pdpId, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + routes.COMPANIES + pdpId + '/eligible-companies',
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function addCompanyToPdPRestricted(body, platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS +
			platformId +
			'/company/' +
			pdpId +
			'/add-company-restricted-pdp/revision/' +
			revisionNumber,
		body,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function addMultiplesCompaniesToPdP(body, platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS +
			platformId +
			routes.COMPANIES +
			pdpId +
			'/add-companies/revision/' +
			revisionNumber,
		body,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function getPdPStats(platformId, pdpId, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + routes.COMPANIES + pdpId + '/stats',
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function validatePdP(platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + '/validate/' + pdpId + routes.REVISION + revisionNumber,
		undefined,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function pdpList(platformId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + '/pdp-list',
		body,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function copyPdP(platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + '/copy-pdp/' + pdpId + routes.REVISION + revisionNumber,
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function deletePdP(platformId, pdpId, callback) {
	let req = RequestFactory.createAxiosRequest(
		'DELETE',
		link.PDPINFORMATIONS + platformId + '/pdp/' + pdpId,
		undefined,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function getPdPDraftListCompanies(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONSDRAFT + platformId + routes.COMPANIES + pdpId,
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getCommunicationPdPHistory(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + routes.COMMUNICATION + pdpId + '/history',
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getDiffusionPdPHistory(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + routes.DIFFUSION + pdpId + '/history',
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function sendDiffusion(platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS +
			platformId +
			routes.DIFFUSION +
			pdpId +
			routes.REVISION +
			revisionNumber +
			'/send-diffusion',
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getPdPCompanyDetails(platformId, pdpId, companyID, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS + platformId + routes.COMPANIES + pdpId + '/company-details/' + companyID,
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function updateCompanyPdPNonDraft(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'PUT',
		link.PDPINFORMATIONS + platformId + routes.COMPANIES + pdpId + '/update-company',
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function deleteDWPCorrespondent(idUser, idCompany, callback) {
	axios({
		method: 'DELETE',
		url: link.DWPCORRESPONDENTS + idCompany + '/delete-correspondent/' + idUser,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getOperationalInformationsByAccountId(filters, id, callback) {
	axios({
		method: 'GET',
		url: link.OPERATIONALINFOS + id,
		params: {
			p_Filters: filters,
		},
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getEnvironmentalAspectByAccountId(id, callback) {
	axios({
		method: 'GET',
		url: link.ENVIRONMENTALASPECTS + id,
		data: undefined,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function GetAttachments(id, callback) {
	axios({
		method: 'GET',
		url: link.ATTACHMENT + id,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function askAttachment(data, id, callback) {
	axios({
		method: 'POST',
		url: link.ATTACHMENT + id,
		data: data,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export async function recoverAccountList(data, callback) {
	axios({
		method: 'POST',
		url: link.GETACCOUNTLIST,
		data: data,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function recoverOperationalInfos(filters, id, callback) {
	axios({
		method: 'GET',
		url: link.OPERATIONALINFOS + id,
		params: {
			p_Filters: filters,
		},
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getAccountInfosById(id, callback) {
	if (id) {
		let req = RequestFactory.createAxiosRequest(
			'GET',
			link.ACCOUNTINFOS + id,
			undefined,
			basicOptions(),
		).promise
		callbackRequest(req, callback)
	} else window.location.reload()
}

export function updateAccountInfos(data, callback) {
	axios({
		method: 'POST',
		url: link.ACCOUNTINFOS,
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function createAccount(data, callback) {
	axios({
		method: 'POST',
		url: link.CREATEACCOUNT,
		data: data,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function uploadEnvironmentalAspectFile(data, companyID, callback) {
	axios({
		method: 'POST',
		url: link.ENVIRONMENTALASPECTS + companyID + '/add-file',
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getEnvironmentalAspects(companyID, callback) {
	axios({
		method: 'GET',
		url: link.ENVIRONMENTALASPECTS + companyID,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function downloadFile(path, callback) {
	axios({
		method: 'GET',
		url: `${link.DOWNLOAD_FILE}?filepath=${path}`,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'arraybuffer',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function changeStateOpeningRequest(data, callback) {
	axios({
		method: 'POST',
		url: link.CHANGE_STATE_OPENING_REQUESTS,
		data: data,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getAccountTypes({ langId, includeCompany = true }, callback) {
	axios({
		method: 'GET',
		url:
			link.GETACCOUNTTYPES + routes.QUERY_LANG_ID + langId + '&p_IncludeCompany=' + includeCompany,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getContactTypeList(langId, callback) {
	axios({
		method: 'GET',
		url: link.CONTACTSTYPE,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_LangId: langId,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function toggleAccountState({ platformId, accountId }, callback) {
	let tempLink = link.DEACTIVATE_ACCOUNT.replace(/[{@}]{3}/g, platformId)
	axios({
		method: 'PUT',
		url: tempLink + accountId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function toggleDO({ platformId, oldId, newId }, callback) {
	axios({
		method: 'PUT',
		url: link.PDPINFORMATIONS + platformId + '/account/substitute/' + oldId + '/new/' + newId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function updateOperationalInfos(id, data, callback) {
	axios({
		method: 'POST',
		url: link.OPERATIONALINFOS + id,
		data: data,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getJobList(langID, callback) {
	axios({
		method: 'GET',
		url: link.JOBS,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_LangId: langID,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function validateAccountExternalCompany(data, callback) {
	axios({
		method: 'POST',
		url: link.VALIDATEACCOUNTEXTERNALCOMPANY,
		data: data,
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function validateAccountUser(data, callback) {
	axios({
		method: 'POST',
		url: link.VALIDATEACCOUNTUSER,
		data: data,
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function setPermitFlag(flag, platformID, companyID, callback) {
	axios({
		method: 'POST',
		url: link.DWPCORRESPONDENTS + companyID + '/set-permit-flag/' + platformID,
		params: {
			p_Flag: flag,
		},
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getChemicalProducts(companyId, data, callback) {
	let tempLink = link.CHEMICAL_PRODUCTS.replace(/[{@}]{3}/g, companyId)
	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getChemicalProduct({ productId, companyId }, callback) {
	let tempLink = link.CHEMICAL_PRODUCTS.replace(/[{@}]{3}/g, companyId)
	axios({
		method: 'GET',
		url: tempLink + productId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function deleteChemicalProduct({ productId, companyId }, callback) {
	let tempLink = link.CHEMICAL_PRODUCTS.replace(/[{@}]{3}/g, companyId)
	axios({
		method: 'DELETE',
		url: tempLink + productId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function upsertChemicalProduct({ companyId, data }, callback) {
	let tempLink = link.CHEMICAL_PRODUCTS.replace(/[{@}]{3}/g, companyId)
	axios({
		method: 'POST',
		url: tempLink + 'upsert',
		data: data,
		headers: axiosHeaders({ pragma: true, contentType: 'multipart/form-data;' }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getDangerPhrases(langId, callback) {
	axios({
		method: 'GET',
		url: link.DANGER_PHRASES + routes.QUERY_LANG_ID + langId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function setPdPInformations(platformId, data, callback) {
	axios({
		method: 'POST',
		url: link.PDPINFORMATIONS + platformId + '/general-infos/',
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getPdPInformations(platformId, pdpId, revisionNumber, callback) {
	axios({
		method: 'GET',
		url:
			link.PDPINFORMATIONS +
			platformId +
			'/general-infos/' +
			pdpId +
			routes.REVISION +
			revisionNumber,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getPdPNumberSuggestion(platformId, pdpType, pdpYear, callback) {
	axios({
		method: 'GET',
		url: link.PDPSUGGESTION,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_TypePdP: pdpType,
			p_PlatformId: platformId,
			p_Year: pdpYear,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getAccountByTypePlatform(platformId, accountType, callback) {
	axios({
		method: 'GET',
		url: link.ACCOUNTBYTYPEPLATFORM,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_PlatformId: platformId,
			p_AccountType: accountType,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getSectors(platformId, callback) {
	axios({
		method: 'GET',
		url: link.SECTORS,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_PlatformId: platformId,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getOperationAreas(platformId, pdpId, revisionNumber, callback) {
	axios({
		method: 'GET',
		url:
			link.PDPINFORMATIONS +
			platformId +
			'/operation-areas/' +
			pdpId +
			routes.REVISION +
			revisionNumber,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function updateOperationAreas(platformId, data, pdpId, revisionNumber, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS +
			platformId +
			'/operation-areas/' +
			pdpId +
			routes.REVISION +
			revisionNumber,
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getPdPAllContacts(platformId, callback) {
	axios({
		method: 'GET',
		url: link.PDPCONTACTS + routes.QUERY_PLATFORM_ID + platformId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getPdPContacts(platformId, pdpId, revisionNumber, callback) {
	axios({
		method: 'GET',
		url:
			link.PDPINFORMATIONS +
			platformId +
			routes.CONTACTS +
			pdpId +
			routes.REVISION +
			revisionNumber,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function addPdPContactWithId(platformId, data, pdpId, revisionNumber, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS +
			platformId +
			routes.CONTACTS +
			pdpId +
			routes.REVISION +
			revisionNumber,
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getEntities(platformId, langId, callback) {
	axios({
		method: 'GET',
		url: link.ENTITIES,
		headers: axiosHeaders({ pragma: true }),
		params: {
			p_PlatformId: platformId,
			p_LangId: langId,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function createPdPContact(platformId, data, pdpId, revisionNumber, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS +
			platformId +
			routes.CONTACTS +
			pdpId +
			routes.REVISION +
			revisionNumber +
			'/upsert-contact',
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function deletePdPContact(platformId, pdpId, contactId, callback) {
	axios({
		method: 'DELETE',
		url:
			link.PDPINFORMATIONS + platformId + routes.CONTACTS + pdpId + '/delete-contact/' + contactId,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function uploadPdpDocument(platformId, data, pdpId, revisionNumber, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS + platformId + '/documents/' + pdpId + routes.REVISION + revisionNumber,
		data: data,
		headers: axiosHeaders({ pragma: true, contentType: 'multipart/form-data;' }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function sendPdPConvocation(platformId, data, pdpId, revisionId, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS +
			platformId +
			routes.COMMUNICATION +
			pdpId +
			routes.REVISION +
			revisionId +
			'/send-convocation',
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function sendPdPNotification(platformId, data, pdpId, revisionId, callback) {
	axios({
		method: 'POST',
		url:
			link.PDPINFORMATIONS +
			platformId +
			routes.COMMUNICATION +
			pdpId +
			routes.REVISION +
			revisionId +
			'/send-notification',
		data: data,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getChevronsStates(platformId, pdpId, revisionNumber, callback) {
	axios({
		method: 'GET',
		url:
			link.PDPINFORMATIONS +
			platformId +
			'/chevrons-state/' +
			pdpId +
			routes.REVISION +
			revisionNumber,
		headers: axiosHeaders({ pragma: true }),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function recoverCompaniesList(platformId, pdpId, data, callback) {
	let tempLink = link.GETCOMPANIESPDP.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink + pdpId, data, basicOptions())
	callbackRequest(req.promise, callback)
}

export function deleteCompany(platformId, pdpId, companyId, data, callback) {
	let tempLink = link.DELETECOMPANIESPDP.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, pdpId)

	let req = RequestFactory.createAxiosRequest('DELETE', tempLink + companyId, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function updateCompany(platformId, pdpId, data, callback) {
	let tempLink = link.UPDATECOMPANIESPDP.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, pdpId)

	let req = RequestFactory.createAxiosRequest('PUT', tempLink, data, basicOptions())
	callbackRequest(req.promise, callback)
}

export function getChemicalProductsList(companyId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.CHEMICAL_PRODUCTS.replace(/[{@}]{3}/g, companyId),
		body,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function openingRequestsList(body, callback) {
	let req = RequestFactory.createAxiosRequest('POST', link.OPENING_REQUESTS, body, basicOptions())
	callbackRequest(req.promise, callback)
}

export function updateMassiveAction(platformId, pdpId, data, callback) {
	let tempLink = link.MASSIVEACTION.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, pdpId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, basicOptions())

	callbackRequest(req.promise, callback)
}

export function getPdPCompanyResponsable(platformId, pdpId, companyID, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONSDRAFT +
			platformId +
			routes.COMPANIES +
			pdpId +
			'/get-resp-company/' +
			companyID,
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function updatePdPCompanyResponsable(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'PUT',
		link.PDPINFORMATIONSDRAFT + platformId + routes.COMPANIES + pdpId + '/update-company',
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function uploadOperationnalModes(companyId, data, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.CONTACTSLIST + companyId + '/operational-modes',
		data,
		{
			headers: axiosHeaders({ pragma: true, contentType: 'multipart/form-data;' }),
		},
	)
	callbackRequest(req.promise, callback)
}

export function getOperationnalModes(companyId, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.CONTACTSLIST + companyId + '/operational-modes',
		undefined,
		basicOptions(),
	)
	callbackRequest(req.promise, callback)
}

export function getPdPEligibleCompaniesRestrictedPdP(platformId, pdpId, revisionNumber, callback) {
	let req = RequestFactory.createAxiosRequest(
		'GET',
		link.PDPINFORMATIONS +
			platformId +
			'/restricted-pdp/' +
			pdpId +
			'/eligible-companies/' +
			revisionNumber,
		undefined,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}

export function getCompanyManagers(companyId, callback) {
	let req = RequestFactory.createAxiosRequest('GET', link.COMPANYMANAGER, undefined, {
		...basicOptions(),
		params: { p_CompanyId: companyId },
	})
	callbackRequest(req.promise, callback)
}

export function addCompanyManager(platformId, pdpId, companyId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS +
			platformId +
			routes.COMPANIES +
			pdpId +
			'/add-company-manager/' +
			companyId,
		body,
		{
			...basicOptions(),
		},
	)
	callbackRequest(req.promise, callback)
}

export function addCompanyManagerRestrictedPdP(platformId, companyId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + '/restricted-pdp/add-company-manager/' + companyId,
		body,
		{
			...basicOptions(),
		},
	)
	callbackRequest(req.promise, callback)
}

export function setPdPInfosResttrictedPdP(platformId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'POST',
		link.PDPINFORMATIONS + platformId + '/restricted-pdp/general-infos',
		body,
		{
			...basicOptions(),
		},
	)
	callbackRequest(req.promise, callback)
}

export function sendPdp(platformId, pdpId, revisionNumber, callback) {
	let tempLink = link.SENDTODWP.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, pdpId)
	tempLink = tempLink.replace(/[{@3}]{4}/g, revisionNumber)

	let req = RequestFactory.createAxiosRequest('GET', tempLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function recoverSubcontractorCompanies(platformId, companyRecordId, callback) {
	let tempLink = link.SUBCONTRACTORCOMPANIES.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tempLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function createSubcontractorCompanies(platformId, companyRecordId, data, callback) {
	let tempLink = link.SUBCONTRACTORCOMPANIES.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, {
		...basicOptions(),
	})

	callbackRequest(req.promise, callback)
}

export function deleteSubcontractorCompanies(platformId, companyRecordId, companyId, callback) {
	let tempLink = link.SUBCONTRACTORCOMPANIES.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('DELETE', tempLink, companyId, {
		...basicOptions(),
	})

	callbackRequest(req.promise, callback)
}

export function upsertCompanyRecordOperationalMode(platformId, companyRecordId, data, callback) {
	let tempLink = link.OPERATIONALMODE.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, formDataOptions())
	callbackRequest(req.promise, callback)
}

export function getCompanyRecordOperationalMode(platformId, companyRecordId, callback) {
	let tempLink = link.OPERATIONALMODE.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tempLink, undefined, basicOptions())
	callbackRequest(req.promise, callback)
}

export function getHygieneData(platformId, companyRecordId, callback) {
	let tempLink = link.HYGIENE.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tempLink, undefined, basicOptions())
	callbackRequest(req.promise, callback)
}

export function upsertHygieneSurveillancePoint(platformId, companyRecordId, data, callback) {
	let tempLink = link.HYGIENESURVEILLANCEPOINT.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, basicOptions())
	callbackRequest(req.promise, callback)
}

export function upsertSpecificActivity(platformId, companyRecordId, data, callback) {
	let tempLink = link.SPECIFICACTIVITYPHASES.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, basicOptions())
	callbackRequest(req.promise, callback)
}

export function AddHygieneContact(platformId, companyRecordId, data, callback) {
	let tempLink = link.HYGIENECONTACT.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tempLink, data, basicOptions())
	callbackRequest(req.promise, callback)
}

export function deleteHygieneSurveillancePoint(
	platformId,
	companyRecordId,
	hygieneSurveillanceId,
	callback,
) {
	let tempLink = link.HYGIENESURVEILLANCEPOINT.replace(/[{@1}]{4}/g, platformId)
	tempLink = tempLink.replace(/[{@2}]{4}/g, companyRecordId) + '/' + hygieneSurveillanceId

	let req = RequestFactory.createAxiosRequest('DELETE', tempLink, undefined, basicOptions())
	callbackRequest(req.promise, callback)
}

export function getActivityPhases(platformId, companyRecordId, callback) {
	let tmpLink = link.ACTIVITYPHASES.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function toggleActivityPhaseState(platformId, companyRecordId, activityPhaseId, callback) {
	let tmpLink = link.ACTIVITYPHASES.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink + '/' + activityPhaseId

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteActivityPhases(platformId, companyRecordId, activityPhaseId, callback) {
	let tmpLink = link.SPECIFICACTIVITYPHASES.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink + '/' + activityPhaseId

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function upsertActivityPhaseObservation(
	platformId,
	companyRecordId,
	activityPhaseId,
	Observation,
	callback,
) {
	let tmpLink = link.ACTIVITYPHASESOBSERVATION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, activityPhaseId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, Observation, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteActivityPhaseObservation(
	platformId,
	companyRecordId,
	activityPhaseId,
	callback,
) {
	let tmpLink = link.ACTIVITYPHASESOBSERVATION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, activityPhaseId)

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export async function getOperationDescription(platformId, _companyId, companyRecordId, callback) {
	let tmpLink = link.OPERATIONDESCRIPTION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	axios({
		method: 'GET',
		url: tmpLink,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function recoverChemicalProduct(platformId, companyRecordId, chemicalProductId, callback) {
	let tmpLink = link.CHEMICALPRODUCTS.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = `${tmpLink}/${chemicalProductId}`

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}
export function getCompanyCertifications(platformId, companyId, callback) {
	let tmpLink = link.GETCERTIFICATIONS.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function upsertChemicalProductById(
	platformId,
	companyRecordId,
	chemicalProductId,
	data,
	callback,
) {
	let tmpLink = link.CHEMICALPRODUCTS.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = `${tmpLink}/${chemicalProductId}`

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyRecordList(platformId, data, callback) {
	let tmpLink = link.COMPANYRECORDLIST.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function upsertCompanyRecord(platformId, companyId, body, callback) {
	let tmpLink = link.POSTOPERATIONDESCRIPTION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, body, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function updateCse(platformId, companyId, body, callback) {
	let tmpLink = link.HYGIENE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, body, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCse(platformId, companyId, callback) {
	let tmpLink = link.HYGIENE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getNewCompanyRecordList(platformId, companyId, callback) {
	let tmpLink = link.NEWCOMPANYRECORDLIST.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getDEChevronsStates(platformId, companyRecordId, callback) {
	let tmpLink = link.CHEVRONSSTATE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getSubcontractorCompanies(platformId, companyRecordId, callback) {
	let tmpLink = link.NEWSUBCONTRACTORCOMPANIES.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyRecordById(platformId, companyRecordId, callback) {
	let tmpLink = link.COMPANYRECORD.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function confirmCompanyRecordById(platformId, companyRecordId, data, callback) {
	let tmpLink = link.COMPANYRECORD.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function submitCompanyRecord(platformId, companyRecordId, callback) {
	let tmpLink = link.COMPANYRECORDSUBMIT.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions('arraybuffer'),
	})
	callbackRequest(req.promise, callback)
}

export function getWorkflowValidationById(platformId, companyRecordId, callback) {
	let tmpLink = link.CSTATEVALIDATION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function confirmSubmitCompanyRecord(platformId, companyRecordId, callback) {
	let tmpLink = link.COMPANYRECORDCONFIRMSUBMIT.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function sendApprobation(platformId, companyRecordId, data, callback) {
	let tmpLink = link.SENDAPPROBATION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function sendValidation(p_Link, platformId, companyRecordId, bool, callback) {
	let tmpLink = p_Link.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, bool, {
		...formDataOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyPrincipalForSubcontractor(platformId, companyRecordId, pdpId, callback) {
	let tmpLink = link.PRINCIPALCOMPANIESLIST.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, pdpId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}
export function sendOPMValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_OPM, platformId, companyRecordId, bool, callback)
}
export function sendSBCValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_SBC, platformId, companyRecordId, bool, callback)
}
export function sendHAHValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_HAH, platformId, companyRecordId, bool, callback)
}
export function sendACPValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_ACP, platformId, companyRecordId, bool, callback)
}
export function sendCHPValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_CHP, platformId, companyRecordId, bool, callback)
}
export function sendEVAValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_EVA, platformId, companyRecordId, bool, callback)
}
export function sendOPDValidation(platformId, companyRecordId, bool, callback) {
	sendValidation(link.VALIDATION_OPD, platformId, companyRecordId, bool, callback)
}

export function setCompanyCertifications(platformId, companyId, data, callback) {
	let tmpLink = link.GETCERTIFICATIONS.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyRecordApprobation(platformId, data, callback) {
	let tmpLink = link.APPROBATION_CRL.replace(/[{@1}]{4}/g, platformId)
	let req = RequestFactory.createAxiosRequest('POST', tmpLink, data, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getDEEnvironmentalAspect(platformId, companyRecordId, callback) {
	let tmpLink = link.ENVIRONMENTALASPECTSDE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function setDEEnvironmentalAspect(platformId, companyRecordId, formData, callback) {
	let tmpLink = link.ENVIRONMENTALASPECTSDE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, formData, {
		headers: axiosHeaders({ pragma: true, contentType: 'multipart/form-data;' }),
	})

	callbackRequest(req.promise, callback)
}

export function deleteHygieneContact(platformId, companyRecordId, hygieneContactId, callback) {
	let tmpLink = link.DELETE_HC.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyRecordId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, hygieneContactId)
	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteEnvironmentalAspect(companyId, path, callback) {
	let tmpLink = link.DELETEENVIRONMENTALASPECTS.replace(/[{@1}]{4}/g, companyId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, path)

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteOperationalMode(companyId, path, callback) {
	let tmpLink = link.DELETEOPERATIONALMODE.replace(/[{@1}]{4}/g, companyId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, path)

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyByPdp(platformId, callback) {
	let tmpLink = link.PDPBYPLATFORM + routes.QUERY_PLATFORM_ID + platformId

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCompanyByPtf(platformId, callback) {
	let tmpLink = link.NOTIFICATIONCOMPANIES + routes.QUERY_PLATFORM_ID + platformId

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function setUpdateMassNotification(platformId, formData, callback) {
	let tmpLink = link.MASSNOTIFICATION.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, formData, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getDEHistorization(platformId, formData, callback) {
	let tmpLink = link.DEHISTORY.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, formData, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getListMassNotifications(platformId, callback) {
	let tmpLink = link.MASSNOTIFICATIONLIST.replace(/[{@1}]{4}/g, platformId)
	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}
export function getMeasureType(langId, callback) {
	let tmpLink = link.MEASURE_TYPES + routes.QUERY_LANG_ID + langId

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteNotif(platformId, notifID, callback) {
	let tmpLink = link.MASSNOTIFICATION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink + '/' + notifID

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteOtherDocument(companyId, path, callback) {
	let tmpLink = link.OTHERDOCUMENT.replace(/[{@1}]{4}/g, companyId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, path)

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getotherDocuments(companyId, callback) {
	let tmpLink = link.OTHERDOCUMENTGET.replace(/[{@1}]{4}/g, companyId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getCountSentToDwp(platformId, pdpId, revisionNumber, callback) {
	let tmpLink = link.COUNT_TO_SENT_DWP.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, pdpId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, revisionNumber)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function getRestrictedPdPDuration(platformId, callback) {
	let req = RequestFactory.createAxiosRequest('GET', link.RESTRICTEDPDPDURATION, undefined, {
		...basicOptions(),
		params: { p_PlatformId: platformId },
	})
	callbackRequest(req.promise, callback)
}

export async function getOperationDescriptionInit(
	platformId,
	companyId,
	pdpId,
	pdpRevisionId,
	callback,
) {
	let tmpLink = link.INIT_OPERATION_DESCRIPTION.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, companyId)
	tmpLink = tmpLink.replace(/[{@3}]{4}/g, pdpId)
	tmpLink = tmpLink.replace(/[{@4}]{4}/g, pdpRevisionId)

	axios({
		method: 'GET',
		url: tmpLink,
		headers: axiosHeaders(),
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function getCompanyRecordRefusalMessage(platformId, callback) {
	let tmpLink = link.REFUSAL_MESSAGE.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('GET', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function updateCompanyRecordRefusalMessage(platformId, body, callback) {
	let tmpLink = link.REFUSAL_MESSAGE.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('PUT', tmpLink, body, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function deleteCompanyRecordRefusalMessage(platformId, itemId, callback) {
	let tmpLink = link.REFUSAL_MESSAGE_DELETE.replace(/[{@1}]{4}/g, platformId)
	tmpLink = tmpLink.replace(/[{@2}]{4}/g, itemId)

	let req = RequestFactory.createAxiosRequest('DELETE', tmpLink, undefined, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function addCompanyRecordRefusalMessage(platformId, body, callback) {
	let tmpLink = link.REFUSAL_MESSAGE.replace(/[{@1}]{4}/g, platformId)

	let req = RequestFactory.createAxiosRequest('POST', tmpLink, body, {
		...basicOptions(),
	})
	callbackRequest(req.promise, callback)
}

export function updateUserLastConnectionDate(selectedPlatform, callback) {
	let req = RequestFactory.createAxiosRequest('GET', link.TOKEN, undefined, {
		...basicOptions(),
		params: { p_PlatformId: selectedPlatform },
	})
	callbackRequest(req.promise, callback)
}

export function updatePdPCompanyPrincipal(platformId, pdpId, body, callback) {
	let req = RequestFactory.createAxiosRequest(
		'PUT',
		link.PDPINFORMATIONSDRAFT + platformId + routes.COMPANIES + pdpId + '/update-companyPrincipal',
		body,
		{ ...basicOptions() },
	)
	callbackRequest(req.promise, callback)
}
