import { Slide, Typography } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import FolderIcon from '@material-ui/icons/Folder'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import DescriptionIcon from '@material-ui/icons/Description'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SecurityIcon from '@material-ui/icons/Security'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import {
	PLATFORM_ID_COOKIE,
	SHAREPOINT_URL_LIVE_COOKIE,
	SHAREPOINT_URL_SANDBOX_COOKIE,
	USER_OBJECTID,
	DEFAULT_COOKIE_OPTIONS,
} from '../../constants/cookies'
import ROLES, { ALL_ROLES } from '../../constants/roles'
import Access from '../../containers/RoleContainer'
import { MenuContext } from '../../contexts/MenuContext'
import './TotalToolBar.css'
import { DOC_URL } from '../../constants/url'
import SettingsIcon from '@material-ui/icons/Settings'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'
import CommentIcon from '@material-ui/icons/Comment'
import { DocumentationUserAuthentication } from '../../containers/AuthContainer'
import Cookies from 'js-cookie'
import Icon from '@mdi/react'
import { mdiFactory } from '@mdi/js'

// EXPORTS
export const drawerWidth = 240

// STYLES
const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing.unit * 7 + 1,
	},
	paper: {
		backgroundColor: '#323232 !important',
		boxShadow: theme.shadows[5],
		border: 'none',
	},
	toolbar: {
		...theme.mixins.toolbar,
	},
	topList: {
		backgroundColor: theme.palette.primary.main + ' !important',
	},
	selectedItem: {
		backgroundColor: theme.palette.secondary.main + ' !important',
		fill: theme.palette.primary.main + ' !important',
		color: theme.palette.primary.main + ' !important',
	},
	selectedIcon: {
		fill: theme.palette.primary.main + ' !important',
		color: theme.palette.primary.main + ' !important',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	verticalAlign: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-40%)',
	},
	merge: {
		left: theme.spacing.unit,
	},
	submenuContainer: {
		position: 'fixed',
		width: 250,
		color: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		cursor: 'pointer',
		zIndex: 0,
	},
	submenuHeader: {
		position: 'relative',
		backgroundColor: theme.palette.secondary.main,
		width: '100%',
		height: 46,
	},
	arrowIcon: {
		right: theme.spacing.unit,
	},
	submenuList: {
		width: '100%',
		backgroundColor: theme.palette.primary.main,
	},
	submenu: {
		position: 'relative',
		height: 46,
		maxWidth: '100%',
		whiteSpace: 'pre-wrap',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			transition: 'background-color 0.3s',
		},
	},
	revertColor: {
		color: theme.palette.primary.main,
	},
}))

function TotalToolBar() {
	// STYLES
	const classes = useStyles()
	const [cookies] = useCookies([
		PLATFORM_ID_COOKIE,
		SHAREPOINT_URL_LIVE_COOKIE,
		SHAREPOINT_URL_SANDBOX_COOKIE,
	])
	// STATES
	const [selected, setSelected] = useState(null)

	useEffect(() => {
		setSelected(null)
	}, [window.location.href])

	// CONTEXTS
	const { open } = useContext(MenuContext)

	// EVENTS

	useEffect(() => {
		setSelected(null)
	}, [open])

	const handleClickAuthenticationDocumentation = (url) => {
		DocumentationUserAuthentication(cookies[PLATFORM_ID_COOKIE], (response, error) => {
			if (error) {
				console.error(error)
				return
			}

			if (response.data)
				Cookies.set(USER_OBJECTID, response.data.UserUUID, {
					...DEFAULT_COOKIE_OPTIONS,
				})
			window.open(url, '_blank')
		})
	}

	const Submenu = ({ title, options }) => {
		const classes = useStyles()

		return (
			<div
				style={{ left: options.coords.x, top: options.coords.y }}
				className={classes.submenuContainer}
			>
				<div className={classes.submenuHeader}>
					<Typography variant="subtitle1" color="primary" className={classes.verticalAlign}>
						&nbsp;{title}
					</Typography>
					<KeyboardArrowRightIcon
						className={classNames(classes.arrowIcon, classes.verticalAlign)}
					/>
				</div>
				<div className={classes.submenuList}>
					{options &&
						options.lines &&
						options.lines.map((line, i) => (
							<Access key={i} accept={line.accept}>
								<a href={line.link} target={line.target || ''} onClick={line.onClick ?? null}>
									<div className={classes.submenu}>
										<Typography
											color="secondary"
											variant="subtitle2"
											className={classNames(classes.verticalAlign, classes.merge)}
										>
											{line.text}
										</Typography>
									</div>
								</a>
							</Access>
						))}
				</div>
			</div>
		)
	}

	const MenuItem = ({ text, icon, options, reverse = false }) => {
		const selfRef = useRef()
		const [coords, setCoords] = useState({ x: 250, y: 0 })

		const handleClick = (e) => {
			if (selected !== text) setSelected(text)
			else setSelected(null)
		}

		useEffect(() => {
			if (!options) return
			let domNode = ReactDOM.findDOMNode(selfRef.current)
			let bound = domNode.getBoundingClientRect()
			setCoords({ x: bound.left + bound.width, y: bound.top, height: bound.height })
		}, [selfRef])

		useEffect(() => {
			if ((coords && (!coords.height || !coords.y)) || !options.lines) return
			let windowHeight = window.innerHeight
			let submenuHeight = (options.lines.length + 1) * coords.height

			if (coords.y + submenuHeight > windowHeight) {
				let result = coords.y + submenuHeight - windowHeight
				setCoords({ ...coords, y: coords.y - result })
			}
		}, [coords])

		return (
			<>
				<ListItem
					button
					onClick={handleClick}
					ref={selfRef}
					key={t(text)}
					classes={{ selected: classes.selectedItem }}
					selected={selected === text}
				>
					<ListItemIcon
						className={classNames({
							[classes.selectedIcon]: selected === text,
							[classes.revertColor]: reverse,
						})}
					>
						{icon}
					</ListItemIcon>
					{reverse ? (
						<ListItemText classes={{ primary: classes.revertColor }} primary={t(text)} />
					) : (
						<ListItemText primary={t(text)} />
					)}
				</ListItem>
				{coords && coords.y > 0 && options && (
					<Slide in={selected === text} direction="right">
						<Submenu title={t(text)} options={{ coords, ...options }} />
					</Slide>
				)}
			</>
		)
	}

	// CONSTANTS
	const ACCESS = {
		All: [
			ROLES.ADMIN,
			ROLES.PLATFORMMANAGER,
			ROLES.PRINCIPAL,
			ROLES.EXTCOMPADMIN,
			ROLES.EXTCOMP,
			ROLES.INTERNALOBS,
			ROLES.EXTERNALOBS,
			ROLES.HYGIENIST,
		],
		AllExceptExtObs: [
			ROLES.ADMIN,
			ROLES.PLATFORMMANAGER,
			ROLES.PRINCIPAL,
			ROLES.EXTCOMPADMIN,
			ROLES.EXTCOMP,
			ROLES.INTERNALOBS,
			ROLES.HYGIENIST,
		],
		Manager: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
		ManagerWithDO: [ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL],
		Company: [ROLES.EXTCOMPADMIN, ROLES.EXTCOMP],
		Documentation: [ROLES.DOCUMENTATION],
		Hygienist: [ROLES.HYGIENIST],
		InternalObs: [ROLES.INTERNALOBS],
		Oversight: [ROLES.ADMIN, ROLES.HYGIENIST, ROLES.PRINCIPAL],
	}

	// TRANSLATION
	const { t } = useTranslation(['user', 'documentation', 'specialsTreatments', 'companyRecord'])

	// CONTENT
	return (
		<Drawer
			variant="permanent"
			className={classNames(classes.drawer, classes.drawerClose)}
			classes={{
				paper: classNames(
					{
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					},
					classes.paper,
				),
			}}
			open={open}
		>
			<div className={classes.toolbar} />
			<List className={classes.topList}>
				<Access accept={ACCESS.Oversight}>
					<MenuItem
						text="oversight"
						options={{
							lines: [{ text: t('oversight'), link: '/dashboard' }],
						}}
						icon={<RemoveRedEyeIcon />}
					/>
				</Access>
				<Access accept={ACCESS.All}>
					<MenuItem
						text="preventionPlan"
						icon={<SecurityIcon />}
						options={{
							lines: [
								{
									text: t('FollowPdP'),
									link: '/followPdp',
									accept: ACCESS.All,
								},
							],
						}}
					/>
				</Access>
				<Access accept={ACCESS.AllExceptExtObs}>
					<MenuItem
						text="companyFiles"
						icon={<FolderIcon />}
						options={{
							lines: [
								{
									text: t('FollowUp'),
									link: '/followCR',
									accept: ACCESS.AllExceptExtObs,
								},
								{
									text: t('Approbation'),
									link: '/ApprovalCompaniesRecords/' + cookies[PLATFORM_ID_COOKIE],
									accept: ACCESS.Manager,
								},
								{
									text: t('Historization'),
									link: '/HistorizationCompaniesRecords',
									accept: ACCESS.Manager,
								},
							],
						}}
					/>
				</Access>
				<MenuItem
					text="account"
					icon={<PermIdentityIcon />}
					options={{
						lines: [
							{
								text: t('OpeningRequest'),
								link: '/openingRequest',
								accept: ACCESS.Manager,
							},
							{
								text: t('AccountManagement'),
								link: '/users',
								accept: ACCESS.ManagerWithDO,
							},
							{ text: t('MyAccount'), link: '/myAccount' },
							{
								text: t('MyOperationalAccount'),
								link: '/op-infos',
								accept: ACCESS.Company,
							},
						],
					}}
				/>
				<Access accept={ACCESS.Manager}>
					<MenuItem
						text={t('Notification')}
						icon={<NotificationImportantIcon />}
						options={{
							lines: [
								{
									text: t('MassNotifications'),
									link: '/massNotification',
									accept: ACCESS.Manager,
								},
							],
						}}
					/>
				</Access>
			</List>
			<List>
				<Access accept={ACCESS.Manager}>
					<MenuItem
						text="platform"
						icon={<Icon path={mdiFactory} size={1} className={classes.icon} />}
						options={{
							lines: [
								{
									text: t('ManagementAlternates'),
									link: '/alternatesmanagement',
									accept: ACCESS.Manager,
								},
								{
									text: t('ManagementReferenceDocuments'),
									link: '/reference-documents-management',
									accept: ACCESS.Manager,
								},
								{
									text: t('ActivityPhase'),
									link: '/activityphasesmanagement',
									accept: ACCESS.Manager,
								},
								{
									text: t('checklist_company_record'),
									link: '/checklist-company-record',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
								{
									text: t('sectorsUnitsManagement'),
									link: '/sectors-units-management',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
								{
									text: t('platformConfiguration'),
									link: '/platform-configuration',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
							],
						}}
						reverse
					/>
				</Access>
				<MenuItem
					text={t('Documentation')}
					icon={<DescriptionIcon />}
					options={{
						lines: [
							{
								text: t('documentation:LiveDocumentation'),
								link: `#`,
								accept: ALL_ROLES,
								onClick: () =>
									handleClickAuthenticationDocumentation(
										`${DOC_URL + cookies[PLATFORM_ID_COOKIE]}/Accueil?p_viewmode=live`,
									),
							},
							{
								text: t('documentation:SandboxDocumentation'),
								link: `#`,
								accept: ACCESS.Manager,
								onClick: () =>
									handleClickAuthenticationDocumentation(
										`${DOC_URL + cookies[PLATFORM_ID_COOKIE]}/Accueil?p_viewmode=sandbox`,
									),
							},
							{
								text: t('documentation:LiveSharepoint'),
								link: cookies[SHAREPOINT_URL_LIVE_COOKIE],
								accept: ACCESS.Manager,
								target: '_blank',
							},
							{
								text: t('documentation:SandboxSharepoint'),
								link: cookies[SHAREPOINT_URL_SANDBOX_COOKIE],
								accept: ACCESS.Manager,
								target: '_blank',
							},
							{
								text: t('documentation:Administration'),
								link: `#`,
								accept: ACCESS.Manager,
								onClick: () =>
									handleClickAuthenticationDocumentation(
										`${DOC_URL}admin/${cookies[PLATFORM_ID_COOKIE]}`,
									),
							},
						],
					}}
					reverse
				/>
				<Access accept={[...ACCESS.ManagerWithDO, ...ACCESS.Hygienist, ...ACCESS.InternalObs]}>
					<MenuItem
						text={t('reports')}
						icon={<CommentIcon className={classes.icon} />}
						options={{
							lines: [
								{
									text: t('reports'),
									link: '/reports',
									accept: [...ACCESS.ManagerWithDO, ...ACCESS.Hygienist, ...ACCESS.InternalObs],
								},
							],
						}}
						reverse
					/>
				</Access>
				<Access accept={ACCESS.Manager}>
					<MenuItem
						text="tools"
						icon={<SettingsIcon className={classes.icon} />}
						options={{
							lines: [
								{
									text: t('EditingFlowModels'),
									link: '/flowmodel',
									accept: [ROLES.ADMIN],
								},
								{
									text: t('menu_hygienist_notification_title'),
									link: '/hygienist-notification',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
								{
									text: t('companyRecord:refusal_message_company_record'),
									link: '/refusal-messages',
									accept: [ROLES.ADMIN],
								},
								{
									text: t('specialsTreatments:specials_treatments'),
									link: '/specials-treatments',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
								{
									text: t('menu_mail_communication_title'),
									link: '/mail-communication',
									accept: [ROLES.ADMIN, ROLES.PLATFORMMANAGER],
								},
							],
						}}
						reverse
					/>
				</Access>
			</List>
		</Drawer>
	)
}

export default TotalToolBar
