import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Icon,
	IconButton,
	Typography,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import printJS from 'print-js'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import { Redirect } from 'react-router-dom'
import FormStep from '../../components/Form/FormStep'
import DescriptionOfOperationsForm from '../../components/forms/CompanyFiles/DescriptionOfOperationsForm/DescriptionOfOperationsForm'
import TotalArrowStepper from '../../components/TotalArrowsStepper/TotalArrowsStepper'
import { PLATFORM_ID_COOKIE, ACCOUNT_TYPES_COOKIE } from '../../constants/cookies'
import { COMPANYRECORDSUBMIT, GENERALINFOS } from '../../constants/url'
import {
	confirmSubmitCompanyRecord,
	getDEChevronsStates,
	getOperationDescription,
	getOperationalInformationsByAccountId,
} from '../../containers/DataContainer'
import {
	useAddChemicalProduct,
	useAddCompanyFilesCertification,
	useAddCompanyFilesContact,
	useAddCompanyFilesContactHSEForm,
	useAddCompanyFilesDocuments,
	useAddCSEContactForm,
	useAddEnvironmentalAspectsFiles,
	useCertificationDEForm,
	useChemicalProductsStorage,
	useCreateContactForm,
	useDEChemicalProductsForm,
	useEnvironmentalAspectNewFileForm,
	useExecutionManagerForm,
	useMedicalSurveillanceForm,
	useOperationalProceduresForm,
	useOperationnalModeForm,
	usePhasesUnsafeOperations,
	usePhaseUnsafeObservationForm,
	useSubcontractingCompagniesForm,
	useCheckListForm,
} from '../../containers/FormContainer'
import { ArrayBufferToString } from '../../utils/Buffers'
import { HandlingErrorMessages } from '../../utils/Errors'
import { bindParams } from '../../utils/Link'
import api from '../../utils/Requester'
import ChemicalsProducts from './ChemicalsProducts/ChemicalsProducts'
import CRStepInitializer from './CRStepInitializer'
import EnvironmentalAspects from './EnvironmentalAspects/EnvironmentalAspects'
import HygieneAndHealth from './HygieneAndHealth/HygieneAndHealth'
import OperationalProcedures from './OperationalProcedures/OperationalProcedures'
import PhasesUnsafeOperations from './PhasesUnsafeOperations/PhasesUnsafeOperations'
import SubcontractingCompagnies from './SubcontractingCompagnies/SubcontractingCompagnies'
import { ALL_EXT_ROLES } from '../../constants/roles'
import PropTypes from 'prop-types'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

const useStyles = makeStyles((theme) => ({
	nextBtn: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	rootBtn: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	stepperButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 50,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	previousBtn: {
		marginLeft: 20,
		marginRight: 20,
	},
	rightBtn: {
		marginRight: 20,
	},
	nextWithoutComplete: {
		marginRight: '10px',
	},
	buttonFont: {
		fontSize: 12,
		color: 'white',
	},
	button: {
		maxWidth: 200,
		marginTop: 3,
		marginLeft: 15,
		boxShadow: '0px 0px 0px 0px',
	},
	buttonText: {
		['@media (max-width:1150px)']: {
			// eslint-disable-line no-useless-computed-key
			display: 'none',
		},
	},
	rightIcon: {
		['@media (max-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			fontSize: '3em',
		},
	},
	popupTitle: {
		color: theme.palette.secondary.main,
		margin: 25,
	},
	iconsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	popup: {
		maxWidth: 700,
	},
	blockDialogText: {
		fontSize: '12px',
	},
	buttonProgressWhite: {
		color: '#ffffff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	buttonProgressSecondary: {
		color: theme.palette.secondary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	colorOpacity: {
		color: 'rgba(255,255,255,0.3)',
	},
	hidden: {
		display: 'none',
	},
}))

function CompanyRecord({ match, setCompanyRecordId }) {
	const { t } = useTranslation(['user', 'companyRecord'])
	const [cookies] = useCookies()

	const classes = useStyles()
	const queries = match.params
	const {
		companyRecordId,
		companyId,
		pdpId,
		platformId,
		pdpRevisionId,
		restrictedPdPContext,
		companyManagerId,
	} = queries

	//#region isRefuse
	const [isRefuse, setIsRefuse] = useState(false)
	const [openDE, setOpenDE] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [loading, setLoading] = useState(false)

	const [pdfBlob, setPdfBlob] = useState()
	const [pdfLink, setPdfLink] = useState()
	const [numPages, setNumPages] = useState()
	const { enqueueSnackbar } = useSnackbar()
	const [isValidateAsk, setIsValidateAsk] = useState(false)

	const currentstep = useState('')
	const [currStepData, setCurrStepData] = currentstep
	const [isReadOnly, setIsReadOnly] = useState(false)
	const [chevronStates, setChevronStates] = useState([])
	const [open, setOpen] = useState(false)
	const [issub, setSub] = useState(false)
	const [ispp, setPp] = useState(false)

	const [revisionCompanyRecordId, setRevisionCompanyRecordId] = useState()
	const [redirectRevision, setRedirectRevision] = useState()

	const [pdpNumber, setPdpNumber] = useState('')
	const [pdpRevisionNumber, setPdpRevisionNumber] = useState('')
	const [companyname, setCompanyName] = useState('')
	const [confirmSignature, setConfirmSignature] = useState(false)
	const [refreshChevrons, setRefreshChevrons] = useState(false)

	const isManager = cookies[ACCOUNT_TYPES_COOKIE].some((ur) => ur.code === 'PLATFORMMANAGER')

	const RefusalPopUp = (props) => {
		const { onClose, open } = props
		return (
			<Dialog open={open} onClose={onClose} classes={{ paper: classes.popup }}>
				<Typography classes={{ root: classes.popupTitle }} variant="h6">
					{t('ImcompleteRecord')}
				</Typography>
				<DialogContent>
					<DialogContentText>
						{isRefuse}
						<br />
						{IsExternalAccount && t('YouCanSubmitAgain')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="secondary" autoFocus>
						{IsExternalAccount ? t('UpdateDE') : t('SeeDE')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	//#endregion isRefuse

	//#region Validate Popup
	const OpenValidate = () => {
		if (isValidateAsk) return
		setIsValidateAsk(true)
		setLoading(true)
		setIsReadOnly(true)
		api
			.get(
				bindParams(COMPANYRECORDSUBMIT, cookies[PLATFORM_ID_COOKIE], companyRecordId),
				{},
				{ responseType: 'arraybuffer' },
			)
			.then((res) => {
				let { data, ok } = res
				setLoading(false)
				setIsReadOnly(false)
				if (!ok) {
					if (data) {
						data = JSON.parse(ArrayBufferToString(data))
						HandlingErrorMessages(data, (code) => {
							enqueueSnackbar(t('companyRecord:' + code), { variant: 'error' })
						})
						setIsValidateAsk(false)
					}
					return
				}

				if (!data) return
				const blob = new Blob([data], { type: 'application/pdf' })
				setPdfBlob(blob)
				setPdfLink(URL.createObjectURL(blob))
				setOpen(true)
				setIsValidateAsk(false)
			})
			.catch((err) => console.log(err))
	}
	const ConfirmSubmit = () => {
		if (!confirmSignature) {
			// Switch to second confirmation
			setConfirmSignature(true)
			return
		}
		setLoading(true)
		confirmSubmitCompanyRecord(cookies[PLATFORM_ID_COOKIE], companyRecordId, (res, err) => {
			setLoading(false)
			if (err) {
				if (err.data) enqueueSnackbar(t('companyRecord:' + err.data[0].code), { variant: 'error' })
				return
			}

			if (!res) return
			let message = !restrictedPdPContext ? t('DEHasBeenSubmit') : t('RestrictedPdpDEHasBeenSubmit')
			enqueueSnackbar(message, { variant: 'info' })
			setRedirect(true)
			CloseValidate()
		})
	}

	const CloseValidate = () => {
		setOpen(false)
		setConfirmSignature(false)
	}

	const onDocumentLoadSuccess = (document) => {
		setNumPages(document._pdfInfo.numPages)
	}
	const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob)

	const ValidatePopup = (props) => {
		const { onClose, open } = props
		return (
			<Dialog open={open} onClose={onClose} classes={{ paper: classes.popup }}>
				<Typography classes={{ root: classes.popupTitle }} variant="h6">
					{t('ConfirmSubmit')}
				</Typography>
				<DialogContent>
					<DialogContentText
						className={`${classes.blockDialogText} ${confirmSignature ? classes.hidden : ''}`}
					>
						{t('SubmitBlock1')} <br />
						{t('SubmitBlock2')} <br />
						{t('SubmitBlock3')} <br />
						{t('SubmitBlock4')} <br />
					</DialogContentText>

					<DialogContentText
						className={`${classes.blockDialogText} ${!confirmSignature ? classes.hidden : ''}`}
					>
						{t('SignatureSubmitBlock1')} <br />
						{t('SignatureSubmitBlock2')} <br />
						{t('SignatureSubmitBlock3')} <br />
						{t('SignatureSubmitBlock4')} <br />
						{t('SignatureSubmitBlock5')} <br />
					</DialogContentText>

					<div className={`${classes.iconsContainer} ${confirmSignature ? classes.hidden : ''}`}>
						<IconButton
							onClick={() => {
								fileDownload(pdfBlob, 'deValidationFile.pdf')
							}}
						>
							<Icon>get_app</Icon>
						</IconButton>
						<IconButton
							onClick={() => {
								isIE11
									? window.navigator.msSaveOrOpenBlob(pdfBlob, 'deValidationFile.pdf')
									: printJS(pdfLink)
							}}
						>
							<Icon>local_printshop</Icon>
						</IconButton>
					</div>
					<Scrollbars hidden={confirmSignature} autoHeight={true} autoHeightMin={'40vh'} {...props}>
						<Document
							file={pdfLink}
							onLoadError={console.error}
							onLoadSuccess={onDocumentLoadSuccess}
							renderMode="svg"
						>
							{numPages &&
								Array.from(new Array(numPages), (el, index) => (
									<Page key={`page_${index + 1}`} pageNumber={index + 1} />
								))}
						</Document>
					</Scrollbars>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} onClick={onClose} color="secondary">
						{t('cancel')}
					</Button>
					{!loading && (
						<Button disabled={loading} onClick={ConfirmSubmit} color="secondary" autoFocus>
							{loading && (
								<CircularProgress size={24} className={classes.buttonProgressSecondary} />
							)}
							{t('confirm')}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		)
	}

	const SubmitButton = () => {
		return (
			<>
				<Button
					variant="contained"
					color="secondary"
					classes={{
						label: loading ? `${classes.colorOpacity} ${classes.buttonFont}` : classes.buttonFont,
						root: classes.button,
					}}
					onClick={OpenValidate}
					disabled={isReadOnly || !companyRecordId}
				>
					{loading && <CircularProgress size={24} className={classes.buttonProgressWhite} />}
					<CheckCircleOutline className={classes.rightIcon} />
					<span className={classes.buttonText}>{t('companyRecord:companyRecordSubmit')}</span>
				</Button>
			</>
		)
	}
	//#endRegion Validate Popup

	const buttonsStyle = {
		root: classes.rootBtn,
		previous: classNames(classes.stepperButton, classes.previousBtn),
		next: classNames(classes.stepperButton, classes.nextBtn),
		completed: classNames(classes.stepperButton, classes.nextWithoutComplete),
		finished: classNames(classes.stepperButton, classes.rightBtn),
		nextWithoutComplete: classNames(classes.stepperButton, classes.nextWithoutComplete),
	}

	const AddCompanyFilesContact = useAddCompanyFilesContact()
	const AddCompanyFilesCertification = useAddCompanyFilesCertification()
	const AddCompanyFilesDocuments = useAddCompanyFilesDocuments()
	const MedicalSurveillanceForm = useMedicalSurveillanceForm()
	const ChemicalProductsForm = useDEChemicalProductsForm()
	const ChemicalProductsStorage = useChemicalProductsStorage()
	const SubcontractingCompagniesForm = useSubcontractingCompagniesForm()
	const EnvironmentalAspectNewFileForm = useEnvironmentalAspectNewFileForm()
	const OperationalProceduresForm = useOperationalProceduresForm()
	const AddOperationalProceduresForm = useOperationnalModeForm()
	const PhasesUnsafeOperationsForm = usePhasesUnsafeOperations()
	const AddEnvironmentalAspectsFiles = useAddEnvironmentalAspectsFiles()
	const AddChemicalProduct = useAddChemicalProduct()
	const AddCSEContactForm = useAddCSEContactForm()
	const PhaseUnsafeObservationForm = usePhaseUnsafeObservationForm()
	const ExecutionManagerForm = useExecutionManagerForm()
	const CreateContactForm = useCreateContactForm()
	const CertificationDEForm = useCertificationDEForm()
	const AddCompanyFilesContactHSEForm = useAddCompanyFilesContactHSEForm()
	const CheckListForm = useCheckListForm()

	const ALLSTEPS = [
		{
			label: t('OperationDescription'),
			code: 'OperationsDetails',
			content: DescriptionOfOperationsForm,
			forms: [
				AddCompanyFilesContact,
				AddCompanyFilesCertification,
				AddCompanyFilesDocuments,
				ExecutionManagerForm,
				CreateContactForm,
				CertificationDEForm,
				CheckListForm,
			],
		},
		{
			label: t('SubContractingCompanies'),
			code: 'SubContractingCompanies',
			content: SubcontractingCompagnies,
			forms: [SubcontractingCompagniesForm],
		},
		{
			label: t('HygieneAndHealth'),
			code: 'HygieneAndHealth',
			content: HygieneAndHealth,
			forms: [MedicalSurveillanceForm, AddCSEContactForm, AddCompanyFilesContactHSEForm],
		},
		{
			label: t('DangerActivityPhases'),
			code: 'DangerActivityPhases',
			content: PhasesUnsafeOperations,
			forms: [PhasesUnsafeOperationsForm, PhaseUnsafeObservationForm],
		},
		{
			label: t('ChemicalProducts'),
			code: 'ChemicalProducts',
			content: ChemicalsProducts,
			forms: [AddChemicalProduct, ChemicalProductsForm, ChemicalProductsStorage],
		},
		{
			label: t('EnvironmentalAspects'),
			code: 'EnvironmentalAspects',
			content: EnvironmentalAspects,
			forms: [AddEnvironmentalAspectsFiles, EnvironmentalAspectNewFileForm],
		},
		{
			label: t('OperatingModes'),
			code: 'OperatingModes',
			content: OperationalProcedures,
			forms: [OperationalProceduresForm, AddOperationalProceduresForm],
		},
		{
			label: 'button',
			code: 'button',
			content: SubmitButton,
			type: 'button',
		},
	]

	const getSteps = () => {
		if (restrictedPdPContext || (issub && !ispp))
			return ALLSTEPS.filter(
				(step) => step.code !== 'SubContractingCompanies' || (isReadOnly && step.code === 'button'),
			)
		else return ALLSTEPS
	}

	const STEPS = getSteps()

	// Check if the connected person is an "internal" or "external" profil, it will alter the display of the popup
	// ur => user roles / er => external roles
	let IsExternalAccount = cookies[ACCOUNT_TYPES_COOKIE].some((ur) =>
		ALL_EXT_ROLES.find((er) => er.id === ur.id),
	)

	// #region Get chevrons states
	const getChevronsStates = async () => {
		if (!companyRecordId) return
		await getOperationDescription(platformId, companyId, companyRecordId, (res, err) => {
			if (err) return

			// If it's internal account, we display more informations (pdp revision number and company name)
			if (!IsExternalAccount) {
				setPdpRevisionNumber(res.data.companyRecordRevisionNumber)
				let filters = 'None'
				getOperationalInformationsByAccountId(filters, res.data.companyId, (response, error) => {
					if (error) return
					if (response) setCompanyName(response.data.entNom)
				})
			}
			if (res) {
				setPp(res.data.isPrincipalCompany)
				setSub(res.data.isSubcontractingCompany)
			}
			if (res.data.refusalReason) {
				setIsRefuse(res.data.refusalReason)
				setOpenDE(true)
			}
		})
		callDEChevronsStates()
	}

	const callDEChevronsStates = () => {
		if (!companyRecordId) {
			api
				.get(bindParams(GENERALINFOS, cookies[PLATFORM_ID_COOKIE], pdpId, pdpRevisionId))
				.then((res) => {
					if (res.ok) setPdpNumber(res.data.pdpNumero)
				})
			return
		}
		getDEChevronsStates(platformId, companyRecordId, (res, err) => {
			if (err) return console.error('Error to subcontractor companies datatable !')

			if (!res || !res.data) return
			setPdpNumber(res.data.pdpNumber)
			setIsReadOnly(res.data.isReadOnly || isManager)
			setChevronStates(res.data.chevronStates)
		})
	}

	useEffect(() => {
		;(async () => {
			getChevronsStates()
		})()
	}, [])

	//#endregion Get chevrons states

	//#region Revision
	useEffect(() => {
		if (revisionCompanyRecordId && companyRecordId && revisionCompanyRecordId !== companyRecordId) {
			setRedirectRevision(true)
			return
		}
		setRedirectRevision(false)
	}, [revisionCompanyRecordId])
	//#endregion Revision

	useEffect(() => callDEChevronsStates(), [refreshChevrons])

	return (
		<>
			{redirect && !restrictedPdPContext && <Redirect to="/followCR" />}
			{redirectRevision && !restrictedPdPContext && (
				<Redirect
					to={
						'/companyRecord/' +
						cookies[PLATFORM_ID_COOKIE] +
						'/' +
						companyId +
						'/' +
						revisionCompanyRecordId
					}
				/>
			)}
			<ValidatePopup open={open} onClose={CloseValidate} />
			<RefusalPopUp
				open={openDE}
				onClose={() => {
					setOpenDE(false)
				}}
			/>
			<TotalArrowStepper
				buttonsStyle={buttonsStyle}
				contentStyle={classes.contentStyle}
				completedSteps={chevronStates}
				stepStatus={currStepData}
				setStepStatus={setCurrStepData}
				icon="folder_shared"
				title={t('CompanyRecord')}
				content={`${t('WelcomeOnYourDE')} ${companyname}`}
				pdpnumber={`${t('numberPDP')} ${pdpNumber} ${pdpRevisionNumber}`}
			>
				{STEPS.map((step) => (
					<FormStep
						label={step.label}
						chevronCode={step.code}
						key={step.label}
						type={step.type ? step.type : undefined}
						disable={
							(!companyRecordId && step.code !== 'OperationsDetails') ||
							(step.code === 'SubContractingCompanies' &&
								((issub && !ispp) || restrictedPdPContext))
						}
					>
						<CRStepInitializer
							currStep={currentstep}
							content={step.content}
							forms={step.forms}
							stepperBtns={buttonsStyle}
							queries={queries}
							isReadOnly={isReadOnly}
							setRevisionCompanyRecordId={setRevisionCompanyRecordId}
							setCompanyRecordId={setCompanyRecordId}
							companyManagerId={companyManagerId}
							restrictedPdPContext={restrictedPdPContext}
							refreshChevrons={refreshChevrons}
							setRefreshChevrons={setRefreshChevrons}
						/>
					</FormStep>
				))}
			</TotalArrowStepper>
		</>
	)
}
CompanyRecord.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.func.isRequired,
	match: PropTypes.object,
	setCompanyRecordId: PropTypes.func,
}

export default CompanyRecord
