import { Tooltip } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import Check from '@material-ui/icons/Check'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DeleteForever from '@material-ui/icons/DeleteForever'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import NotInterested from '@material-ui/icons/NotInterested'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import DataTable from '../../components/NewDataTable/NewDataTable'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { openingRequestsList } from '../../containers/DataContainer'
import {
	useAcceptOpenningRequestForm,
	useRefuseOpenningRequestForm,
} from '../../containers/FormContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import moment from 'moment'
import { COLUMNS_OPENING_REQUESTS, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	datatable: {
		margin: 35,
	},
	validateIcon: {
		color: theme.palette.secondary.main + ' !important',
		margin: 5,
		marginLeft: 0,
		display: 'inline-block',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	deleteIcon: {
		color: theme.palette.secondary.main + ' !important',
		marginLeft: 'auto',
		display: 'inline-block',
		margin: 5,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	stateIcon: {
		display: 'block',
		marginLeft: '25px',
		color: theme.palette.secondary.main,
	},
	tooltip: {
		display: 'block',
	},
}))

function OpeningRequests() {
	const classes = useStyles()
	const { setTitleBar } = useTotalTitleBarContext()
	const { t } = useTranslation('user')
	const [companyId, setCompanyId] = useState()
	const [update, setUpdate] = useState()
	const formData = {
		companyId: companyId,
		setUpdate: setUpdate,
		update: update,
	}
	const [cookies] = useCookies()
	const acceptOpenningRequestForm = useAcceptOpenningRequestForm(formData)
	const refuseOpenningRequestForm = useRefuseOpenningRequestForm(formData)
	const [result, setResult] = useState({})
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'remove_red_eye',
				title: t('oversight'),
				subtitle: t('WelcomeToYourPdP'),
			},
		})
	}, [setTitleBar, t])

	useEffect(() => {
		let body = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			nbItemsAsked: 10,
			pageNumber: 1,
		}
		setIsLoading(true)
		openingRequestsList(body, function (res) {
			if (res) {
				let tempRes = res.data
				let newColumns = [
					{
						name: 'companyid',
						label: t('Action'),
						options: {
							display: 'true',
							filter: false,
							filterOptions: null,
							searchable: false,
							sort: false,
						},
					},
					{
						name: 'companyjustification',
						label: t('Reason'),
						options: {
							display: 'false',
							filter: false,
							filterOptions: null,
							searchable: false,
							sort: false,
						},
					},
				]
				tempRes.columns = [...tempRes.columns, ...newColumns]
				setResult(tempRes)
			}
			setIsLoading(false)
		})
	}, [update, cookies, t])

	const toolTipComponent = (value, tableMeta) => {
		return (
			<Tooltip
				title={
					tableMeta.rowData[
						getIndexOfColumn(result.columns, COLUMNS_OPENING_REQUESTS.COMPANYJUSTIFICATION)
					]
				}
				placement="bottom"
			>
				<span>{value}</span>
			</Tooltip>
		)
	}

	return (
		<>
			{acceptOpenningRequestForm.formular}
			{refuseOpenningRequestForm.formular}
			<HeadTitle title={t('OpeningRequest')} link="/dashboard" />
			<div className={classes.datatable}>
				<DataTable
					title={t('OpeningRequest')}
					data={result.data}
					columns={result.columns}
					options={result.options}
					isLoading={isLoading}
					customColumns={[
						{
							name: 'openingrequestdate',
							component: function componentOpeningRequestDate(value, tableMeta) {
								if (!tableMeta.rowData) return
								return (
									<Tooltip
										title={
											tableMeta.rowData[
												getIndexOfColumn(
													result.columns,
													COLUMNS_OPENING_REQUESTS.COMPANYJUSTIFICATION,
												)
											]
										}
										placement="bottom"
									>
										<span>{moment(value).format('DD/MM/YYYY')}</span>
									</Tooltip>
								)
							},
						},
						{
							name: 'companyname',
							component: function componentCompanyName(value, tableMeta) {
								if (!tableMeta.rowData) return
								return toolTipComponent(value, tableMeta)
							},
						},
						{
							name: 'companyagencyname',
							component: function componentCompanyAgencyName(value, tableMeta) {
								return toolTipComponent(value, tableMeta)
							},
						},
						{
							name: 'companysiret',
							component: function componentCompanySiret(value, tableMeta) {
								return toolTipComponent(value, tableMeta)
							},
						},
						{
							name: 'companyphone',
							component: function componentCompanyPhone(value, tableMeta) {
								return toolTipComponent(value, tableMeta)
							},
						},
						{
							name: 'companyemail',
							component: function componentCompanyEmail(value, tableMeta) {
								return toolTipComponent(value, tableMeta)
							},
						},
						{
							name: 'companystateid',
							component: (value) => {
								switch (value) {
									case 1:
										return (
											<Tooltip title={t('OnStandby')} placement="bottom">
												<AccessTime className={classes.stateIcon} />
											</Tooltip>
										)
									case 2:
										return (
											<Tooltip title={t('accepted')} placement="bottom">
												<Check className={classes.stateIcon} />
											</Tooltip>
										)
									case 3:
										return (
											<Tooltip title={t('refused')} placement="bottom">
												<NotInterested className={classes.stateIcon} />
											</Tooltip>
										)
									case 4:
										return (
											<Tooltip title={t('disabled')} placement="bottom">
												<HourglassEmpty className={classes.stateIcon} />
											</Tooltip>
										)
									default:
										break
								}
							},
						},
						{
							name: 'companyid',
							component: (_value, tableMeta) => {
								const companyId =
									tableMeta.rowData[
										getIndexOfColumn(result.columns, COLUMNS_OPENING_REQUESTS.COMPANYID)
									]

								if (!tableMeta.rowData) return
								if (
									tableMeta.rowData[
										getIndexOfColumn(result.columns, COLUMNS_OPENING_REQUESTS.COMPANYSTATEID)
									] === 1
								) {
									return (
										<>
											<CheckCircle
												className={classes.validateIcon}
												onClick={() => {
													acceptOpenningRequestForm.open()
													setCompanyId(companyId)
												}}
											/>
											<DeleteForever
												className={classes.validateIcon}
												onClick={() => {
													refuseOpenningRequestForm.open()
													setCompanyId(companyId)
												}}
											/>
										</>
									)
								}
							},
						},
					]}
					customLabels={[
						{
							name: 'openingrequestdate',
							label: t('AskingDate'),
						},
						{
							name: 'companyname',
							label: t('companyName'),
						},
						{
							name: 'companyagencyname',
							label: t('CompanyAgencyName'),
						},
						{
							name: 'companysiret',
							label: t('CompanySiret'),
						},
						{
							name: 'companyphone',
							label: t('CompanyPhone'),
						},
						{
							name: 'companyemail',
							label: t('CompanyEmail'),
						},
						{
							name: 'companystateid',
							label: t('CompanyState'),
						},
						{
							name: 'action',
							label: t('Action'),
						},
						{
							name: 'reason',
							label: t('Reason'),
						},
					]}
				/>
			</div>
		</>
	)
}
export default OpeningRequests
