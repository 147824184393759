import React from 'react'

function CGUContentFr(props) {
	return (
		<div>
			<p>
				Bienvenue sur le site internet{' '}
				<a href="https://rapso-d.totalenergies.com"> https://rapso-d.totalenergies.com</a> (Le «
				site internet »). En vous connectant ou en utilisant notre site internet, vous reconnaissez
				avoir lu, compris, et accepté, sans limitation ni réserve, les présentes conditions
				générales d’utilisation et notre charte sur la protection des données à caractère personnel
				et cookies. Veuillez noter que si vous visitez d’autres sites du groupe Total d'autres
				conditions générales et chartes sur la protection des données à caractère personnel sont
				applicables sur ces sites et nous vous recommandons de les consulter.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>1. Identité</h2>
			<p>
				<span style={{ fontWeight: 'bold' }}>Société éditrice du site internet,</span> ci-après la «
				Société » :<br />
				Total Raffinage Chimie
				<br />
				Siège social : 2 place Jean Millier – La Défense 6 – 92400 Courbevoie
				<br />
				<br />
				Société par Actions Simplifiée
				<br />
				Capital : 933 605 625,50 €<br />
				Registre du Commerce et des Sociétés de Nanterre sous le numéro 692 004 807
				<br />
				Numéro T.V.A. : FR63692004807
				<br />
				E-mail : rapso-d-admin@totalenergies.com <br />
				Téléphone : 33 + 1 47 44 60 00
				<br />
				Directeur de publication : Direction Industrie
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>
					Conception technique et développement du site Internet :
				</span>
				<br />
				Business Decision France, SAS au capital de 815 200€
				<br />
				Coeur Défense A<br />
				110, Esplanade du Général de Gaulle
				<br />
				92931 Paris La Défense Cedex
				<br />
				Téléphone : 0156212121
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>Hébergement du site Internet :</span>
				<br />
				Total Global Information Technology Services,
				<br />
				SAS au capital de 6 577 787 euros, dont le siège social est situé 2 place Jean Millier – La
				Défense 6 – 92400 Courbevoie
				<br />
				immatriculée au Greffe de Nanterre sous le numéro 531 680 437
				<br />
				Téléphone : 33 + 1 47 44 60 00
				<br />
				Les sociétés du groupe Total ont leur propre existence légale et disposent d’une
				personnalité juridique autonome. La Société qui a créé ce site internet appartient au groupe
				Total. Les termes « Total » et « le groupe Total » sont parfois utilisés pour des raisons
				pratiques et désignent de façon générale, TOTAL S.A. (la société holding) et/ou ses
				filiales. De même, les termes « nous », « notre », « nos » font référence à la Société ou
				toute autre société du groupe Total en général. Il ne peut être déduit de la simple
				utilisation de ces expressions une quelconque implication de TOTAL S.A., ni d’aucune de ses
				filiales dans les affaires ou la gestion d’une autre société du groupe Total.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>2. Informations sur les activités</h2>
			<p>
				La Société a mis en ligne ce site internet afin de vous présenter certaines activités de la
				Société et le cas échéant d’autres activités du groupe Total. Les informations sont fournies
				dans le cadre d’une information générale, sans garantie d'adéquation à un besoin particulier
				d'utilisation. La Société peut les modifier, mettre à jour, à tout moment et sans préavis.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>3. Propriété intellectuelle</h2>
			<h3 style={{ fontWeight: 'bold' }}>3.1. Principe.</h3>
			<p>
				Toutes les informations ou documents (textes, images animées ou non, bases de données, sons,
				photos, savoir-faire, produits cités) contenus dans le site internet ainsi que tous les
				éléments créés pour le site internet et sa structure générale, sont soit la propriété de la
				Société ou du groupe Total, soit font l'objet de droits d'utilisation, de reproduction et de
				représentation consentis au profit de ces derniers. Ces informations, documents ou éléments
				sont soumis aux lois protégeant le droit d'auteur dès lors qu'elles sont mises à la
				disposition du public sur ce site internet. Aucune licence, ni aucun droit autre que celui
				de consulter le site internet, n'est conféré à quiconque au regard des droits de propriété
				intellectuelle. La reproduction des documents du site internet est autorisée à des fins
				exclusives d'information pour un usage personnel et privé. Toute reproduction et toute
				utilisation de copie réalisée à d'autres fins est expressément interdite et soumise à
				l'autorisation préalable et expresse de la Société. Dans tous les cas, la reproduction
				autorisée des informations contenues dans ce site internet devra indiquer la source et la
				mention de propriété adéquates.
			</p>
			<h3 style={{ fontWeight: 'bold' }}>3.2. Signes distinctifs.</h3>
			<p>
				Sauf mention contraire, les dénominations sociales, les logos, les produits et marques cités
				dans ce site internet sont la propriété de la Société ou du groupe Total, ou bien font
				l’objet de droits d’usage, de reproduction ou de représentation qui ont été consentis à leur
				profit. Ils ne peuvent être utilisés sans l'autorisation écrite préalable de la Société.
			</p>
			<h3 style={{ fontWeight: 'bold' }}>3.3. Bases de données.</h3>
			<p>
				Les éventuelles bases de données mises à votre disposition sont la propriété de la Société
				qui a la qualité de producteur de bases de données. Il vous est interdit d’extraire ou de
				réutiliser une partie qualitativement ou quantitativement substantielle des bases de données
				y compris à des fins privées.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>4. Engagement de l'utilisateur</h2>
			<p>
				<h3 style={{ fontWeight: 'bold', display: 'inline' }}>4.1.</h3> Chaque utilisateur du site
				internet qui fournit des informations consent à la Société l'intégralité des droits
				transférables relatifs à cette information et autorise la Société à en faire usage. Les
				informations ainsi fournies par les utilisateurs seront considérées comme non
				confidentielles. Toutefois, lorsque les informations fournies sont des données personnelles
				au sens du droit applicable elles sont traitées conformément à{' '}
				<a href="#" onClick={(e) => props.onClick()}>
					la charte sur la protection des données à caractère personnel et cookies
				</a>{' '}
				applicable à ce site internet.
			</p>
			<p>
				<h3 style={{ fontWeight: 'bold', display: 'inline' }}>4.2.</h3> Chaque utilisateur du site
				internet déclare se conformer aux présentes conditions générales d’utilisation et lois en
				vigueur, en particulier :<br />
				<ul>
					<li>
						disposer de la compétence et des moyens nécessaires pour accéder au site internet et
						l’utiliser ;
					</li>
					<li>
						avoir vérifié que la configuration informatique utilisée ne contient aucun virus et
						qu'elle est en parfait état de fonctionnement ;
					</li>
					<li>
						consentir à la Société et à ses partenaires le cas échéant le droit de faire tout usage
						des informations fournies (autre que des données à caractère personnel) ;
					</li>
					<li>
						devoir conserver confidentiels et être en conséquence responsable de l’utilisation et de
						la sécurité des codes d'accès et mots de passe que la Société peut vous transmettre pour
						accéder à certaines rubriques. La Société se réserve le droit de suspendre votre accès
						au site internet en cas d'utilisation frauduleuse ou de tentative d'utilisation
						frauduleuse de cet accès.
					</li>
				</ul>
			</p>
			<h2 style={{ fontWeight: 'bold' }}>5. Liens hypertextes </h2>
			<h3 style={{ fontWeight: 'bold' }}>5.1. Activation des liens.</h3>
			<p>
				La Société décline formellement toute responsabilité quant aux contenus des sites vers
				lesquels elle offre des liens. Ces liens sont proposés aux utilisateurs de ce site internet.
				Veuillez consulter les conditions générales et la charte sur la protection des données à
				caractère personnel et cookies de ces sites afin de comprendre leurs pratiques. La décision
				d'activer les liens appartient exclusivement aux utilisateurs du site internet. La Société
				peut modifier ou supprimer un lien sur notre site internet à tout moment. <br />
			</p>
			<h3 style={{ fontWeight: 'bold' }}>5.2. Autorisation des liens.</h3>
			<p>
				Si vous souhaitez créer un lien hypertexte avec ce site internet, vous devez obtenir
				l'autorisation écrite et préalable de la Société en utilisant les coordonnées de contact
				mentionnées à la fin de ce document.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>6. Mises en garde</h2>
			<p>
				<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.1.</h3> Les informations et
				recommandations disponibles sur ce site internet (« Informations ») sont proposées de bonne
				foi. Elles sont censées être correctes au moment où elles sont publiées sur le site.
				Toutefois, la Société ne garantit pas le caractère exhaustif et l'exactitude de ces
				Informations. Vous assumez pleinement les risques liés au crédit que vous leur accordez. Ces
				Informations vous sont fournies à la condition que vous ou toute autre personne qui les
				recevez puissiez déterminer leur intérêt pour un objectif précis avant de les utiliser. En
				aucun cas, la Société ne sera responsable des dommages susceptibles de résulter du crédit
				accordé à ces Informations ou de leur utilisation. Ces Informations ne doivent pas être
				considérées comme des recommandations pour l'utilisation d'informations, de produits, de
				procédures, d'équipements ou de formulations qui seraient en contradiction avec un brevet,
				un copyright ou une marque déposée. La Société décline toute responsabilité, expresse ou
				implicite, si l'utilisation de ces Informations venait à contrevenir à un brevet, un
				copyright ou une marque déposée.
				<br />
				La Société, et toute société du groupe Total rejette catégoriquement toute interprétation
				qui viserait à assimiler le contenu de ses sites internet à des offres d'achat ou des
				incitations à acquérir des actions ou autres valeurs mobilières cotées ou non cotées de la
				Société ou de toute autre société du groupe Total. Aucune garantie, expresse ou implicite,
				n'est donnée quant à la nature marchande des Informations fournies, ni quant à leur
				adéquation à une finalité déterminée, ainsi qu'en ce qui concerne les produits auxquels il
				est fait référence dans ces Informations. En aucun cas, la Société ne s'engage à mettre à
				jour ou à corriger les Informations qui seront diffusées sur Internet ou sur leurs serveurs
				web. La Société se réserve le droit de modifier ou de corriger le contenu de ses sites à
				tout moment sans préavis.
			</p>
			<p>
				<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.2.</h3> Les documents présentés sur
				ce site internet peuvent contenir des informations prospectives sur le Groupe (notamment des
				objectifs et tendances), ainsi que des déclarations prospectives (forward-looking
				statements) au sens du Private Securities Litigation Reform Act de 1995, concernant
				notamment la situation financière, les résultats d’opérations, les activités et la stratégie
				industrielle de TOTAL. Ces données ne constituent pas des prévisions au sens du règlement
				européen n°809/2004. <br />
				Les informations et déclarations prospectives contenues dans ces documents sont fondées sur
				des données et hypothèses économiques formulées dans un contexte économique, concurrentiel
				et réglementaire donné. Elles peuvent s’avérer inexactes dans le futur et sont dépendantes
				de facteurs de risques susceptibles de donner lieu à un écart significatif entre les
				résultats réels et ceux envisagés, tels que notamment la variation des taux de change, le
				prix des produits pétroliers, la capacité d’effectuer des réductions de coûts ou des gains
				d’efficacité sans perturbation inopportune des opérations, les considérations de
				réglementations environnementales et des conditions économiques et financières générales. De
				même, certaines informations financières reposent sur des estimations notamment lors de
				l’évaluation de la valeur recouvrable des actifs et des montants des éventuelles
				dépréciations d’actifs.
				<br />
				Ni TOTAL ni aucune de ses filiales ne prennent l’engagement ou la responsabilité vis-à-vis
				des investisseurs ou toute autre partie prenante de mettre à jour ou de réviser, en
				particulier en raison d’informations nouvelles ou événements futurs, tout ou partie des
				déclarations, informations prospectives, tendances ou objectifs contenus dans ces documents.
				Des informations supplémentaires concernant les facteurs, risques et incertitudes
				susceptibles d’avoir un effet sur les résultats financiers ou les activités du Groupe sont
				par ailleurs disponibles dans les versions les plus actualisées du Document de référence
				déposé par la Société auprès de l’Autorité des marchés financiers et du Form 20-F déposé par
				la Société auprès de la United States Securities and Exchange Commission (« SEC »).
			</p>
			<p>
				<h3 style={{ fontWeight: 'bold', display: 'inline' }}>6.3.</h3> La Société ne garantit pas,
				sans que cette liste soit exhaustive, que le site internet fonctionne sans interruption et
				que les serveurs qui y donnent accès et/ou les sites tiers pour lesquels apparaissent des
				liens hypertextes ne contiennent pas de virus.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>
				7. Mises à jour des conditions d'utilisation du site internet et loi applicable
			</h2>
			<p>
				La Société peut procéder à la mise à jour de ces conditions d'utilisation à tout moment. En
				conséquence, vous êtes invités à vous référer régulièrement aux dernières conditions
				d'utilisation en vigueur. Ces conditions d’utilisation sont soumises au droit applicable et
				relèvent de la compétence des tribunaux compétents.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>8. Coordonnées de contact</h2>
			<p>
				Pour toute question relative aux conditions d’utilisation du site internet, veuillez nous
				contacter par email (rapso-d-admin@totalenergies.com) ou par courrier postal à l’adresse
				suivante :
				<br />
				<br />
				Total Raffinage Chimie
				<br />
				Direction industrielle
				<br />2 place Jean Millier – La Défense 6 – 92400 Courbevoie
			</p>
		</div>
	)
}

export default CGUContentFr
