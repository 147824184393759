import React, { useState, useEffect } from 'react'
import { InputLabel, Select, FormControl, FilledInput, Icon } from '@material-ui/core'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		display: 'block',
	},
	labelRoot: {
		color: theme.palette.primary.main,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		textColor: 'white',
		width: '100%',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	arrowColor: {
		color: '#ffffff',
		marginRight: '10px',
	},
	menuRoot: {
		backgroundColor: '#3268A7',
		color: 'white !important',
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	textColor: {
		color: theme.palette.primary.main,
		backgroundColor: '#3268A7 !important',
		'&:hover': {
			color: '#3268A7',
			backgroundColor: theme.palette.primary.main + '!important',
		},
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
}))

export default function InputSelectField({
	onChange,
	validate,
	name,
	label,
	children,
	initialSelected,
	...props
}) {
	const classes = useStyles()
	const [value, setValue] = useState([])

	const myOnChange = (event) => {
		setValue([...event.target.value])
	}

	useEffect(() => {
		if (initialSelected) setValue(initialSelected)
	}, [initialSelected])

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => {
				const mergedOnChange = (e) => {
					myOnChange(e)
					input.onChange(e)
					if (onChange) onChange(e)
				}
				const newInput = { ...input, onChange: mergedOnChange }
				return (
					<FormControl variant="filled" className={classes.formControl} {...props}>
						<InputLabel
							htmlFor="select-multiple"
							classes={{
								root: classes.labelRoot,
								focused: classes.labelFocused,
							}}
						>
							{label}
						</InputLabel>
						<Select
							multiple={true}
							value={value}
							classes={{
								root: classes.inputRoot,
							}}
							MenuProps={{
								classes: {
									paper: classes.menuRoot,
								},
							}}
							IconComponent={(props) => (
								<Icon
									{...props}
									classes={{
										root: classes.arrowColor,
									}}
								>
                  arrow_drop_down_circle
								</Icon>
							)}
							inputProps={{
								className: classes.inputSize,
							}}
							input={
								<FilledInput
									classes={{
										root: classes.inputRoot,
										focused: classes.inputFocused,
									}}
									{...newInput}
								/>
							}
						>
							{children}
						</Select>
						<FormHelperText classes={{ root: classes.helperText }}>
							{meta && meta.touched && meta.error ? (
								<Translation ns="validateData">{(t) => <>{t(meta.error)}</>}</Translation>
							) : undefined}
						</FormHelperText>
					</FormControl>
				)
			}}
		/>
	)
}
